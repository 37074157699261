<script setup>

    import { onMounted, onUnmounted, onUpdated, ref } from 'vue'

    import { storeToRefs } from 'pinia'
    import { useViewStore, useStaffStore, useClientStore, useCaregiverStore, useJobStore, useJobBucketedStore } from '@/Stores'

    import { util } from '@/Helpers'

    import LatteContainer from '@/Components/App/LatteContainer.vue'
    import ProgressCircle from '@/Components/ProgressCircle.vue'
    import LatteFeedbackPageIntro from '@/Components/Feedback/LatteFeedbackPageIntro.vue'
    import LatteFeedbackTableHeader from '@/Components/Feedback/LatteFeedbackTableHeader.vue'
    import LatteFeedbackTableClientRow from '@/Components/Feedback/LatteFeedbackTableClientRow.vue'
    import LatteFeedbackTableCaregiverRow from '@/Components/Feedback/LatteFeedbackTableCaregiverRow.vue'
    import Loader from '@/Components/App/Loader.vue'

    const viewStore = useViewStore()
    const staffStore = useStaffStore()
    const clientStore = useClientStore()
    const caregiverStore = useCaregiverStore()
    const jobStore = useJobStore()

    const { clients } = storeToRefs(clientStore)
    const { caregivers } = storeToRefs(caregiverStore)
    const { jobs } = storeToRefs(jobStore)
    const { sortedJobsByClient, sortedJobsByClientArray, progressByBucket } = useJobBucketedStore()

    const loadingNew = ref(true)
    const loadingRecent = ref(true)
    const loadingOld = ref(true)

    const navItems = []

    function navSetup() {

        navItems[0] = {
            id: 'ops-feedback-jobs-new-last-week',
            label: 'Last Week',
            status: loadingNew.value == true ? 0 : Math.round( progressByBucket.new )
        }
        navItems[1] = {
            id: 'ops-feedback-jobs-new-last-month',
            label: 'Last 4 Weeks',
            status: loadingRecent.value == true ? 0 : Math.round( progressByBucket.recent )
        }
        navItems[2] = {
            id: 'ops-feedback-jobs-new-last-quarter',
            label: 'Last 12 Weeks',
            status: loadingOld.value == true ? 0 : Math.round( progressByBucket.old )
        }

    }

    // Hydrate data stores
    async function hydrate(data, config = { start: 14, end: 1 }) {
        await jobStore.hydrate(data.jobs, config)
        await staffStore.hydrateFromJobs(Object.values(jobs.value))
        await caregiverStore.hydrateFromJobs(Object.values(jobs.value))
        await clientStore.hydrateFromJobs(Object.values(jobs.value))
        loadingNew.value = loadingRecent.value = loadingOld.value = false
    }

    function rehydrate(data) {

        data = data ? data : {
            scopeAll: false,
            start: 14,
            end: 1,
            id: null
        }

        const { scopeAll, start, end, id, includeMissingStartDate } = data

        switch (id) {
            case "ops-feedback-jobs-new-last-week":
                loadingNew.value = true
                break
            case "ops-feedback-jobs-new-last-month":
                loadingRecent.value = true
                break
            case "ops-feedback-jobs-new-last-quarter":
                loadingOld.value = true
                break
        }

        jobStore.index(start, end, scopeAll, includeMissingStartDate).then(response => {

            console.log('Rehydrating from API', { jobs: response.data })
            hydrate({ jobs: response.data }, { start: start, end: end, includeMissingStartDate: includeMissingStartDate })

        })

    }


    onMounted(() => {
        rehydrate({
            scopeAll: false,
            start: 14,
            end: 1,
            id: null,
            includeMissingStartDate: true
        })

    })
    onUpdated(() => {
        navSetup()
        viewStore.pageNavLoad('Client Feedback', navItems)
    })
    onUnmounted(() => {
        viewStore.pageNavUnload()
        jobStore.purge()
    })

</script>

<template>

    <div class="mx-auto max-w-7xl md:px-8 sm:pt-4 md:pt-6">

        <div class="mx-auto max-w-5xl">

            <div class="px-4 grid sm:grid-cols-1 gap-4 md:grid-cols-12">

                <LatteFeedbackPageIntro />

                <div class="sm:col-span-1 md:col-span-5 md:-mt-4">

                    <h5 class="text-stone-500 font-semibold text-md md:text-xs tracking-wide text-center mb-2 md:mb-0">
                        Your Feedback Completion Progress
                    </h5>

                    <div class="grid grid-cols-3 gap-2 md:mx-auto md:gap-4">

                        <div @click="util.scrollToElement('ops-feedback-jobs-new-last-week')"
                            class="flex flex-col text-center text-xs font-semibold text-lifeworx-blue-700 opacity-75 hover:opacity-100 cursor-pointer"
                        >
                            <ProgressCircle
                                :progress="loadingNew == true ? 0 : Math.round( progressByBucket.new )"
                                :label="true"
                                :delay="1000"
                            />
                            <span class="text-sm underline">
                                Last Week
                            </span>
                        </div>

                        <div @click="util.scrollToElement('ops-feedback-jobs-new-last-month')"
                            class="flex flex-col text-center text-xs font-semibold text-lifeworx-blue-700 opacity-75 hover:opacity-100 cursor-pointer"
                        >
                            <ProgressCircle
                                :progress="loadingRecent == true ? 0 : Math.round( progressByBucket.recent )"
                                :label="true"
                                :delay="1500"
                            />
                            <span class="text-sm underline">
                                Last 4 Weeks
                            </span>
                        </div>

                        <div @click="util.scrollToElement('ops-feedback-jobs-new-last-quarter')"
                            class="flex flex-col text-center text-xs font-semibold text-lifeworx-blue-700 opacity-75 hover:opacity-100 cursor-pointer"
                        >
                            <ProgressCircle
                                :progress="loadingOld == true ? 0 : Math.round( progressByBucket.old )"
                                :label="true"
                                :delay="2000"
                            />
                            <span class="text-sm underline">
                                Last 12 Weeks
                            </span>
                        </div>


                    </div>

                </div>

            </div>

            <LatteContainer
                id="ops-feedback-jobs-new-last-week"
                title="New Jobs Last Week"
                :progress="loadingNew == true ? 0 : Math.round( progressByBucket.new )"
                :scoped="true"
                :collapsible="true"
                :start="1"
                :end="1"
                :includeMissingStartDate="true"
                :showRefreshButton="true"
                @refresh="rehydrate"
            >

                <div>

                    <LatteFeedbackTableHeader />

                    <div
                        v-if="loadingNew"
                        class="py-36 bg-zinc-100"
                    >
                        <Loader />
                    </div>

                    <div
                        v-else-if="Object.keys(sortedJobsByClient.new).length == 0"
                        class="py-8 text-center font-semibold text-zinc-400"
                    >
                        No matching jobs found in this period.
                    </div>

                    <div v-else>

                        <template
                            v-for="([clientId, jobIdStartDates], index) in sortedJobsByClientArray.new" :key="clientId"
                        >

                            <LatteFeedbackTableClientRow
                                v-if="clients[clientId]"
                                :client="clients[clientId]"
                                :jobs="sortedJobsByClient.new[clientId]"
                            />

                            <div class="">

                                <template
                                    v-for="(job, index) in jobStore.jobsOrderedDesc(sortedJobsByClient.new[clientId], jobIdStartDates)" :key="job.id"
                                >

                                    <LatteFeedbackTableCaregiverRow
                                        v-if="caregivers[job.caregiver.id]"
                                        :job="job"
                                        :caregiver="caregivers[job.caregiver.id]"
                                    />

                                </template>

                            </div>

                            <div class="w-full h-1 bg-zinc-300"></div>

                        </template>

                    </div>

                </div>

            </LatteContainer>

            <LatteContainer
                id="ops-feedback-jobs-new-last-month"
                title="New Jobs Last 4 Weeks"
                :progress="loadingRecent == true ? 0 : Math.round( progressByBucket.recent )"
                :scoped="true"
                :collapsible="true"
                :start="5"
                :end="2"
                :showRefreshButton="true"
                @refresh="rehydrate"
            >

                <div>

                    <LatteFeedbackTableHeader />

                    <div
                        v-if="loadingRecent"
                        class="py-36 bg-zinc-100"
                    >
                        <Loader />
                    </div>

                    <div
                        v-else-if="Object.keys(sortedJobsByClient.recent).length == 0"
                        class="py-8 text-center font-semibold text-zinc-400"
                    >
                        No matching jobs found in this period.
                    </div>

                    <div
                        v-else
                    >

                        <template
                            v-for="([clientId, jobIdStartDates], index) in sortedJobsByClientArray.recent" :key="clientId"
                        >

                            <LatteFeedbackTableClientRow
                                v-if="clients[clientId]"
                                :client="clients[clientId]"
                                :jobs="sortedJobsByClient.recent[clientId]"
                            />

                            <div class="">

                                <template
                                    v-for="(job, index) in jobStore.jobsOrderedDesc(sortedJobsByClient.recent[clientId], jobIdStartDates)" :key="job.id"
                                >

                                    <LatteFeedbackTableCaregiverRow
                                        v-if="caregivers[job.caregiver.id]"
                                        :job="job"
                                        :caregiver="caregivers[job.caregiver.id]"
                                    />

                                </template>

                            </div>

                            <div class="w-full h-1 bg-zinc-300"></div>

                        </template>

                    </div>

                </div>

            </LatteContainer>

            <LatteContainer
                id="ops-feedback-jobs-new-last-quarter"
                title="New Jobs Last 12 Weeks"
                :progress="loadingOld == true ? 0 : Math.round( progressByBucket.old )"
                :scoped="true"
                :collapsible="true"
                :start="14"
                :end="6"
                :showRefreshButton="true"
                @refresh="rehydrate"
            >

                <div>

                    <LatteFeedbackTableHeader />

                    <div
                        v-if="loadingOld"
                        class="py-36 bg-zinc-100"
                    >
                        <Loader />
                    </div>

                    <div
                        v-else-if="Object.keys(sortedJobsByClient.old).length == 0"
                        class="py-8 text-center font-semibold text-zinc-400"
                    >
                        No matching jobs found in this period.
                    </div>

                    <div
                        v-else
                    >

                        <template
                            v-for="([clientId, jobIdStartDates], index) in sortedJobsByClientArray.old" :key="clientId"
                        >

                            <LatteFeedbackTableClientRow
                                v-if="clients[clientId]"
                                :client="clients[clientId]"
                                :jobs="sortedJobsByClient.old[clientId]"
                            />

                            <div class="">

                                <template
                                    v-for="(job, index) in jobStore.jobsOrderedDesc(sortedJobsByClient.old[clientId], jobIdStartDates)" :key="job.id"
                                >

                                    <LatteFeedbackTableCaregiverRow
                                        v-if="caregivers[job.caregiver.id]"
                                        :job="job"
                                        :caregiver="caregivers[job.caregiver.id]"
                                    />

                                </template>

                            </div>

                            <div class="w-full h-1 bg-zinc-300"></div>

                        </template>

                    </div>

                </div>

            </LatteContainer>

        </div>
    </div>

</template>
