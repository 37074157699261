<script setup>

    import { onMounted, onUnmounted, ref } from 'vue'

    import { useRoute, useRouter } from 'vue-router'

    import { storeToRefs } from 'pinia'
    import { useStaffStore, useClientStore, useCaregiverStore, useJobStore } from '@/Stores'

    import LatteContainer from '@/Components/App/LatteContainer.vue'
    import LatteFeedbackPageIntro from '@/Components/Feedback/LatteFeedbackPageIntro.vue'
    import LatteFeedbackTableHeader from '@/Components/Feedback/LatteFeedbackTableHeader.vue'
    import LatteFeedbackTableClientRow from '@/Components/Feedback/LatteFeedbackTableClientRow.vue'
    import LatteFeedbackTableCaregiverRow from '@/Components/Feedback/LatteFeedbackTableCaregiverRow.vue'

    import Loader from '@/Components/App/Loader.vue'

    const router = useRouter()
    const route = useRoute()

    const staffStore = useStaffStore()
    const clientStore = useClientStore()
    const caregiverStore = useCaregiverStore()
    const jobStore = useJobStore()

    const { clients } = storeToRefs(clientStore)
    const { caregivers } = storeToRefs(caregiverStore)
    const { jobs, sortedJobsByClientAll, sortedJobsByClientAllOrdered } = storeToRefs(jobStore)

    const loading = ref(true)
    const singleViewSubject = ref(null)


    // Hydrate data stores
    async function hydrate(data, config = { start: 14, end: 1 }) {
        await jobStore.hydrate(data.jobs, config)
        await staffStore.hydrateFromJobs(Object.values(jobs.value))
        await caregiverStore.hydrateFromJobs(Object.values(jobs.value))
        await clientStore.hydrateFromJobs(Object.values(jobs.value))
        loading.value = false
    }

    function rehydrate(data) {

        data = {
            client: route.params.client,
            caregiver: route.params.caregiver
        }

        const { client, caregiver } = data

        loading.value = true

        jobStore.index(null, null, null, true, client, caregiver).then(response => {

            singleViewSubject.value = null

            console.log('Rehydrating from API', { jobs: response.data })
            hydrate({ jobs: response.data }, { start: 14, end: 1, includeMissingStartDate: true }).then(() => {

                singleViewSubject.value = route.params.client ? clients.value[route.params.client] : caregivers.value[route.params.caregiver]

            })

        })
    }


    onMounted(() => rehydrate() )
    onUnmounted(() => jobStore.purge() )

</script>

<template>

    <div class="mx-auto max-w-7x md:px-8 sm:pt-4 md:pt-6">

        <div class="mx-auto max-w-5xl">

            <div class="px-4 grid sm:grid-cols-1 gap-4 md:grid-cols-12">

                <LatteFeedbackPageIntro />

                <div class="sm:col-span-1 md:col-span-5 md:-mt-4">

                    <h5 class="text-gray-400 font-semibold text-sm md:text-xs tracking-wide text-center">
                        Currently Viewing
                    </h5>

                    <h5
                        class="my-2 font-semibold italic text-lg text-center animate-pulse"
                        v-if="loading"
                        >
                        Loading...
                    </h5>

                    <h5
                        class="my-2 font-semibold text-lg text-center"
                        v-if="singleViewSubject"
                        >
                        {{ singleViewSubject.first_name + ' ' + singleViewSubject.last_name }} ({{ route.params.client ? 'Client' : 'Caregiver' }})
                    </h5>

                    <div class="flex justify-center">
                        <button
                            class="text-xs md:text-sm underline"
                            @click="router.push('/feedback')"
                        >
                            View All Jobs
                        </button>
                    </div>

                </div>


            </div>

            <LatteContainer
                id="ops-feedback-jobs-single"
                :title="(route.params.client ? 'Client' : 'Caregiver') + ' Jobs'"
                :forceSingleLineMobileLayout="true"
                :collapsible="false"
                :start="1"
                :end="1"
                :showRefreshButton="true"
                @refresh="rehydrate"
            >

                <div>

                    <LatteFeedbackTableHeader />

                    <div
                        v-if="loading"
                        class="py-36 bg-zinc-100"
                    >
                        <Loader />
                    </div>

                    <div
                        v-else-if="Object.keys(sortedJobsByClientAll).length == 0"
                        class="py-8 text-center font-semibold text-zinc-400"
                    >
                        No matching jobs found.
                    </div>

                    <div v-else>

                        <template
                        v-for="([clientId, jobIdStartDates], index) in sortedJobsByClientAllOrdered" :key="clientId"
                        >

                            <LatteFeedbackTableClientRow
                                v-if="clients[clientId]"
                                :client="clients[clientId]"
                                :jobs="sortedJobsByClientAll[clientId]"
                            />

                            <div class="">

                                <template
                                    v-for="(job, index) in jobStore.jobsOrderedDesc(sortedJobsByClientAll[clientId], jobIdStartDates)" :key="job.id"
                                >

                                    <LatteFeedbackTableCaregiverRow
                                        v-if="caregivers[job.caregiver.id]"
                                        :job="job"
                                        :caregiver="caregivers[job.caregiver.id]"
                                    />

                                </template>

                            </div>

                            <div class="w-full h-1 bg-zinc-300"></div>

                        </template>

                    </div>

                </div>

            </LatteContainer>

        </div>
    </div>

</template>
