<script setup lang="ts">

    import { storeToRefs } from 'pinia'

    import LatteRatingBar from '@/Components/Feedback/LatteRatingBar.vue'
    import LatteScheduleDays from '@/Components/Feedback/LatteScheduleDays.vue'
    import LatteStaffLabel from '@/Components/LatteStaffLabel.vue'
    import LatteFeedbackActionsComms from '@/Components/Feedback/LatteFeedbackActionsComms.vue'
    import LatteFeedbackActionsRatings from '@/Components/Feedback/LatteFeedbackActionsRatings.vue'

    import { ArrowUturnLeftIcon, CalendarDaysIcon, CheckIcon, XMarkIcon } from '@heroicons/vue/20/solid'
    import { ArrowRightCircleIcon } from '@heroicons/vue/24/outline'

    import { useStaffStore, useNotificationStore } from '@/Stores'

    import moment from 'moment'

    import { computed, ref } from 'vue'
    import type { UseSwipeDirection } from '@vueuse/core'
    import { useSwipe, useWindowSize } from '@vueuse/core'

    import { hideAllPoppers } from 'floating-vue'

    const staffStore = useStaffStore()
    const { staffs } = storeToRefs(staffStore)

    const notificationStore = useNotificationStore()

    const { width, height } = useWindowSize()

    const isMobile = computed(() => (width.value <= 768 ? true : false))

    const props = defineProps({
        caregiver: {
            type: Object
        },
        job: {
            type: Object
        },
    })


    const target = ref<HTMLElement | null>(null)
    const container = ref<HTMLElement | null>(null)
    const containerWidth = computed(() => container.value?.offsetWidth)
    const touchThreshold = 20
    const left = ref('0')
    const opacity = ref(1)

    const caregiverCoffeeBaseUrl = import.meta.env.VITE_COFFEE_BASEURL + '/user/recruiter-candidates/personalinfo/id/'

    function reset() {
        hideAllPoppers()
        left.value = '0'
        opacity.value = 1
    }

    const { direction, isSwiping, lengthX, lengthY } = useSwipe(
        target, {
            threshold: touchThreshold,
            passive: true,
            onSwipeStart(e: TouchEvent) {
                hideAllPoppers()
            },
            onSwipe(e: TouchEvent) {
                if (containerWidth.value && isMobile.value) {
                    if (lengthX.value < 0 && (lengthY.value <= touchThreshold*5 && lengthY.value >= -touchThreshold*5)) {
                        const length = Math.abs(lengthX.value)
                        left.value = `${length}px`
                        opacity.value = 1 - length / containerWidth.value
                    }
                    else if (lengthX.value > 0 && (lengthY.value <= touchThreshold*5 && lengthY.value >= -touchThreshold*5)) {
                        const length = Math.abs(lengthX.value)
                        left.value = `-${length}px`
                        opacity.value = 1 - length / containerWidth.value
                    }
                    else {
                        reset()
                    }
                }
            },
            onSwipeEnd(e: TouchEvent, direction: UseSwipeDirection) {
                if (isMobile.value && ['left', 'right'].includes(direction)) {
                    if (direction == 'right' && lengthX.value < 0 && containerWidth.value && (Math.abs(lengthX.value) / containerWidth.value) >= 0.1) {
                        left.value = '150%'
                        opacity.value = 0
                    }
                    else if (direction == 'left' && lengthX.value > 0 && containerWidth.value && (Math.abs(lengthX.value) / containerWidth.value) >= 0.1) {
                        left.value = '-150%'
                        opacity.value = 0
                    }
                    else {
                        // reset()
                    }
                } else {
                    reset()
                }
            },
        })

</script>

<template>

    <div class="w-full group bg-zinc-75 group-odd:bg-zinc-150">

        <div ref="container" class="containerx select-none h-full bg-zinc-75 group-odd:bg-zinc-150  overflow-x-clip">

            <div class="grid grid-cols-12 md:grid-cols-16 border-b-zinc-400">

                <div class="col-span-1 pl-4 flex items-center justify-center">

                    <div class="flex-shrink-0 rounded-full h-6 w-6 border-2 border-zinc-300 border-opacity-100 bg-opacity-100 bg-zinc-100">

                        <div class="relative left-5 h-5 z-20">
                            <div
                                class="h-[11px] w-16 border-b-2"
                                :class="{
                                    'border-lifeworx-red-500': caregiver.hard_no_at,
                                    'border-zinc-300': !caregiver.hard_no_at,
                                }"
                            />
                        </div>

                        <div class="relative bottom-28 h-5 z-20">
                            <div class="h-24 w-[0.7rem] border-r-2 border-zinc-300" />
                        </div>

                        <div
                            class="relative -inset-x-0.5 -top-[2.6rem] rounded-full h-6 w-6 border-2  border-opacity-100 bg-opacity-100 z-30 flex justify-center items-center"
                            :class="{
                                'border-lifeworx-red-500 bg-zinc-100': caregiver.hard_no_at && job.ratings.length == 0,
                                'border-zinc-300 bg-zinc-100': !caregiver.hard_no_at && job.ratings.length == 0,
                                'border-lifeworx-green-500 bg-lifeworx-green-500': !caregiver.hard_no_at && job.ratings && job.ratings.length > 0 && job.ratings.slice(-1)[0].status == 'green',
                                'border-lifeworx-yellow-500 bg-lifeworx-yellow-500': !caregiver.hard_no_at && job.ratings && job.ratings.length > 0 && job.ratings.slice(-1)[0].status == 'yellow',
                                'border-lifeworx-red-500 bg-lifeworx-red-500': (caregiver.hard_no_at && job.ratings.length > 0) || (!caregiver.hard_no_at && job.ratings && job.ratings.length > 0 && job.ratings.slice(-1)[0].status == 'red'),
                            }"
                        >
                            <XMarkIcon
                                v-if="caregiver.hard_no_at && job.ratings.length == 0"
                                class="opacity-50 inline-flex h-4 w-4 text-lifeworx-red-200 stroke-1 stroke-lifeworx-red-200"
                            />
                            <XMarkIcon
                                v-else-if="caregiver.hard_no_at && job.ratings.length > 0"
                                class="inline-flex h-4 w-4 text-white stroke-1 stroke-white"
                            />
                            <CheckIcon
                                v-if="job.ratings && job.ratings.length > 0 && !caregiver.hard_no_at"
                                class="inline-flex h-4 w-4 text-white stroke-1 stroke-white"
                            />

                        </div>

                    </div>

                </div>

                <div class="col-span-11 md:col-span-5 md:pl-2 grid grid-cols-8 ">

                    <div class="col-span-2 flex items-center justify-center">

                        <div
                            class="flex-shrink-0 rounded-full overflow-clip h-14 w-14 border-2 z-30"
                            :class="{
                                'border-lifeworx-red-500 bg-lifeworx-red-500': caregiver.hard_no_at,
                                'border-zinc-300 bg-white': !caregiver.hard_no_at
                            }"
                        >
                            <a
                                :href="caregiverCoffeeBaseUrl + caregiver.coffee_job_profile_id"
                                target="_blank"
                            >
                                <img
                                    :src="caregiver.profile_image_url"
                                    :alt="caregiver.first_name + ' ' + caregiver.last_name"
                                    class="object-cover h-14 w-14"
                                    :class="{
                                        'opacity-50 bg-lifeworx-red-500 bg-blend-screen': caregiver.hard_no_at
                                    }"
                                />
                            </a>
                        </div>

                    </div>

                    <div class="col-span-5 md:col-span-6 flex items-center h-24 py-5">

                        <div class="relative w-full h-16">

                            <div v-if="direction == 'left' && isMobile" class="flex mt-2">

                                <LatteFeedbackActionsRatings
                                    :isMobile="isMobile"
                                    :job="job"
                                    @rated="reset()"
                                />

                                <ArrowUturnLeftIcon
                                    class="inline-flex flex-shrink-0 h-8 w-8 mt-2 ml-4 mr-6 text-zinc-500 opacity-60 hover:opacity-100 cursor-pointer"
                                    aria-hidden="true"
                                    @click="reset()"
                                />

                            </div>

                            <div v-if="direction == 'right' && isMobile" class="flex mt-2">

                                <div class="col-span-2 items-center">
                                    <LatteScheduleDays
                                        :job="job"
                                    />
                                </div>

                                <ArrowUturnLeftIcon
                                    class="inline-flex h-8 w-8 mt-2 ml-4 mr-6 text-zinc-500 opacity-60 hover:opacity-100 cursor-pointer"
                                    aria-hidden="true"
                                    @click="reset()"
                                />

                            </div>

                            <div class="w-full grid grid-rows-3 gap-4 -mt-2 py-0 pb-2 md:py-1 overlay group bg-zinc-75 group-odd:bg-zinc-150"
                            ref="target" :class="{ animated: !isSwiping }" :style="{ left, opacity }">

                                <h4
                                    class="h-6 tracking-wide text-lifeworx-blue-700 font-semibold text-sm"
                                    :class="{ 'line-through text-lifeworx-red-500': caregiver.hard_no_at }"

                                >
                                    <a
                                        :href="caregiverCoffeeBaseUrl + caregiver.coffee_job_profile_id"
                                        target="_blank"
                                        class="hover:underline"
                                    >
                                        {{ caregiver.first_name + ' ' + caregiver.last_name }}
                                        <CalendarDaysIcon
                                            v-if="job.start_date === null"
                                            class="inline-flex h-5 w-5 -mt-0.5 text-lifeworx-red-500 md:hidden"
                                        />
                                    </a>
                                </h4>

                                <div class="flex-grow h-6">
                                        <LatteStaffLabel
                                            :staff="staffs[caregiver.assigned_recruiter.id]"
                                        />
                                </div>

                                <div class="flex w-48 h-6 py-0.5">
                                    <LatteRatingBar
                                        :bordered="true"
                                        :subject="caregiver"
                                        subjectType="caregiver"
                                        :job="job"
                                    />
                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="col-span-1 md:hidden flex items-center">

                        <div class="flex md:hidden justify-end items-center">
                            <button
                                class="w-10 h-10 text-lifeworx-blue-600"
                                @click="hideAllPoppers && notificationStore.showActionModal(caregiver, 'caregiver')"
                            >
                                <ArrowRightCircleIcon class="w-7 h-7" />
                            </button>
                        </div>

                    </div>


                </div>


                <div class="col-span-3 hidden md:flex items-center">

                    <LatteFeedbackActionsRatings
                        :job="job"
                    />

                </div>

                <div class="col-span-2 items-center hidden md:flex">
                    <h4
                        class="uppercase tracking-wider text-zinc-600  text-xs"
                        :title="job && job.ratings && job.ratings.length > 0 ? moment(job.ratings.slice(-1)[0].created_at).utc().format('MMMM Do YYYY, h:mm:ss a') : null"
                    >
                        {{ job && job.ratings && job.ratings.length > 0 ? moment(job.ratings.slice(-1)[0].created_at).utc().from(moment.utc()) : '&mdash;' }}
                    </h4>
                </div>

                <div class="col-span-2 hidden md:flex items-center">
                    <LatteScheduleDays
                        :job="job"
                    />
                </div>

                <LatteFeedbackActionsComms
                    class="hidden md:flex "
                    :subject="caregiver"
                    subjectType="caregiver"
                />



            </div>

        </div>

    </div>

</template>

<style scoped>

    .containerx {
        position: relative;
        align-items: center;
        justify-content: center;
    }

    .overlay {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .overlay.animated {
        transition: all 0.2s ease-in-out;
    }

    .overlay > p {
        color: #fff;
        font-weight: bold;
        text-align: center;
        white-space: nowrap;
    }

</style>
