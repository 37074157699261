<script setup>

    import { computed, ref, watch } from 'vue'

    import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
    import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/vue'

    const props = defineProps(
        {
            modelValue: {
                type: [ Object, String ]
            },
            options: {
                type: Array,
                required: true,
            }
        }
    )

    const emit = defineEmits(['update:modelValue'])

    const query = ref('')

    const filteredOption = computed(() =>
        query.value === ''
            ? props.options
            : props.options.filter((option) => {
                return option.name.toLowerCase().includes(query.value.toLowerCase());
            })
        )

    const queryOption = computed(() => {
        if (filteredOption.value.length == 1 && filteredOption.value[0]['id'].toLowerCase() == query.value.toLowerCase()) {
            return null;
        }

        return query.value === '' ? null : { id: query.value, name: query.value };
    })

    watch(() => props.modelValue, (newValue)=> {
        if (!newValue || newValue == '') {
            query.value = ''
        } else {
            query.value = newValue.name
        }
    })

</script>

<template>

    <Combobox
        as="div"
        :modelValue="props.modelValue"
        @update:modelValue="value => emit('update:modelValue', value)"
        nullable
    >
        <div class="relative">
            <ComboboxInput
                autocomplete="off"
                class="w-full rounded-md border border-stone-400 bg-white py-1.5 pl-2.5 pr-10 text-stone-700 shadow-sm ring-0 ring-inset ring-stone-400 focus:ring-1 focus:outline-none focus:ring-inset focus:ring-lifeworx-blue-600 focus:border-lifeworx-blue-600 text-sm sm:leading-6"
                @change="query = $event.target.value"
                :display-value="(option) => option?.name"
            />
            <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
            >
                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </ComboboxButton>

            <ComboboxOptions
                v-if="filteredOption.length > 0 || queryOption"
                class="absolute z-popover mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
                <ComboboxOption
                    v-if="queryOption"
                    :value="queryOption"
                    :key="queryOption.id"
                    as="template"
                    v-slot="{ active, selected }"
                >
                     <li
                        :class="[
                            'relative cursor-pointer select-none py-2 pl-3 pr-9',
                            active ? 'bg-lifeworx-blue-600 text-white' : 'text-gray-900'
                        ]"
                    >
                        <span :class="['block truncate', selected && 'font-semibold']">
                            Create new: {{ queryOption.name }}
                        </span>

                        <span
                            v-if="selected"
                            :class="[
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-lifeworx-blue-600'
                            ]"
                        >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                    </li>
                </ComboboxOption>
                <ComboboxOption
                    v-for="option in filteredOption"
                    :key="option.id"
                    :value="option"
                    as="template"
                    v-slot="{ active, selected }"
                >
                    <li
                        :class="[
                            'relative cursor-pointer select-none py-2 pl-3 pr-9',
                            active ? 'bg-lifeworx-blue-600 text-white' : 'text-gray-900'
                        ]"
                    >
                        <span
                            :class="[
                                'block truncate', selected && 'font-semibold'
                            ]"
                        >
                            {{ option.name }}
                        </span>

                        <span
                            v-if="selected"
                            :class="[
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-lifeworx-blue-600'
                            ]"
                        >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                    </li>
                </ComboboxOption>
            </ComboboxOptions>
        </div>
    </Combobox>

</template>
