<script setup>

    import { onMounted, ref, watch, useSlots } from 'vue'
    import { factory } from '@/Validators'

    const slots = useSlots()

    const props = defineProps({
        modelValue: {
            type: [String, null],
        },
        validate: {
            type: Array,
            default: []
        },
        type: {
            type: String
        },
        label: {
            type: String,
        },
    })

    const emit = defineEmits(['update:modelValue'])
    const input = ref()
    const text = ref(null)
    const isValid = ref(true)

    onMounted(() => {
        if (input?.value.hasAttribute('autofocus')) {
            input.value.focus()
        }
    })

    watch(text, (value) => {
        if (value == null) {
            return;
        }

        if (props.validate.includes('nullable') && value == '') {
            emit('update:modelValue', value)
            isValid.value = true
            return;
        }

        if (props.validate.includes('nullable') && value == '') {
            emit('update:modelValue', value)
            isValid.value = true
            return;
        }

        for (let i in props.validate) {
            const validator = factory(props.validate[i])

            if (validator.isValid(value) == false) {
                isValid.value = false
                return;
            }

            isValid.value = true
        }
        emit('update:modelValue', value)

    }, {immediate: true})

    watch(() => props.modelValue, (value) => {
        text.value = value
    }, {immediate: true})

    defineExpose({ focus: () => input.value.focus() })

</script>

<template>
    <label
        class="block font-medium text-sm text-stone-500 group"
        :class="{
            'cursor-pointer': slots.default
        }">
        <span
            class="block mb-1 text-xs text-stone-400"
            :style="{ visibility: text ? 'visible' : 'hidden' }"
        >
            {{ label }}
        </span>
        <slot />
    </label>

    <input
        class="w-full font-serif text-4xl font-medium py-3 bg-lifeworx-alabaster-200 border-stone-400 placeholder:text-stone-400 text-lifeworx-blue-800 focus:border-lifeworx-blue-600 focus:ring-lifeworx-blue-600 focus:ring-1 focus:outline-none rounded-md shadow-sm peer tracking-wide"
        :class="{
            'border-lifeworx-red-500 focus:ring-1 focus:border-lifeworx-red-500 focus:ring-lifeworx-red-500 bg-lifeworx-red-500 bg-opacity-10' : isValid == false,
        }"
        v-model="text"
        :type="type"
        :placeholder="label"
        ref="input"
    />

</template>
