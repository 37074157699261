import './bootstrap'
import '../css/app.css'
import 'floating-vue/dist/style.css'
import '../css/popper.css'

import { createApp, h } from 'vue'
import { createPinia } from 'pinia'

import router from './Router'

import FloatingVue from 'floating-vue'

import * as Sentry from '@sentry/vue'
// import VueGtag from 'vue-gtag'

import App from './App.vue'

const app = createApp(App)

const pinia = createPinia()

const appName = import.meta.env.VITE_APP_NAME || 'LifeWorx Ops'

const FloatingVueConfig = {
    themes: {
      'lifeworx': {
        '$extend': 'dropdown',
        triggers: ['click'],
        autoHide: true,
        placement: 'bottom',
      },
      'lifeworx-red-outline': {
        '$extend': 'dropdown',
        triggers: ['click'],
        autoHide: true,
        placement: 'top',
      },
      'lifeworx-yellow-outline': {
        '$extend': 'dropdown',
        triggers: ['click'],
        autoHide: true,
        placement: 'top',
      },
      'lifeworx-green-outline': {
        '$extend': 'dropdown',
        triggers: ['click'],
        autoHide: true,
        placement: 'top',
      },
    },
}

Sentry.init({
    app,
    trackComponents: true,
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                "localhost",
                /^https:\/\/latte\.staging\.lifeworx\.com\/api/,
                /^https:\/\/latte\.lifeworx\.com\/api/,
                /^https:\/\/ops\.staging\.lifeworx\.com\/api/,
                /^https:\/\/ops\.lifeworx\.com\/api/
            ],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(pinia)

app.use(router)

app.use(FloatingVue, FloatingVueConfig)

// app.use(VueGtag, {
//     config: {
//         id: import.meta.env.VITE_GA_MEASUREMENT_ID,
//     }
// }, router)

app.mount('#app')
