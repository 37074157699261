<script setup>

    import { ref, reactive, watch, onMounted, onUpdated } from 'vue'
    import gsap from 'gsap'

    const circumference = 30 * 2 * Math.PI
    const number = ref(0)
    const tweened = reactive({
        number: 0
    })

    function sleep(ms) {
        return new Promise(
            resolve => setTimeout(resolve, ms)
        );
    }

    async function delayedAnimation() {
        await sleep(props.delay);
        number.value = props.progress
    }

    const props = defineProps({
        progress: {
            type: Number,
            default: 25
        },
        label: {
            type: Boolean,
            default: true
        },
        delay: {
            type: Number,
            default: 0
        }
    })

    watch(number, (n) => {
        gsap.to(tweened, { duration: 1.5, number: Number(n) || 0 })
    })

    onMounted(() => { delayedAnimation() })
    onUpdated(() => { delayedAnimation() })

</script>

<template>

    <div class="inline-flex items-center justify-center overflow-hidden rounded-full bottom-5 left-5">

        <svg class="w-20 h-20 rotate-90">
            <circle
                class="text-lifeworx-alabaster-200"
                stroke-width="5"
                stroke="currentColor"
                fill="transparent"
                r="30"
                cx="40"
                cy="40"
            />
            <circle
                class="text-lifeworx-orange"
                stroke-width="5"
                :stroke-dasharray="circumference"
                :stroke-dashoffset="circumference - tweened.number.toFixed(0) / 100 * circumference"
                stroke-linecap="round"
                stroke="currentColor"
                fill="transparent"
                r="30"
                cx="40"
                cy="40"
            />
        </svg>

        <span v-if="label" class="absolute text-base font-semibold text-stone-500">
            {{ tweened.number.toFixed(0) }}%
        </span>

    </div>

</template>
