<script setup>

    import { ref, watch, computed } from 'vue'

    import { storeToRefs } from 'pinia'

    import AddressAutocomplete from '@/Components/Forms/AddressAutocomplete.vue';
    import LwxSelect from '@/Components/Forms/LwxSelect.vue'
    import TextInput from '@/Components/Forms/TextInput.vue'
    import { useConfigStore } from '@/Stores'

    const configStore = useConfigStore()
    const { config } = storeToRefs(configStore)

    const address = ref(null)
    const addressPropsChange = ref(false)
    const emit = defineEmits(['update:address']);

    const props = defineProps({
        address: {
            type: Object,
            default: {
                address: "",
                address_2: "",
                city: "",
                state: "",
                zip: ""
            }
        },
    })

    const states = ref([
        {
            id: null,
            name: ""
        }
    ])

    function computeAddress(input) {
        let addr = '';
        if (input.address) addr += input.address;
        if (input.address_2) addr += input.address_2;
        if (input.city) addr += ', ' + input.city;
        if (input.state) addr += ', ' + input.state;
        if (input.zip) addr += ' ' + input.zip;
        return addr;
    }

    const formattedAddress = computed(() => {
        return computeAddress(address.value);
    });


    function addressFill(place) {
        for (const component of place.address_components) {

            const componentType = component.types[0]

            switch (componentType) {
                case "street_number": {
                    address.value.address = component.long_name
                    break;
                }
                case "route": {
                    address.value.address += ' ' + component.long_name
                    break;
                }
                case "postal_code": {
                    address.value.zip = component.long_name
                    break;
                }
                case "sublocality_level_1": {
                    address.value.city = component.short_name
                }
                case "locality": {
                    address.value.city = component.long_name
                    break;
                }
                case "administrative_area_level_1": {
                    address.value.state = { id: component.short_name, name: component.long_name }
                    break;
                }
            }
        }
    }

    function extractAddress(proxyAddress) {
        return {
            ...proxyAddress,
            state: proxyAddress.state ? proxyAddress.state.id : null,
        }
    }

    watch([() => props.address, states], (newValue, oldValue) => {
        address.value = {
            ...props.address,
            state: getOptionName(props.address.state, states.value) ? {id: props.address.state, name: getOptionName(props.address.state, states.value)} : props.address.state,
        }
    }, { immediate: true, deep: true })

    watch(address, (newValue, oldValue) => {
        if(addressPropsChange.value == false) {
            if (JSON.stringify(extractAddress(oldValue)) === JSON.stringify(extractAddress(newValue))) {
                emit('update:address', extractAddress(newValue))
                return;
            }
        }
        addressPropsChange.value = false
    }, { deep: true })

    function getOptionName(id, array) {
        const index = array.findIndex(item => item.id == id);
        return index == -1 ? undefined :  array[index].name;
    }

    watch([() => props.address], (newValue, oldValue) => {
        addressPropsChange.value = true
    }, { deep: true, immediate : true })

    watch(config, (value) => {
        states.value = value.states;
    }, { deep: true, immediate : true })

</script>

<template>
<div
        class="flex rounded-md overflow-visible cursor-text"
        :class="{
            'border-lifeworx-stone-500 bg-lifeworx-stone-500 bg-opacity-10' : !formattedAddress,
            'border-stone-400': formattedAddress,
        }"
        @click="focusCursor"
    >
        <div class="flex flex-1 mr-1">

            <div class="w-full mb-4 md:mb-0">

                <div class="flex flex-1 mr-1">
                    <div class="w-1/2 mb-3">
                        <AddressAutocomplete
                                class="w-full border-0 text-sm text-stone-700 placeholder:text-stone-400 focus:ring-0 bg-transparent"
                                id="grid-address"
                                v-model="address.address"
                                @addressFill="addressFill"
                                placeholder="Service Address"
                                input_class="font-semibold bg-lifeworx-alabaster-200 text-lifeworx-blue-800"
                            />

                            <!-- <div
                                class="ml-[-2.5rem] z-10 px-2 pt-3 text-right w-10 text-xs select-none"
                                :class="{
                                    'text-lifeworx-blue-700': formattedAddress,
                                    'text-stone-500': !formattedAddress
                                }"
                            >
                                <MapIcon class="bg-lifeworx-alabaster-200"/>
                            </div> -->
                </div>

                <div class="w-1/2 mb-3 pl-2">
                    <div >
                        <TextInput
                            class="w-full px-2.5 py-1.5 text-sm text-stone-700"
                            id="grid-address_2"
                            type="text"
                            v-model="address.address_2"
                            placeholder="Apartment/Suite"
                            input_class="font-semibold bg-lifeworx-alabaster-200 text-lifeworx-blue-800"
                        />
                    </div>
                </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-2">
                    <div class="w-full md:w-1/2 pl-3 mb-4 md:mb-0">
                        <TextInput
                            class="w-full px-2.5 py-1.5 text-sm text-stone-700"
                            id="grid-city"
                            type="text"
                            v-model="address.city"
                            placeholder="Town/City"
                            input_class="font-semibold bg-lifeworx-alabaster-200 text-lifeworx-blue-800"
                        />
                    </div>
                    <div class="w-full md:w-1/4 px-2 mb-4 md:mb-0">
                        <LwxSelect
                            id="grid-state"
                            v-model="address.state"
                            :options="states"
                            :useId="true"
                            placeholder="State"
                            input_class="font-semibold bg-lifeworx-alabaster-200 text-lifeworx-blue-800"
                        />
                    </div>
                    <div class="w-full md:w-1/4 pr-4 mb-4 md:mb-0">
                        <TextInput
                            class="w-full px-2.5 py-1.5 text-sm text-stone-700"
                            id="grid-zip"
                            type="text"
                            :validate="['nullable', 'len:5', 'number']"
                            v-model="address.zip"
                            placeholder="ZIP"
                            input_class="font-semibold bg-lifeworx-alabaster-200 text-lifeworx-blue-800"
                        />
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>
