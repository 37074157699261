    import { ref, watch } from 'vue'

    import { defineStore, acceptHMRUpdate } from 'pinia'

    export const useViewStore = defineStore('view', () => {

        const scrollDist = ref(0)

        const pageNavTitle = ref(null)
        const pageNavItems = ref([])

        const isEmbedded = ref(false)

        //
        // View helpers
        //

        function pageNavLoad(title, items) {
            pageNavTitle.value = title
            pageNavItems.value = items
        }

        function pageNavUnload() {
            pageNavTitle.value = null
            pageNavItems.value = []
        }

        watch(isEmbedded, () => {
            if (isEmbedded.value) {
                document.body.classList.add('bg-[#EDEDED]')
                document.body.classList.remove('bg-lifeworx-alabaster')
            }
        })


        return {
            scrollDist, isEmbedded,
            pageNavTitle, pageNavItems,
            pageNavLoad, pageNavUnload
        }

    })

    if (import.meta.hot) {
        import.meta.hot.accept(acceptHMRUpdate(useViewStore, import.meta.hot))
    }
