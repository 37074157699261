<script setup>
    import { computed, ref, watch } from 'vue'
    import { storeToRefs } from 'pinia'

    import LwxComponentLabel from '@/Components/LwxComponentLabel.vue'
    import { MagnifyingGlassIcon, ListBulletIcon } from '@heroicons/vue/24/outline'
    import { XMarkIcon } from '@heroicons/vue/24/solid';
    import InputLabel from '@/Components/Forms/InputLabel.vue'
    import LwxNumber from '@/Components/Forms/LwxNumber.vue'
    import SearchMultiSelect from '@/Components/Forms/SearchMultiSelect.vue'
    import Modal from '@/Components/App/Modal.vue'

    import { useClientSingleStore, useConfigStore, useFormStatusStore } from '@/Stores'

    import { getModifiedProperties } from '@/Helpers'

    const formStatus = useFormStatusStore()
    const formLoading = formStatus.computedFormLoading('client_care_need')

    const configStore = useConfigStore()
    const clientSingleStore = useClientSingleStore()

    const { client } = storeToRefs(clientSingleStore)
    const { config, HealthConditionDict, ExpertFactorDict } = storeToRefs(configStore)

    const emit = defineEmits(['client:update', 'client:cancel-update', 'client:store'])

    const multiSelectCategory = ref(null)
    const localClient = ref({
        height: {
        },
        health_conditions: [],
        expert_must_have_factors: [],
        expert_wow_factors: [],
    })
    const clientPropsChange = ref(false)

    const handleChange = (data) => {
        if (Object.keys(data).length > 0) {
            emit('client:update', data, 'client_care_need');
        } else {
            emit('client:cancel-update', client.value.id);
        }
    }

    const handleStore = (data) => {
        if (Object.keys(data).length > 0) {
            emit('client:store', data, 'client_care_need');
        }
    }

    const categories = {
        'health_condition' : [
            { key: 'health_conditions', label: 'Active', summary: 'health condition', color: 'lifeworx-red-500'}
        ],
        'expert_factor' : [
            { key: 'expert_must_have_factors', label: 'Must-Have', summary: 'must-have', color: 'lifeworx-orange-500'},
            { key: 'expert_wow_factors', label: 'Wow-Factor', summary: 'wow-factor', color: 'lifeworx-purple-400'}
        ]
    }

    const leadTexts = {
        'health_condition' : 'Health Condition',
        'expert_factor' : 'Caregiver Attribute'
    }

    const options = computed(() => {
        return {
            'health_condition' : config.value.care_need.health_conditions,
            'expert_factor' : config.value.care_need.expert_factors
        };
    })

    const selected = computed(() => {
        const selectedOptions = {}
        if (multiSelectCategory.value === 'health_condition') {
            selectedOptions['health_conditions'] = localClient.value.health_conditions.slice()
        } else {
            selectedOptions['expert_must_have_factors'] = localClient.value.expert_must_have_factors.slice()
            selectedOptions['expert_wow_factors'] = localClient.value.expert_wow_factors.slice()
        }
        return selectedOptions;
    })

    function handleSelected(selectedOptions) {
        Object.keys(selectedOptions).forEach(item => {
            localClient.value[item] = selectedOptions[item].slice()
        })
    }

    function handleSelectedRemoval(optionId, field) {
        localClient.value[field] = localClient.value[field].filter(item => item !== optionId)
    }

    watch(() => client.value, (value) => {
        localClient.value = JSON.parse(JSON.stringify(value))
        localClient.value.height = {
            foot: value.height ? Math.floor(value.height / 12) : null,
            inch: value.height ? value.height % 12 : null
        }
        if (localClient.value.id) {
            clientPropsChange.value = true
        }
    }, { immediate : true })


    function extractClient(proxyClient) {
        let height = NaN
        if (proxyClient.height.foot) {
            height = parseInt(proxyClient.height.foot) * 12
        }

        if (proxyClient.height.inch) {
            height = isNaN(height) ? 0 : height
            height += parseInt(proxyClient.height.inch)
        }

        return {
            ...proxyClient,
            height: height
        };
    }

    watch(localClient, (newValue, oldValue) => {
        if (oldValue === undefined) {
            clientPropsChange.value = false;
            return;
        }

        if (oldValue.id === newValue.id) {
            if(clientPropsChange.value == false) {
                const diff = getModifiedProperties(
                    JSON.stringify(extractClient(newValue)),
                    JSON.stringify(client.value),
                    [
                        'height', 'weight', 'health_conditions', 'expert_must_have_factors', 'expert_wow_factors'
                    ]
                )
                if (newValue.id != undefined) {
                    handleChange(diff)
                } else {
                    handleStore(diff)
                }
                return;
            }
        }
        clientPropsChange.value = false
    }, { immediate: true, deep: true })
</script>

<template>
    <div class="p-4 pt-3 pb-5" id="client_care_need">
        <div class="grid grid-cols-3 gap-10 mb-5">
            <div class="col-span-1">
                <div class="grid">
                    <LwxComponentLabel value="Health Conditions" />
                </div>
                <div class="border rounded bg-neutral-50 border-dotted">
                    <div class="px-3 py-2 h-32 overflow-y-scroll">
                        <ul class="flex flex-wrap">
                            <li
                                v-for="(condition_id, i) in localClient.health_conditions"
                                :key="i"
                                class=""
                            >
                                <span class="text-xs inline-block border border-lifeworx-red-200 bg-lifeworx-red-100 bg-opacity-50 font-semibold text-lifeworx-red-500 p-1 px-2 rounded-full mb-1 mr-2 cursor-default">
                                    {{ HealthConditionDict[condition_id].name }}
                                    <XMarkIcon
                                        class="cursor-pointer inline-flex h-4 w-4 text-lifeworx-red-500 stroke-2"
                                        @click="() => handleSelectedRemoval(condition_id, 'health_conditions')"
                                    />
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div
                        class="p-2 bg-white flex border-t cursor-text hover:ring-1"
                        @click="() => multiSelectCategory = 'health_condition'"
                    >
                        <MagnifyingGlassIcon class="flex-none inline-flex h-6 w-6 text-neutral-400 cursor-pointer"/>
                        <div class="flex-grow flex items-center text-xs align-middle text-neutral-400 rounded px-2 italic">Start typing to search...</div>
                        <ListBulletIcon class="flex-none inline-flex h-6 w-6 cursor-pointer"/>
                    </div>
                </div>
            </div>
            <div class="col-span-2">
                <div class="grid grid-cols-2">
                    <LwxComponentLabel value="Expert Must-Haves" />
                    <LwxComponentLabel class="px-3" value="Expert Wow-Factor" />
                </div>
                <div class="border rounded bg-neutral-50 border-dotted">
                    <div class="py-2 overflow-y-scroll h-32">
                        <div class="grid grid-cols-2 ">
                            <div class="px-3 border-r border-dashed">
                                <ul class="flex flex-wrap">
                                    <li
                                        v-for="(factor_id, i) in localClient.expert_must_have_factors"
                                        :key="i"
                                        class=""
                                    >
                                        <span class="text-xs inline-block border border-lifeworx-orange-300 bg-lifeworx-orange-200  bg-opacity-50 font-semibold text-lifeworx-orange-600 p-1 px-2 rounded-full mb-1 mr-2 cursor-default">
                                            {{ ExpertFactorDict[factor_id].name }}
                                            <XMarkIcon
                                                class="cursor-pointer inline-flex h-4 w-4 text-lifeworx-orange-600 stroke-2"
                                                @click="() => handleSelectedRemoval(factor_id, 'expert_must_have_factors')"
                                            />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div class="px-3">
                                <ul class="flex flex-wrap">
                                    <li
                                        v-for="(factor_id, i) in localClient.expert_wow_factors"
                                        :key="i"
                                        class=""
                                    >
                                        <span class="text-xs inline-block border border-lifeworx-purple-100 bg-lifeworx-purple-50 bg-opacity-50 font-semibold text-lifeworx-purple-400 p-1 px-2 rounded-full mb-1 mr-2 cursor-default">
                                            {{ ExpertFactorDict[factor_id].name }}
                                            <XMarkIcon
                                                class="cursor-pointer inline-flex h-4 w-4 text-lifeworx-purple-500 stroke-2"
                                                @click="() => handleSelectedRemoval(factor_id, 'expert_wow_factors')"
                                            />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div
                        class="p-2 bg-white flex border-t cursor-text hover:ring-1"
                        @click="() => multiSelectCategory = 'expert_factor'"
                    >
                        <MagnifyingGlassIcon class="flex-none inline-flex h-6 w-6 text-neutral-400 cursor-pointer"/>
                        <div class="flex-grow flex items-center text-xs align-middle text-neutral-400 rounded px-2 italic">Start typing to search...</div>
                        <ListBulletIcon class="flex-none inline-flex h-6 w-6 cursor-pointer"/>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <LwxComponentLabel value="Additional Details" />
            <div class="flex-wrap md:flex gap-10">
                <div class="flex-wrap md:flex-none">
                    <InputLabel
                        for="height"
                        value="Height"
                        class="text-xs text-stone-400 whitespace-nowrap w-fit"
                    >
                    </InputLabel>
                    <div class="flex gap-4">
                        <LwxNumber
                            v-model="localClient.height.foot"
                            unit="foot"
                            :rangeMin=4
                            :rangeMax=7
                        />
                        <LwxNumber
                            v-model="localClient.height.inch"
                            unit="inch"
                            :rangeMin=0
                            :rangeMax=11
                        />
                    </div>
                </div>
                <div class="flex-wrap md:flex-none">
                    <InputLabel
                        for="weight"
                        value="Weight"
                        class="text-xs text-stone-400 whitespace-nowrap w-fit"
                    >
                    </InputLabel>
                    <LwxNumber
                        v-model="localClient.weight"
                        unit="pound"
                        :rangeMin=75
                        :step=25
                    />
                </div>
                <div class="flex-1 flex justify-end">
                    <div class="w-8 -ml-1 mt-1 pr-5 grid content-end">
                        <svg
                            v-if="formLoading"
                            class="h-5 w-5 animate-spin text-lifeworx-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        >
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            :show="multiSelectCategory != null"
            :padding-enable="false"
            @close="() => multiSelectCategory = null"
        >
            <SearchMultiSelect
                :options="options[multiSelectCategory] ? options[multiSelectCategory] : []"
                :categories="categories[multiSelectCategory] ? categories[multiSelectCategory] : []"
                :lead_text="leadTexts[multiSelectCategory] ? leadTexts[multiSelectCategory] : ''"
                :close="() => multiSelectCategory = null"
                :selected="selected"
                @update:selected="handleSelected"
            />
        </Modal>
    </div>
</template>
