export function usePubSub(eventLists) {
    const events = {}

    function subscribe(event, callback) {
        if (!events[event]) {
            return
        }

        const id = crypto.randomUUID()

        events[event].push({id, callback})

        return id
    }

    function unsubscribe(event, id) {
        if (!events[event]) {
            return
        }

        events[event] = events[event].filter(subscriber => subscriber.id !== id)
    }

    function publish(event, argumentArr = []) {
        if (!events[event]) {
            return
        }

        for (let i in events[event]) {
            events[event][i].callback(...argumentArr)
        }
    }

    for (let i in eventLists) {
        events[eventLists[i]] = []
    }

    return {
        subscribe, unsubscribe, publish
    }
}
