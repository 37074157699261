import { ref } from 'vue'

import { defineStore, acceptHMRUpdate } from 'pinia'

import { Loader } from '@googlemaps/js-api-loader'

export const useGoogleMapsStore = defineStore('googleMaps', () => {

    const googleMapsApiKey = ref(import.meta.env.VITE_GOOGLE_MAPS_API_KEY)

    const googleMapsApiAutocompleteSessionToken = ref()
    const googleMapsApiAutocompleteService = ref()
    const googleMapsApiPlacesService = ref()

    const googleMapsLoaderApi = new Loader({
        apiKey: googleMapsApiKey.value,
        version: 'weekly',
        libraries: ['maps', 'places'],
    })

    const googleMapsLoader = ref(googleMapsLoaderApi)

    const mapRef = ref(null)
    const mapBounds = ref()

    function resetMap(center, markers) {
        mapRef.value.map?.getStreetView().setVisible(false)
        mapBounds.value = new mapRef.value.api.LatLngBounds()
        mapBounds.value.extend({ lat: center.lat, lng: center.lng })
        markers.forEach((marker) => mapBounds.value.extend(marker))
        mapRef.value.map.setCenter(mapBounds.value.getCenter())
        mapRef.value.map.fitBounds(mapBounds.value)
        mapRef.value.map.setZoom(mapRef.value.map.getZoom()-1)
    }

    function centerMap(lat, lng) {
        mapRef.value.map.panTo({ lat: lat, lng: lng })
    }

    function zoomToMarker(lat, lng) {
        mapRef.value.map.getStreetView().setVisible(false)
        mapRef.value.map.panTo({ lat: lat, lng: lng })
        mapRef.value.map.setZoom(15)
        mapRef.value.map.panBy(mapRef.value.map.getDiv().offsetWidth / 4 * -1, 0)
    }

    return {
        mapRef,
        centerMap, resetMap, zoomToMarker,
        googleMapsApiKey, googleMapsLoader, googleMapsApiAutocompleteSessionToken, googleMapsApiAutocompleteService, googleMapsApiPlacesService
    }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useGoogleMapsStore, import.meta.hot))
}
