<script setup>

    import { onMounted, onUnmounted, ref } from 'vue'

    import LatteStyledContainer from '@/Components/App/LatteStyledContainer.vue'
    import LatteClientContacts from '@/Components/Contacts/LatteClientContacts.vue'
    import LatteClientCareNeed from '@/Components/Client/LatteClientCareNeed.vue'
    import { UserPlusIcon } from '@heroicons/vue/20/solid'
    import LatteProfileClientIntro from '@/Components/LatteProfileClientIntro.vue';

    import { useClientSingleStore, useNotificationStore, useViewStore, useModuleLockStore } from '@/Stores'

    import { storeToRefs } from 'pinia'

    import { useJobRunner } from '@/Helpers/jobRunner'

    import { run, finish, isFinished, isRunBefore } from '@/Helpers/jobState'
    import { deepCopy } from '@/Helpers'

    const clientSingleStore = useClientSingleStore()
    const { isEdit, client } = storeToRefs(clientSingleStore)

    const notificationStore = useNotificationStore()

    const viewStore = useViewStore()

    const loading = ref(true)
    const addNew = ref(true)


    const navItems = []

    function navSetup() {
        navItems[0] = {
            id: 'care_need',
            label: 'Care Needs'
        }
        navItems[1] = {
            id: 'contact',
            label: 'Contacts'
        }
    }


    async function hydrateClient(data) {
        await clientSingleStore.hydrate(data)
        loading.value = false
    }

    function rehydrateClient(data) {
        loading.value = true
        hydrateClient(data)
    }

    function cancelClientUpdate(clientId) {
        jobRunner.removeJob('update', clientId)
    }

    const jobRunner = useJobRunner('client')
    jobRunner.subscribe('addedRetryJob', function(errorCode, retryTime) {
        notificationStore.add('Error Saving Changes', 'error', `Retrying automatically... (${retryTime}/20)`, 15000)
    })
    jobRunner.subscribe('addedJob', function() {
        clientSingleStore.resetInactivityRefreshTimer()
        notificationStore.removeById(clientSingleStore.getMaxRetryDialogId())
        clientSingleStore.removeMaxRetryDialogId()
    })
    jobRunner.subscribe('reachedMaxRetry', function() {
        const maxRetryDialogId = notificationStore.add('Error Saving Changes', 'error', 'Latest changes not saved. Make an edit to try saving again.', 7 * 24 * 60 * 60 * 1000)
        clientSingleStore.setMaxRetryDialogId(maxRetryDialogId)
    })

    const moduleLockStore = useModuleLockStore()
    const isTopLeftEnable = moduleLockStore.computedModule('topLeftModule')
    const isContactModuleEnable = moduleLockStore.computedModule('contactModule')
    let followUpDataAfterCreate = null
    async function storeClient(data, formId) {
        moduleLockStore.disable('contactModule')
        if (isRunBefore('client', 'create') == false || isFinished('client', 'create') == true) {
            jobRunner.addJob('create', null, data, [formId], async (data, formId, jobId) => {
                run('client', 'create')
                await clientSingleStore.storeClient(data, formId)
                    .then(function (response) {
                        moduleLockStore.enable('contactModule')
                        hydrateClient(response.data)
                        history.pushState(
                            {},
                            null,
                            `/client/${response.data.id}`
                        )
                        jobRunner.handleJobSuccess('create', null, jobId)
                        if (followUpDataAfterCreate) {
                            addUpdateClientJob(deepCopy(updateFollowUpData(followUpDataAfterCreate, response.data)), formId, true)
                        }
                        finish('client', 'create')

                        notificationStore.add('New Client Created')
                    })
                    .catch(function(error) {
                        finish('client', 'create')
                        if (followUpDataAfterCreate) {
                            const followUpDataAfterCreateCopy = deepCopy(followUpDataAfterCreate)
                            const finalData = {...data, ...followUpDataAfterCreateCopy}
                            storeClient(finalData, formId)
                        }
                        jobRunner.handleJobError('create', null, jobId, error.error.response?.status)
                    })
                    .finally(function() {
                        followUpDataAfterCreate = null
                    })
            })
        } else {
            followUpDataAfterCreate = data
        }
    }

    function addUpdateClientJob(data, formId, runNow = false) {
        const func = runNow ? 'addAndRunJob' : 'addJob'

        jobRunner[func]('update', client.value.id, data, [formId], async (data, formId, jobId) => {
            clientSingleStore.applyLocalChangeToActiveClient(data)
            await clientSingleStore.updateClient(client.value.id, data, formId, jobId)
                .then(function (response) {
                    if (jobRunner.hasScheduleJob('update', client.value.id) == false) {
                        hydrateClient(response.data)
                    }
                    jobRunner.handleJobSuccess('update', client.value.id, jobId)
                    notificationStore.add('Changes Saved Successfully')
                })
                .catch(function(error) {
                    jobRunner.handleJobError('update', client.value.id, jobId, error.error.response?.status)
                })
                .finally(function() {
                })
        })
    }

    function updateFollowUpData(followUpData, responseData) {
        if (followUpData.contacts) {
            followUpData.contacts.forEach((contact) => {
                const index = responseData.contacts.findIndex(item => item.uuid == contact.uuid)
                if (index != -1) {
                    contact.id = responseData.contacts[index].id
                }
            })
        }

        return followUpData;
    }

    async function updateClient(data, formId) {
        addUpdateClientJob(data, formId)
    }

    onMounted(() => {
        isEdit.value = true
        navSetup()
        viewStore.pageNavLoad('Create New Client', navItems)
    })

    onUnmounted(() => {
        viewStore.pageNavUnload()
    })

</script>

<template>

    <div class="mx-auto max-w-7x md:px-8 sm:pt-4 md:pt-6">

        <div class="mx-auto max-w-5xl">

            <div class="px-4 grid sm:grid-cols-1 gap-4 md:grid-cols-12">

                <LatteProfileClientIntro
                    @client:store="storeClient"
                    @client:update="updateClient"
                    @client:cancel-update="cancelClientUpdate"
                    :enable="isTopLeftEnable != false"
                />

            </div>

            <LatteStyledContainer
                id="care_need"
                title="Care Needs"
                :collapsible="true"
                :forceSingleLineMobileLayout="true"
            >
                <template #default>
                    <LatteClientCareNeed
                        @client:update="updateClient"
                        @client:store="storeClient"
                        @client:cancel-update="cancelClientUpdate"
                    />
                </template>
            </LatteStyledContainer>

            <LatteStyledContainer
                id="contact"
                title="Contacts"
                :collapsible="true"
                :forceSingleLineMobileLayout="true"
            >

                <template #header>
                    <button
                        class="rounded bg-white black py-1 flex space-x-1 items-center text-xs font-bold px-4"
                        @click="addNew = true"
                    >
                        <span>Add New</span> <UserPlusIcon class="block h-6 w-5 pr-1" aria-hidden="true"/>
                    </button>
                </template>

                <template #default>
                    <LatteClientContacts
                        :addNew="addNew"
                        @update:selected="() => {addNew = false}"
                        @update:clientData="rehydrateClient"
                        :enable="isContactModuleEnable"
                    />
                </template>

            </LatteStyledContainer>

        </div>
    </div>

</template>
