<script setup>

    import { onMounted, onUnmounted, ref, watch } from 'vue'
    import { storeToRefs } from 'pinia'

    import LatteStyledContainer from '@/Components/App/LatteStyledContainer.vue'
    import LatteClientContacts from '@/Components/Contacts/LatteClientContacts.vue'
    import LatteProfileClientIntro from '@/Components/LatteProfileClientIntro.vue'
    import LatteClientCareNeed from '@/Components/Client/LatteClientCareNeed.vue'
    import LatteClientSchedule from '@/Components/Client/LatteClientSchedule.vue'

    import { UserPlusIcon } from '@heroicons/vue/20/solid'

    import { useCaregiverStore, useClientSingleStore, useNotificationStore, useViewStore } from '@/Stores'

    import { useJobRunner } from '@/Helpers/jobRunner'

    import { useRoute } from 'vue-router'
    import { util } from '@/Helpers'
    import WeekPicker from '@/Components/WeekPicker.vue'

    const loading = ref(true)
    const scheduleDate = ref(util.date.lastSunday())
    const viewStore = useViewStore()
    const clientSingleStore = useClientSingleStore()
    const caregiverStore = useCaregiverStore()
    const route = useRoute()

    const notificationStore = useNotificationStore()

    const { client } = storeToRefs(clientSingleStore)

    const addNew = ref(false)
    const selectedContactId = ref(null)

    const jobRunner = useJobRunner('client')
    jobRunner.subscribe('addedRetryJob', function(errorCode, retryTime) {
        notificationStore.add('Error Saving Changes', 'error', `Retrying automatically... (${retryTime}/20)`, 15000)
        clientSingleStore.stopInactivityTimeout()
    })
    jobRunner.subscribe('finishedJobSuccess', function() {
        clientSingleStore.resetInactivityRefreshTimer()
    })
    jobRunner.subscribe('addedJob', function() {
        clientSingleStore.resetInactivityRefreshTimer()
        notificationStore.removeById(clientSingleStore.getMaxRetryDialogId())
        clientSingleStore.removeMaxRetryDialogId()
    })
    jobRunner.subscribe('reachedMaxRetry', function() {
        const maxRetryDialogId = notificationStore.add('Error Saving Changes', 'error', 'Latest changes not saved. Make an edit to try saving again.', 7 * 24 * 60 * 60 * 1000)
        clientSingleStore.setMaxRetryDialogId(maxRetryDialogId)
    })


    const navItems = []

    function navSetup() {
        navItems[0] = {
            id: 'care_need',
            label: 'Care Needs'
        }
        navItems[1] = {
            id: 'contact',
            label: 'Contacts'
        }
    }


    async function hydrateClient(data) {
        await clientSingleStore.hydrate(data)
        loading.value = false
    }

    async function hydrateCaregiversAll(data) {
        loading.value = true
        await caregiverStore.hydrateAllCaregivers(data)
        loading.value = false
    }

    function rehydrateClient(data) {
        loading.value = true
        hydrateClient(data)
    }

    function updateClient(data, formId) {
        jobRunner.addJob('update', client.value.id, data, [formId], async (data, formId, jobId) => {
            clientSingleStore.applyLocalChangeToActiveClient(data)
            await clientSingleStore.updateClient(client.value.id, data, formId, jobId)
                .then(function (response) {
                    if (jobRunner.hasScheduleJob('update', client.value.id) == false) {
                        hydrateClient(response.data)
                    }
                    jobRunner.handleJobSuccess('update', client.value.id, jobId)
                    notificationStore.add('Changes Saved Successfully')
                })
                .catch(function(error) {
                    jobRunner.handleJobError('update', client.value.id, jobId, error.error.response?.status)
                })
        })
    }

    function cancelClientUpdate(clientId) {
        jobRunner.removeJob('update', clientId)
    }

    function selectContact(contact) {
        selectedContactId.value = contact;
    }

    function selectAddNewPrimaryContact() {
        clientSingleStore.changeAddNewPrimaryContactMode(true);
        addNew.value = false;
        setTimeout(function() {addNew.value = true;}, 0)
    }

    onMounted(async () => {
        navSetup()

        const client_id = route.params.client
        viewStore.pageNavLoad('Client Profile', navItems)
        clientSingleStore.getClientById(client_id)
            .then(response => {
                rehydrateClient(response.data)
            })

        caregiverStore.getAllCaregivers()
            .then(response => {
                hydrateCaregiversAll(response.data)
            })
    })

    onUnmounted(() => {
        viewStore.pageNavUnload()
    })

    const scheduleBuilderMode = ref(null)
</script>

<template>

    <div class="mx-auto max-w-7x md:px-8 sm:pt-4 md:pt-6">

        <div class="mx-auto max-w-5xl">

            <div class="px-4 grid sm:grid-cols-1 gap-4 md:grid-cols-12">

                <LatteProfileClientIntro
                    @client:update="updateClient"
                    @contact:selected="selectContact"
                    @contact:addNewPrimary="selectAddNewPrimaryContact"
                    @client:cancel-update="cancelClientUpdate"
                />

            </div>

            <LatteStyledContainer
                id="care_need"
                title="Care Needs"
                :collapsible="true"
                :forceSingleLineMobileLayout="true"
            >
                <template #default>
                    <LatteClientCareNeed
                        @client:update="updateClient"
                        @client:cancel-update="cancelClientUpdate"
                    />
                </template>
            </LatteStyledContainer>

            <LatteStyledContainer
                id="schedule"
                title="Care Schedule"
                :collapsible="true"
                :forceSingleLineMobileLayout="true"
            >
                <template #header>
                    <div class="w-full flex justify-center items-center">
                        <WeekPicker v-model="scheduleDate" :disabled="scheduleBuilderMode == 'edit'"/>
                    </div>
                </template>
                <template #default>
                    <LatteClientSchedule
                        :client_id="Number(route.params.client)"
                        :start_date="scheduleDate"
                        @builder-mode:change="(mode) => scheduleBuilderMode = mode"
                    />
                </template>
            </LatteStyledContainer>

            <LatteStyledContainer
                id="contact"
                title="Contacts"
                :collapsible="true"
                :forceSingleLineMobileLayout="true"
            >

                <template #header>
                    <button
                        class="rounded bg-white black py-1 flex space-x-1 items-center text-xs font-bold px-4"
                        @click="addNew = true"
                    >
                        <span>Add New</span> <UserPlusIcon class="block h-6 w-5 pr-1" aria-hidden="true"/>
                    </button>
                </template>

                <template #default>
                    <LatteClientContacts
                        :addNew="addNew"
                        :selectedContactId="selectedContactId"
                        @update:selected="() => {addNew = false}"
                        @update:clientData="rehydrateClient"
                    />
                </template>

            </LatteStyledContainer>

        </div>
    </div>

</template>
