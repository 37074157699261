<script setup>

    import { computed, ref, nextTick } from 'vue'

    import { storeToRefs } from 'pinia'

    import { hideAllPoppers } from 'floating-vue'

    import { PlusCircleIcon } from '@heroicons/vue/20/solid'

    import { useAuthStore, useNotificationStore, useJobStore, useRatingStore, useClientStore, useCaregiverStore } from '@/Stores'
    import { useNProgress } from '@vueuse/integrations/useNProgress'

    const authStore = useAuthStore()

    const notificationStore = useNotificationStore()
    const ratingStore = useRatingStore()

    const jobStore = useJobStore()
    const { jobs } = storeToRefs(jobStore)

    const clientStore = useClientStore()
    const { clients } = storeToRefs(clientStore)

    const caregiverStore = useCaregiverStore()
    const { caregivers } = storeToRefs(caregiverStore)

    const { isLoading } = useNProgress()
    const loading = ref(isLoading)

    const props = defineProps({
        job: {
            type: Object
        },
        isMobile: {
            type: Boolean,
            default: false
        }
    })

    const emit = defineEmits(['rated'])

    const isMobile = computed(() => props.isMobile)

    const ratingNote = ref(null)
    const ratingNoteRedRef = ref()
    const ratingNoteYellowRef = ref()
    const ratingNoteGreenRef = ref()

    const isRedShown = ref(false)
    const isYellowShown = ref(false)
    const isGreenShown = ref(false)

    async function setFocus(color) {
        await nextTick();
        document.getElementById('ratingNote' + color.charAt(0).toUpperCase() + color.slice(1)).focus()
    }

    const selectedColor = computed(() => {
        if (isRedShown.value) { return 'red'; }
        if (isYellowShown.value) { return 'yellow'; }
        if (isGreenShown.value) { return 'green'; }
    })

    function handleUnsaved() {
        if (ratingNote.value) {
            //
        }
        onHide()
    }

    function saveRatingWithNote() {
        if (loading.value === true) {
            return;
        }

        loading.value = true
        const payload = {
            status: selectedColor.value,
            note_content: ratingNote.value ? ratingNote.value.trim() : null
        }

        ratingStore.store(props.job.id, payload).then((response) => {

            if (response.status >= 200 && response.status < 400) {

                jobs.value[props.job.id].ratings.push(response.data)

                // Clean up existing rating for this client
                const clientRatingPos = clients.value[props.job.client.id].latest_rating_per_job.findIndex(el => el.job_id === props.job.id)
                if (clientRatingPos >= 0) {
                    clients.value[props.job.client.id].latest_rating_per_job.splice(clientRatingPos, 1)
                }

                clients.value[props.job.client.id].latest_rating_per_job.push(response.data)

                // Clean up existing rating for this caregiver
                const caregiverRatingPos = caregivers.value[props.job.caregiver.id].latest_rating_per_job.findIndex(el => el.job_id === props.job.id)
                if (caregiverRatingPos >= 0) {
                    caregivers.value[props.job.caregiver.id].latest_rating_per_job.splice(caregiverRatingPos, 1)
                }

                caregivers.value[props.job.caregiver.id].latest_rating_per_job.push(response.data)

                ratingNote.value = null
                hideAllPoppers()
                onHide()

                notificationStore.add('Rating saved')
                loading.value = false

                emit('rated')

            }

        }).catch((err) => {
            authStore.handleError(err)
            loading.value = false
        })


    }

    const bodyEl = document.getElementsByTagName('body')[0];
    function onShow() {
        bodyEl.classList.add("overflow-hidden")
    }

    function onHide() {
        bodyEl.classList.remove("overflow-hidden")
    }

</script>

<template>

    <div class="md:space-x-2 flex">

        <VDropdown
            :triggers="['click']"
            @apply-show="setFocus('red') && isMobile && onShow()"
            @apply-hide="handleUnsaved() && isMobile && onHide()"
            :distance="6"
            :positioning-disabled="isMobile"
            theme="lifeworx-red-outline"
            placement="top-start"
            no-auto-focus
            v-model:shown="isRedShown"
        >

            <PlusCircleIcon
                class="inline-flex max-sm:h-12 h-8 max-sm:w-12 w-8 text-lifeworx-red-500  hover:opacity-100 cursor-pointer"
                :class="{ 'opacity-100': isRedShown, 'opacity-60': !isRedShown }"
                aria-hidden="true"
            />
            <span class="sr-only">
                Add Red Rating
            </span>

            <template #popper>
                <div class="md:w-72">
                    <div class="w-full flex pt-2 px-2 text-lg md:text-sm space-x-2">
                        <h4 class="font-bold text-lifeworx-red-500 flex-grow">
                            Note Required
                        </h4>
                        <button
                            v-close-popper
                            class="font-semibold text-zinc-500"
                        >
                            Cancel
                        </button>
                        <button
                            class="font-semibold text-lifeworx-blue-500 disabled:text-zinc-300"
                            :disabled="!ratingNote || ratingNote.trim() == ''"
                            @click="saveRatingWithNote()"
                        >
                            Save
                        </button>
                    </div>

                    <label for="ratingNoteRed" class="sr-only">Add your note</label>
                    <textarea
                        rows="4"
                        v-model="ratingNote"
                        name="ratingNoteRed"
                        ref="ratingNoteRedRef"
                        id="ratingNoteRed"
                        class="block w-full border-0 bg-transparent py-1.5 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 h-40 md:h-auto"
                        placeholder="Enter a note..."
                        autofocus
                    />
                </div>
            </template>

        </VDropdown>

        <VDropdown
            :triggers="['click']"
            @apply-show="setFocus('yellow') && isMobile && onShow()"
            @apply-hide="handleUnsaved() && isMobile && onHide()"
            :distance="6"
            :positioning-disabled="isMobile"
            theme="lifeworx-yellow-outline"
            placement="top-start"
            no-auto-focus
            v-model:shown="isYellowShown"
        >

            <PlusCircleIcon
                class="inline-flex max-sm:h-12 h-8 max-sm:w-12 w-8 text-lifeworx-yellow-500 hover:opacity-100  cursor-pointer"
                :class="{ 'opacity-100': isYellowShown, 'opacity-60': !isYellowShown }"
                aria-hidden="true"
            />
            <span class="sr-only">
                Add Yellow Rating
            </span>

            <template #popper>
                <div class="md:w-72">
                    <div class="w-full flex pt-2 px-2 text-lg md:text-sm space-x-2">
                        <h4 class="font-bold text-lifeworx-yellow-500 flex-grow">
                            Note Required
                        </h4>
                        <button
                            v-close-popper
                            class="font-semibold text-zinc-500"
                        >
                            Cancel
                        </button>
                        <button
                        @click="saveRatingWithNote()"
                            class="font-semibold text-lifeworx-blue-500 disabled:text-zinc-300"
                            :disabled="!ratingNote || ratingNote.trim() == ''"
                        >
                            Save
                        </button>
                    </div>

                    <label for="ratingNoteYellow" class="sr-only">Add your note</label>
                    <textarea
                        rows="4"
                        v-model="ratingNote"
                        name="ratingNoteYellow"
                        id="ratingNoteYellow"
                        ref="ratingNoteYellowRef"
                        class="block w-full border-0 bg-transparent py-1.5 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 h-40 md:h-auto"
                        placeholder="Enter a note..."
                        autofocus
                    />
                </div>
            </template>

        </VDropdown>

        <VDropdown
            :triggers="['click']"
            :distance="6"
            @apply-show="setFocus('green') && isMobile && onShow()"
            @apply-hide="handleUnsaved() && isMobile && onHide()"
            :positioning-disabled="isMobile"
            theme="lifeworx-green-outline"
            placement="top-start"
            no-auto-focus
            v-model:shown="isGreenShown"
        >

            <PlusCircleIcon
                class="inline-flex max-sm:h-12 h-8 max-sm:w-12 w-8 text-lifeworx-green-500 hover:opacity-100 cursor-pointer"
                :class="{ 'opacity-100': isGreenShown, 'opacity-60': !isGreenShown }"
                aria-hidden="true"
            />
            <span class="sr-only">
                Add Green Rating
            </span>

            <template #popper>
                <div class="md:w-72">
                    <div class="w-full flex pt-2 px-2 text-lg md:text-sm space-x-2">
                        <h4 class="font-bold text-lifeworx-green-500 flex-grow">
                            Note Optional
                        </h4>
                        <button
                            v-close-popper
                            class="font-semibold text-zinc-500"
                        >
                            Cancel
                        </button>
                        <button
                        @click="saveRatingWithNote()"
                            class="font-semibold text-lifeworx-blue-500 disabled:text-zinc-300"
                        >
                            Save
                        </button>
                    </div>

                    <label for="ratingNoteGreen" class="sr-only">Add your note</label>
                    <textarea
                        rows="4"
                        v-model="ratingNote"
                        ref="ratingNoteGreenRef"
                        name="ratingNoteGreen"
                        id="ratingNoteGreen"
                        class="block w-full border-0 bg-transparent py-1.5 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 h-40 md:h-auto"
                        placeholder="Enter a note..."
                        autofocus
                    />

                </div>
            </template>

        </VDropdown>

    </div>

</template>

<style>

    body.no-scroll {
        overflow: hidden;
    }

    .v-popper__popper--no-positioning {
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
    }

    .v-popper__popper--no-positioning .v-popper__backdrop {
        display: block;
        background: rgba(0 0 0 / 90%);
    }

    .v-popper__popper--no-positioning .v-popper__wrapper {
        width: 100%;
        pointer-events: auto;
        transition: transform .15s ease-out;
    }

    .v-popper__popper--no-positioning.v-popper__popper--hidden .v-popper__wrapper {
        transform: translateY(100%);
    }

</style>
