<script setup>

    import { ref } from 'vue'

    const today = new Date()

    const props = defineProps({
        config: {
            type: Object
        },
    })

</script>

<template>

    <footer
        class="mx-auto sm:w-full md:max-w-3xl text-xs font-semibold tracking-wider leading-snug text-center text-gray-400 py-10"
    >

        &copy; {{ today.getFullYear() }} LifeWorx, Inc.
        <br class="inline-block md:hidden" />
        All Rights Reserved.
        <br />
        <span v-if="props.config.env !== 'production'">
            ({{ props.config.env }} environment)
            <br />
            breakpoint: <span class="sm:inline md:hidden">sm</span><span class="hidden md:inline lg:hidden">md</span><span class="hidden lg:inline xl:hidden">lg</span><span class="hidden xl:inline">xl</span>
        </span>

    </footer>

</template>
