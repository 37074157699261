import { computed, ref } from 'vue'

import { defineStore, acceptHMRUpdate } from 'pinia'

export const useFormStatusStore = defineStore('formStatus', () => {

    const status = ref({})
    const loading = ref({})

    function cleanup() {
        for (let formId in status.value) {
            if (status.value[formId] && new Date(status.value[formId].expires.getTime() + 300) < new Date()) {
                status.value[formId] = null
            }
        }
    }
    setInterval(cleanup, 1000)

    function add(formId, message, variant='message', details) {
        if (!status.value[formId]) {
            status.value[formId] = null
        }

        status.value[formId] = {
            variant: variant,
            message: message,
            details: details,
            expires: new Date(new Date().getTime() + 5000)
        }
    }

    function addLoading(formId) {
        loading.value[formId] = true
    }

    function removeLoading(formId) {
        loading.value[formId] = false
    }

    function computedFormStatus(formId) {
        return computed(() => status.value[formId])
    }

    function computedFormLoading(formId) {
        return computed(() => loading.value[formId])
    }

    return {
        computedFormStatus, add,
        computedFormLoading, addLoading, removeLoading
    }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useFormStatusStore, import.meta.hot))
}
