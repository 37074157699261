import { reactive, ref } from 'vue'
import axios from 'axios'

const requestMap = reactive({})

export async function makeRequest(config) {
    return await new Promise((resolve, reject) => {
        const key = `${config.url}_${config.method}`

        cancelRequest(config.url, config.method)

        const cancelTokenSource = axios.CancelToken.source()
        const previousRequest = {
            method: config.method,
            cancelTokenSource,
        };

        requestMap[key] = previousRequest
        axios({
            ...config,
            cancelToken: cancelTokenSource.token,
        })
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            if (axios.isCancel(error)) {
            } else {
                reject(error)
            }
        }).finally(() => {
            delete(requestMap[key])
        })
    })
}

export async function get(url, config = {}) {
    return makeRequest({ ...config, url, method: 'GET' })
}

export async function post(url, data, config = {}) {
    return makeRequest({ ...config, url, data, method: 'POST' })
}

export async function put(url, data, config = {}) {
    return makeRequest({ ...config, url, data, method: 'PUT' })
}

export async function patch(url, data, config = {}) {
    return makeRequest({ ...config, url, data, method: 'PATCH' })
}

export function cancelRequest(url, method) {
    const key = `${url}_${method}`
    let previousRequest = requestMap[key]

    if (
        previousRequest &&
        previousRequest.method === method &&
        previousRequest.cancelTokenSource
    ) {
        previousRequest.cancelTokenSource.cancel('Operation canceled due to new request.');
    }
}
