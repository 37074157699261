<script setup>

    import { onMounted } from 'vue'

    import { storeToRefs } from 'pinia'

    // import Checkbox from '@/Components/Checkbox.vue'
    import InputError from '@/Components/Forms/InputError.vue'
    import InputLabel from '@/Components/Forms/InputLabel.vue'
    import PrimaryButton from '@/Components/Forms/PrimaryButton.vue'
    import TextInput from '@/Components/Forms/TextInput.vue'

    import { LockClosedIcon } from '@heroicons/vue/20/solid'

    import { useRoute, useRouter } from 'vue-router'

    import { useAuthStore, useConfigStore, useViewStore } from '@/Stores'

    const configStore = useConfigStore()
    const authStore = useAuthStore()
    const viewStore = useViewStore()

    const { config } = storeToRefs(configStore)
    const { auth, form, user } = storeToRefs(authStore)
    const { isEmbedded } = storeToRefs(viewStore)

    const router = useRouter()
    const route = useRoute()

    const props = defineProps({
        canResetPassword: {
            type: Boolean,
        },
        status: {
            type: String,
        },
        data: {
            type: Object
        }
    })

    const submit = () => {
        authStore.login(form.value.email, form.value.password, props.data?.redirect)
    }

    onMounted(() => {
        if (localStorage.getItem('AUTH_SESSION_EXPIRED')) {
            localStorage.removeItem('AUTH_SESSION_EXPIRED')
            form.value.errors.email = ['Your session expired, please log in again.']
        }
    })

</script>

<template>

    <div>
        <h2 class="font-serif text-3xl font-black text-center mb-8">
            {{ !isEmbedded ? 'Welcome' : 'Latte Login Required' }}
        </h2>

        <div v-if="status" class="mb-4 font-medium text-sm text-lifeworx-green-600">
            {{ status }}
        </div>

        <form @submit.prevent="submit">
            <div>
                <InputLabel for="email" value="Email" />

                <TextInput
                    id="email"
                    type="email"
                    class="mt-1 block w-full"
                    v-model="form.email"
                    required
                    autofocus
                    autocomplete="email"
                    placeholder="hello@lifeworx.com"
                />

                <InputError
                    v-if="form.errors.email"
                    class="mt-2"
                    :message="form.errors.email[0]"
                />
            </div>

            <div class="mt-4">
                <InputLabel for="password" value="Password" />

                <TextInput
                    id="password"
                    type="password"
                    class="mt-1 block w-full"
                    v-model="form.password"
                    required
                    autocomplete="current-password"
                />

                <InputError
                    v-if="form.errors.password"
                    class="mt-2"
                    :message="form.errors.password[0]"
                />
            </div>

            <div class="grid grid-cols-2 my-6">
                <div>
                    <button
                        v-if="canResetPassword"
                        @click="router.push('password.request')"
                        class="underline text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lifeworx-blue-500 dark:focus:ring-offset-gray-800"
                    >
                        Forgot your password?
                    </button>
                </div>
            </div>

            <div class="w-full">
                <PrimaryButton
                    class="w-full"
                    :class="{ 'opacity-50': form.processing }"
                    :disabled="form.processing"
                >

                    Log in

                    <LockClosedIcon class="-mr-2 ml-2 h-5 w-5" aria-hidden="true" v-if="!form.processing" />
                    <svg class="-mr-2 ml-2 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-else>
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>

                </PrimaryButton>
            </div>
        </form>

    </div>

</template>
