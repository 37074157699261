import { computed, reactive } from 'vue'

import { defineStore, acceptHMRUpdate } from 'pinia'

import { util } from '@/Helpers'
import { useJobStore } from './job'

export const useJobBucketedStore = defineStore('jobBucketedStore', () => {

    const jobStore = useJobStore()

    //
    // Computed Bucketing
    //

    function sortJobsByClientInRange(start, end) {
        let jobs = jobStore.bucketedJobsByWeek(start, end)
        let clients = jobStore.clientsFromJobs(jobs)
        let jobsByClient = {}

        clients.forEach(client => {
            jobsByClient[client] = util.convertArrayToObject(jobStore.jobsFromClient(client, jobs), 'id')
        })

        return jobsByClient;
    }

    const sortedJobsByClientNew = computed(() => {
        return sortJobsByClientInRange(1, 1)
    })

    const sortedJobsByClientRecent = computed(() => {
        return sortJobsByClientInRange(5, 2)
    })

    const sortedJobsByClientOld = computed(() => {
        return sortJobsByClientInRange(14, 6)
    })

    const sortedJobsByClient = reactive({
        new: sortedJobsByClientNew,
        recent: sortedJobsByClientRecent,
        old: sortedJobsByClientOld
    })


    //
    // Computed Client Jobs Ordered
    //

    const sortedJobsByClientNewOrdered = computed(() => {
        return jobStore.orderClientsAndJobs(sortedJobsByClientNew)
    })

    const sortedJobsByClientRecentOrdered = computed(() => {
        return jobStore.orderClientsAndJobs(sortedJobsByClientRecent)
    })

    const sortedJobsByClientOldOrdered = computed(() => {
        return jobStore.orderClientsAndJobs(sortedJobsByClientOld)
    })

    const sortedJobsByClientArray = reactive({
        new: sortedJobsByClientNewOrdered,
        recent: sortedJobsByClientRecentOrdered,
        old: sortedJobsByClientOldOrdered
    })

    //
    // Computed Progress
    //

    function computeProgress(start, end) {
        const jobs = jobStore.bucketedJobsByWeek(start, end)

        if (jobs.length == 0) {
            return 100;
        }

        return (jobs.filter(function(job) {
            return job.ratings.length > 0
        }).length / jobs.length) * 100;
    }
    const ratingProgressNew = computed(() => {
        return computeProgress(1, 1)
    })

    const ratingProgressRecent = computed(() => {
        return computeProgress(5, 2)
    })

    const ratingProgressOld = computed(() => {
        return computeProgress(14, 6)
    })

    const progressByBucket = reactive({
        new: ratingProgressNew,
        recent: ratingProgressRecent,
        old: ratingProgressOld
    })

    return {
        sortedJobsByClient, sortedJobsByClientArray, progressByBucket
    }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useJobBucketedStore, import.meta.hot))
}
