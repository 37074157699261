<script setup>
    import { ref, watch } from 'vue';

    const props = defineProps({
        modelValue: {
            required: true
        },
        value: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        }
    })

    const emit = defineEmits(['update:modelValue'])
    const onChange = () => {
        const keys = Object.keys(weekDay.value)
        const selectedDays = []
        keys.forEach(day => {
            if (weekDay.value[day] == 'yes') {
                selectedDays.push(day)
            }
        })
        emit('update:modelValue', selectedDays)
    };

    const weekDay = ref({
        sunday: 'no',
        monday: 'no',
        tuesday: 'no',
        wednesday: 'no',
        thursday: 'no',
        friday: 'no',
        saturday: 'no'
    })

    function check(days, value) {
        days.forEach(item => {
            weekDay.value[item] = value
        })
    }

    function selectAll()
    {
        if (props.disabled) {
            return
        }
        check(Object.keys(weekDay.value), 'yes')
        onChange()
    }

    function selectWeekDays()
    {
        if (props.disabled) {
            return
        }
        check(Object.keys(weekDay.value), 'no')
        check(['monday', 'tuesday', 'wednesday', 'thursday', 'friday'], 'yes')
        onChange()
    }

    function selectWeekend()
    {
        if (props.disabled) {
            return
        }
        check(Object.keys(weekDay.value), 'no')
        check(['sunday', 'saturday'], 'yes')
        onChange()
    }

    watch(() => props.modelValue, (newValue) => {
        Object.keys(weekDay.value).forEach(day => {
            weekDay.value[day] = (newValue.includes(day)) ? 'yes' : 'no'
        })
    })
</script>

<template>
    <div :class="{
        'opacity-50': props.disabled
    }" class="bg-lifeworx-blue-100 rounded py-5">
        <div class="text-center mb-2">{{ props.title }}</div>
        <ul class="list-none grid grid-cols-7 gap-1 mb-2">
            <li class="col-span-1 text-center"
                v-for="day in Object.keys(weekDay)"
            >
                <label :for="`w-${day}`" class="block font-semibold"
                    :class="{
                        'cursor-pointer' : !props.disabled
                    }"
                >
                    {{ day.charAt(0).toUpperCase() }}
                </label>
                <input
                    :id="`w-${day}`"
                    type="checkbox"
                    v-model="weekDay[day]"
                    true-value="yes"
                    false-value="no"
                    :disabled="props.disabled"
                    @change="onChange"
                    :class="{
                        'cursor-pointer' : !props.disabled
                    }"
                />
            </li>
        </ul>
        <div class="flex justify-around">
            <div class="cursor-pointer text-sm underline underline-offset-1 font-semibold text-lifeworx-orange-500" @click="selectWeekDays">Weekdays</div>
            <div class="cursor-pointer text-sm underline underline-offset-1 font-semibold text-lifeworx-orange-500" @click="selectWeekend">Weekends</div>
            <div class="cursor-pointer text-sm underline underline-offset-1 font-semibold text-lifeworx-orange-500" @click="selectAll">All</div>
        </div>
    </div>

</template>
