<script setup>

    import { ref, watch } from 'vue'

    import LatteMapViewListItem from '@/Components/Maps/LatteMapViewListItem.vue'

    import { storeToRefs } from 'pinia'
    import { useMapStore } from '@/Stores'

    const props = defineProps({
        data: {},
    })

    const mapStore = useMapStore()

    const { selectedId, scrollToId } = storeToRefs(mapStore)

    const emit = defineEmits(['update:selected', 'reset'])

    watch(scrollToId, () => {
        if (!scrollToId) return;
        let el = document.getElementById('latte-map-view-list-item-' + scrollToId.value)
        el.parentElement.parentElement.parentElement.scrollTo({
            top: (el.scrollHeight * [ ...el.parentNode.children ].indexOf(el)),
            behavior: 'smooth'
        })
    })

</script>

<template>

    <div class="divide-y-2 divide-gray-100 max-h-full">

        <ol
            class="w-full h-fit justify-between gap-x-6"
        >

            <LatteMapViewListItem
                v-for="(marker, index) in props.data"
                :key="marker.id"
                :data="marker"
                :index="index"
                :selected="selectedId == marker.id"
                @click="selectedId == marker.id ? selectedId = null & emit('reset') : emit('update:selected', marker.id)"
            />

            <li
                v-if="!props.data || props.data && props.data.length === 0"
                class="h-96 w-full flex text-center justify-center items-center font-semibold text-gray-400 italic"
            >
                No results nearby
            </li>

        </ol>

    </div>

</template>
