<script setup>

    import { ref, watch, computed } from 'vue'
    import pluralize from 'pluralize';
    import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid'
    import PrimaryButton from '@/Components/Forms/PrimaryButton.vue'

    const emit = defineEmits(['update:selected'])

    const props = defineProps({
        options: {
            /** @type import('vue').PropType<Array<{ id: string, name: string }>> */
            type: Array,
            required: true,
        },
        categories: {
            /** @type import('vue').PropType<Array<{ key: string, label: string, summary: string, color: string }>> */
            type: Array,
            required: true,
        },
        lead_text: {
            type: String,
            required: true
        },
        close: {
            type: Function,
            required: true
        },
        selected: {
            /** @type import('vue').PropType<Object<{ [key: string]: number[] }>> */
            type: Object,
            required: true,
        }
    })

    const input = ref(null)
    const filterOptions = ref(props.options)

    function handleSelect(optionId, categoryKey)
    {
        const newSelect = {}
        Object.keys(props.selected).forEach(key => {
            newSelect[key] = props.selected[key].slice()
            if (key !== categoryKey) {
                newSelect[key] = newSelect[key].filter(item => item !== optionId)
            } else {
                if (newSelect[key].includes(optionId)) {
                    newSelect[key] = newSelect[key].filter(item => item !== optionId)
                } else {
                    newSelect[key].push(optionId)
                }
            }
        })
        emit('update:selected', newSelect)
    }

    function toggleRow(option) {
        let currentCategoryIndex = null;
        let keys = Object.keys(props.selected)
        keys.forEach((element, index) => {
            if (props.selected[element].includes(option.id)) {
                currentCategoryIndex = index
            }
        })
        if (currentCategoryIndex == (keys.length-1)) {
            handleSelect(option.id, keys[currentCategoryIndex])
        } else if (currentCategoryIndex == null) {
            handleSelect(option.id, keys[0])
        } else {
            handleSelect(option.id, keys[currentCategoryIndex+1])
        }
    }

    const selectedSummary = computed(() => {
        const data = []
        props.categories?.forEach(category => {
            if (props.selected[category.key].length) {
                const count = props.selected[category.key].length
                data.push({
                    count: count,
                    label: count > 1 ? pluralize(category.summary.toLowerCase()) : category.summary.toLowerCase(),
                    color: category.color
                })
            }
        })

        return data;
    })

    watch(input, (newValue) => {
        if (newValue != null && newValue.trim() !== '') {
            const searchString = newValue.trim().toLowerCase()
            filterOptions.value = props.options.filter(option =>
                option.name.toLowerCase().includes(searchString)
            )
        } else {
            filterOptions.value = props.options
        }
    }, {immediate: true})

</script>

<template>
    <div class="flex w-full flex-col rounded-lg">
        <div class="flex p-3 rounded-t-lg focus-within:ring-lifeworx-blue-500 focus-within:ring-1 focus-within:ring-inset border-b border-stone-300">
            <MagnifyingGlassIcon class="flex-none inline-flex h-6 w-6 text-neutral-400"/>
            <input
                class="border-0 h-6 flex-grow w-full px-2.5 py-1.5 text-sm text-stone-700 placeholder:text-stone-400 placeholder:italic focus:ring-0 bg-transparent"
                id="search"
                placeholder="Start typing to search..."
                type="text"
                v-model="input"
                autocomplete="off"
            />
        </div>
        <div class="pb-3 h-[32rem] overflow-y-scroll block">
            <table class="w-full">
                <thead class="bg-white sticky top-0 shadow">
                    <tr>
                        <td class="py-2 px-6">
                            <span class="font-bold text-xs text-stone-500">{{ props.lead_text }}</span>
                        </td>
                        <td
                            v-for="(category, i) in props.categories"
                            :key="i"
                            class="text-center py-2 text-xs font-bold"
                        >
                            <span :class="'text-' + category.color">{{ category.label }}</span>
                        </td>
                        <td class="w-8"></td>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(option, i) in filterOptions"
                        :key="i"
                        class="border-b cursor-pointer hover:bg-gray-200 text-lifeworx-blue-900 group"
                        :class="{
                            'bg-stone-100' : i % 2 == 0
                        }"
                        @click="toggleRow(option)"

                    >
                        <td class="py-2 pl-6 text-sm text-lifeworx-blue-800 group-hover:text-lifeworx-blue-900 group-hover:font-semibold">{{ option.name }}</td>
                        <td
                            v-for="(category, index) in props.categories"
                            :key="index"
                            class="text-center py-2 px-4"
                        >
                            <input
                                type="checkbox"
                                @click.stop="() => handleSelect(option.id, category.key)"
                                :checked="selected[category.key].includes(option.id)"
                                class="focus:ring-0"
                                :class="'text-' + category.color"
                            />
                        </td>
                        <td class="w-8"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="bg-white flex justify-between border-t border-stone-300 px-6 py-3">
            <div class="grid content-center">
                <span
                    v-if="selectedSummary.length"
                    class="inline-block h-full text-sm"
                >
                    Selected
                    <template v-for="(summary, i) in selectedSummary">
                        <span :class="'text-' + summary.color" class="font-semibold">{{ summary.count }} {{ summary.label }}</span>
                        <template v-if="selectedSummary.length > 1 && i === selectedSummary.length - 2"> and </template>
                        <template v-if="selectedSummary.length > 1 && i < selectedSummary.length - 2">, </template>
                    </template>
                </span>
            </div>
            <PrimaryButton @click="props.close">Done</PrimaryButton>
        </div>
    </div>
</template>
