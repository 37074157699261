<script setup>

    import { onMounted, ref } from 'vue'

    import { useClientSingleStore, useViewStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import LatteStyledContainer from '@/Components/App/LatteStyledContainer.vue'
    import LatteClientContacts from '@/Components/Contacts/LatteClientContacts.vue'

    import { UserPlusIcon } from '@heroicons/vue/20/solid'
    import { useRoute } from 'vue-router'

    const viewStore = useViewStore()
    const clientSingleStore = useClientSingleStore()
    const route = useRoute()


    const { isEmbedded } = storeToRefs(viewStore)

    const addNew = ref(false)

    onMounted(async () => {
        clientSingleStore.getClientByCoffeeUserProfileId(route.params.coffee_client_user_profile_id)
            .then(response => {
                rehydrate(response.data)
            })
    })

    async function hydrate(data) {
        await clientSingleStore.hydrate(data)
    }

    function rehydrate(data) {
        hydrate(data)
    }

</script>

<template>

    <div
        class="mx-auto max-w-7xl"
        :class="{
            'pt-0': isEmbedded,
            'sm:pt-4 md:pt-6 md:px-8': !isEmbedded
        }"
    >

        <div class="mx-auto max-w-5xl">

            <LatteStyledContainer
                id="latte-client-contacts"
                title="Contacts"
                :forceSingleLineMobileLayout="true"
                :isEmbedded="isEmbedded"
            >

                <template #header>
                    <button
                        class="rounded bg-white black py-1 flex space-x-1 items-center text-xs font-bold px-4"
                        @click="addNew = true"
                    >
                        <span>Add New</span> <UserPlusIcon class="block h-6 w-5 pr-1" aria-hidden="true"/>
                    </button>
                </template>

                <template #default>
                    <LatteClientContacts
                        :addNew="addNew"
                        @update:selected="() => {addNew = false}"
                        :showNotification="!isEmbedded"
                    />
                </template>

            </LatteStyledContainer>

        </div>

    </div>

</template>
