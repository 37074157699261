import { ref } from 'vue'

import { defineStore, acceptHMRUpdate } from 'pinia'

import * as Sentry from "@sentry/vue";

import { useNProgress } from '@vueuse/integrations/useNProgress'

export const useRatingStore = defineStore('rating', () => {

    const { isLoading } = useNProgress()
    const loading = ref(isLoading)



    //
    // API
    //

    async function store(job_id, payload) {

        loading.value = true

        return await new Promise((resolve, reject) => {
            axios.post('/api/v1/jobs/' + job_id + '/ratings', payload)
            .then(function (response) {
                // Handle redirect here
                if (response.status === 201) {
                    const redirectUrl = response.headers.location;
                    return axios.get(redirectUrl);
                }
            }).then(function (response) {
                loading.value = false
                resolve(response)
            }).catch(function (error) {
                loading.value = false
                reject({ "error": error })
                Sentry.captureException(error)
            })
        });

    }



    return {
        store,
        loading
    }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useRatingStore, import.meta.hot))
}
