<script setup>

    import { computed } from 'vue'

    import { storeToRefs } from 'pinia'

    import { useStaffStore, useClientStore, useCaregiverStore } from '@/Stores'

    import LatteStaffLabel from '@/Components/LatteStaffLabel.vue'

    import moment from 'moment'

    const staffStore = useStaffStore()
    const { staffs } = storeToRefs(staffStore)

    const clientStore = useClientStore()
    const { clients } = storeToRefs(clientStore)

    const caregiverStore = useCaregiverStore()
    const { caregivers } = storeToRefs(caregiverStore)


    const props = defineProps({
        subject: {
            type: Object
        },
        subjectType: {
            type: String
        },
        job: {
            type: Object
        },
        bordered: {
            type: Boolean,
            default: false
        }
    })

    const subject = computed(() => {

        return props.subjectType == "client" ? clients.value[props.subject.id] : caregivers.value[props.subject.id];

     })

    const ratings = computed(() => {
        const ratingsDefault = { 0: { }, 1: { }, 2: { }, 3: { }, 4: { }, 5: { }, 6: { }, 7: { }, 8: { }, 9: { } }

        if (!subject.value.latest_rating_per_job) {
            return ratingsDefault;
        }

        return Object.assign(ratingsDefault, subject.value.latest_rating_per_job.slice(-10))

    })

    const staffId = props.subject.assigned_sales_manager ? props.subject.assigned_sales_manager : props.subject.assigned_recruiter
    const caregiverCoffeeBaseUrl = import.meta.env.VITE_COFFEE_BASEURL + '/user/recruiter-candidates/personalinfo/id/'
    const clientCoffeeBaseUrl = import.meta.env.VITE_COFFEE_BASEURL + '/staff/sales-manager/information/pid/'

</script>

<template>

    <div class="w-full border-spacing-2 rounded-md p-0.5 h-5 items-center justify-center" :class="{ 'border border-lifeworx-blue-400': bordered, 'border border-lifeworx-red-500': subject.hard_no_at }">

        <div class="grid grid-cols-10 h-full divide-x divide-white divide-opacity-50 [&>*]:h-full bg-zinc-300 rounded-[4px] overflow-clip">

            <template v-for="(rating, index) in ratings">

                <VDropdown
                    v-if="rating.status && ['red', 'yellow', 'green'].includes(rating.status)"
                    :triggers="['hover', 'click']"
                    :popperTriggers="['hover']"
                    :show-group="rating.id.toString()"
                    :distance="10"
                    :skidding="-10"
                    :theme="'lifeworx-' + rating.status + '-outline'"
                    placement="top-start"
                    :key="rating.id"
                >

                    <div
                        v-if="rating.status == 'red'"
                        class="h-full flex bg-lifeworx-red-500 bg-opacity-60 hover:bg-opacity-100 hover:shadow cursor-pointer text-white items-center justify-center" :class="{ 'bg-opacity-100': subjectType == 'caregiver' && props.job.client.id == rating.client.id }"
                    >
                        <div v-if="subjectType == 'caregiver' && props.job.client.id == rating.client.id" class="h-1 w-1 bg-lifeworx-red-600 rounded-full"></div>
                    </div>

                    <div
                        v-else-if="rating.status == 'yellow'"
                        class="h-full flex bg-lifeworx-yellow-500 bg-opacity-60 hover:bg-opacity-100 hover:shadow cursor-pointer text-white items-center justify-center" :class="{ 'bg-opacity-100': subjectType == 'caregiver' && props.job.client.id == rating.client.id }"
                    >
                        <div v-if="subjectType == 'caregiver' && props.job.client.id == rating.client.id" class="h-1 w-1 bg-lifeworx-yellow-600 rounded-full"></div>
                    </div>

                    <div
                        v-else-if="rating.status == 'green'"
                        class="h-full flex bg-lifeworx-green-500 bg-opacity-60 hover:bg-opacity-100 hover:shadow cursor-pointer text-white items-center justify-center" :class="{ 'bg-opacity-100': subjectType == 'caregiver' && props.job.client.id == rating.client.id }"
                    >
                        <div v-if="subjectType == 'caregiver' && props.job.client.id == rating.client.id" class="h-1 w-1 bg-lifeworx-green-600 rounded-full"></div>
                    </div>

                    <template #popper>
                        <div class="p-2 w-full">
                            <div class="flex text-sm space-x-1.5 pb-2">
                                <LatteStaffLabel
                                    :staff="rating.created_by_staff"
                                />
                                <span
                                    class="text-zinc-400 font-semibold cursor-default"
                                    :title="moment(rating.created_at).utc().format('MMMM Do YYYY, h:mm:ss a')"
                                >
                                    {{ moment(rating.created_at).utc().from(moment.utc()) }}
                                </span>
                            </div>
                            <div class="flex text-sm space-x-1.5 pb-2 ">
                                <h4
                                    class="tracking-wide text-lifeworx-blue-700 font-semibold text-sm"
                                >
                                    <template v-if="props.subjectType == 'caregiver'">
                                        <span class="text-zinc-400">Re: Client </span>
                                        <a
                                            :href="clientCoffeeBaseUrl + rating.client.coffee_user_profile_id"
                                            target="_blank"
                                            class="hover:underline"
                                        >
                                            {{ rating.client.first_name + ' ' + rating.client.last_name }}
                                        </a>
                                    </template>
                                    <template v-else-if="props.subjectType == 'client'">
                                        <span class="text-zinc-400">Re: Caregiver </span>
                                        <a
                                            :href="caregiverCoffeeBaseUrl + rating.caregiver.coffee_job_profile_id"
                                            target="_blank"
                                            class="hover:underline"
                                        >
                                            {{ rating.caregiver.first_name + ' ' + rating.caregiver.last_name }}
                                        </a>
                                    </template>
                                </h4>
                            </div>
                            <div class="max-w-md px-4 pb-4">
                                <span
                                    class="text-sm"
                                    :class="{ 'italic font-semibold text-zinc-400': !rating.note }"
                                >
                                    {{ rating.note ? rating.note.content : 'No note added' }}
                                </span>
                            </div>
                        </div>
                    </template>

                </VDropdown>

                <div v-else></div>

            </template>

        </div>
    </div>

</template>
