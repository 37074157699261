<script setup>

    import { computed } from 'vue'

    import moment from 'moment'

    const props = defineProps({
        job: {
            type: Object
        }
    })

    const schedule = computed(() =>
        [
            props.job ? props.job.typical_schedule.includes('Sunday') : false,
            props.job ? props.job.typical_schedule.includes('Monday') : false,
            props.job ? props.job.typical_schedule.includes('Tuesday') : false,
            props.job ? props.job.typical_schedule.includes('Wednesday') : false,
            props.job ? props.job.typical_schedule.includes('Thursday') : false,
            props.job ? props.job.typical_schedule.includes('Friday') : false,
            props.job ? props.job.typical_schedule.includes('Saturday') : false
        ]
    )

</script>

<template>
    <div class="grid">
        <p class="italic text-center text-lifeworx-red-500 text-sm md:text-xs font-semibold">
            {{ props.job.start_date === null ? 'Missing Start Date' : '' }}
        </p>
        <VTooltip
            :triggers="['click', 'touch', 'hover']"
            :autoHide="true"
        >
            <h4
            class="uppercase tracking-normal md:tracking-tightest whitespace-nowrap text-zinc-300 font-bold text-2xl md:text-md lg:text-xl font-mono"
            :class="{ 'mt-2': !(props.job.start_date === null) }">

                <span class="pr-1.5 lg:pr-2" :class="{ 'text-lifeworx-blue-500': schedule[0], 'line-through select-none': !schedule[0] }" title="Sunday">
                    {{ schedule[0] ? 'S' : 'S' }}
                </span>

                <span class="pr-1.5 lg:pr-2" :class="{ 'text-lifeworx-blue-500': schedule[1], 'line-through select-none': !schedule[1] }" title="Monday">
                    {{ schedule[1] ? 'M' : 'M' }}
                </span>

                <span class="pr-1.5 lg:pr-2" :class="{ 'text-lifeworx-blue-500': schedule[2], 'line-through select-none': !schedule[2] }" title="Tuesday">
                    {{ schedule[2] ? 'T' : 'T' }}
                </span>

                <span class="pr-1.5 lg:pr-2" :class="{ 'text-lifeworx-blue-500': schedule[3], 'line-through select-none': !schedule[3] }" title="Wednesday">
                    {{ schedule[3] ? 'W' : 'W' }}
                </span>

                <span class="pr-1.5 lg:pr-2" :class="{ 'text-lifeworx-blue-500': schedule[4], 'line-through select-none': !schedule[4] }" title="Thursday">
                    {{ schedule[4] ? 'T' : 'T' }}
                </span>

                <span class="pr-1.5 lg:pr-2" :class="{ 'text-lifeworx-blue-500': schedule[5], 'line-through select-none': !schedule[5] }" title="Friday">
                    {{ schedule[5] ? 'F' : 'F' }}
                </span>

                <span class="pr-1.5 lg:pr-2" :class="{ 'text-lifeworx-blue-500': schedule[6], 'line-through select-none': !schedule[6] }" title="Saturday">
                    {{ schedule[6] ? 'S' : 'S' }}
                </span>
            </h4>
            <template #popper>
                <span class="text-sm">
                    {{ props.job.start_date ? 'Job started ' + moment(props.job.start_date).utc().format('MMMM Do, YYYY') : 'Please add a start date in QuickBooks' }}
                </span>
            </template>
        </VTooltip>
    </div>

</template>
