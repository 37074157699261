<script setup>

    import { watch, onMounted, ref } from 'vue'

    import LatteIconMapPin from '@/Icons/LatteIconMapPin.vue'

    import { GoogleMap, CustomMarker } from 'vue3-google-map'
    import { ultraLight } from 'vue3-google-map/themes'

    import { storeToRefs } from 'pinia'
    import { useMapStore, useGoogleMapsStore, useClientStore } from '@/Stores'

    const mapStore = useMapStore()
    const googleMapsStore = useGoogleMapsStore()
    const clientStore = useClientStore()

    const { googleMapsApiKey, mapRef } = storeToRefs(googleMapsStore)

    const { selectedId, scrollToId, mapCenter } = storeToRefs(mapStore)
    const { clients } = storeToRefs(clientStore)

    const props = defineProps({
        mapCenter: {},
        nearbyClients: {
            default: [],
            type: Array
        }
    })

    watch(() => props.nearbyClients?.value, (ready) => {
        mapStore.resetMap()
    })

    onMounted(async () => {
        mapStore.bindClient(googleMapsStore)
    })

</script>

<template>
    <GoogleMap
        ref="mapRef"
        :api-key="googleMapsApiKey"
        style="width: 100%; height: 100%;"
        :styles="ultraLight"
        :center="{ lat: mapCenter.geo.lat, lng: mapCenter.geo.lng }"
        :control-size="20"
        :map-type-control="true"
        :fullscreen-control="false"
        :map-type-control-options="{ position: 3 }"
        :clickable-icons="false"
    >

            <CustomMarker
                v-if="mapCenter.geo"
                :options="{ position: { lat: mapCenter.geo.lat, lng: mapCenter.geo.lng }, anchorPoint: 'BOTTOM_CENTER' }"
                @click="props.selectMarker(0)"
                data-marker-client-id="map-search-center-marker"
            >
                <LatteIconMapPin
                    class="text-lifeworx-green inline-block ml-0.5 -mt-1.5 -mr-1 -mb-1 h-5 w-5"
                />
            </CustomMarker>

            <CustomMarker
                v-for="(marker, index) in props.nearbyClients"
                :key="marker.id"
                :options="{ position: { lat: parseFloat(marker.geo.lat), lng: parseFloat(marker.geo.lng) }, anchorPoint: 'BOTTOM_CENTER' }"
                @click="selectedId == marker.id ? selectedId = null & mapStore.resetMap() : mapStore.selectMarker(marker.id)"
                :data-marker-client-id="marker.id"
            >
                <LatteIconMapPin
                    :class="{
                        'text-lifeworx-purple': marker.type == 'client',
                        'text-lifeworx-orange': marker.type == 'prospect',
                        'opacity-50': selectedId !== marker.id
                    }"
                    class="inline-block ml-0.5 -mt-1.5 -mr-1 -mb-1 h-5 w-5"
                />
            </CustomMarker>

    </GoogleMap>
</template>
