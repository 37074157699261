<script setup>

    import { computed, onMounted, onUnmounted, watch } from 'vue'
    import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'

    const props = defineProps({
        show: {
            type: Boolean,
            default: false,
        },
        paddingEnable: {
            type: Boolean,
            default: true
        },
        maxWidth: {
            type: String,
            default: '2xl',
        },
        closeable: {
            type: Boolean,
            default: true,
        },
    })

    const emit = defineEmits(['close'])

    watch(
        () => props.show,
        () => {
            if (props.show) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = null
            }
        }
    )

    const close = () => {
        if (props.closeable) {
            emit('close')
        }
    }

    const closeOnEscape = (e) => {
        if (e.key === 'Escape' && props.show) {
            close()
        }
    }

    onMounted(() => { document.addEventListener('keydown', closeOnEscape) })

    onUnmounted(() => {
        document.removeEventListener('keydown', closeOnEscape)
        document.body.style.overflow = null
    })

    const maxWidthClass = computed(() => {
        return {
            sm: 'sm:max-w-sm',
            md: 'sm:max-w-md',
            lg: 'sm:max-w-lg',
            xl: 'sm:max-w-xl',
            '2xl': 'sm:max-w-2xl',
        }[props.maxWidth];
    })

</script>

<template>

    <Teleport to="body">
        <TransitionRoot
            as="template"
            :show="show"
        >
            <Dialog
                as="div"
                class="relative z-overlay"
                @close="open = false"
            >

                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity backdrop-blur-sm" />
                </TransitionChild>

                <div class="fixed inset-0 z-modal w-screen overflow-y-auto">
                    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild
                            as="template"
                            enter="ease-out duration-300"
                            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enter-to="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leave-from="opacity-100 translate-y-0 sm:scale-100"
                            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel
                                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-32 sm:w-full"
                                :class="[{
                                    'px-4 pb-4 pt-5 sm:p-6' : paddingEnable,
                                }, maxWidthClass
                                ]"
                            >
                                <slot />
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>

            </Dialog>
        </TransitionRoot>
    </Teleport>

</template>
