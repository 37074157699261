<script setup>
    import { computed, ref, watch } from 'vue'

    import { deepCopy, util } from '@/Helpers';
    import CaregiverAutocomplete from '@/Components/Forms/CaregiverAutocomplete.vue';
    import RadioInput from '@/Components/Forms/RadioInput.vue';
    import LwxSelect from '@/Components/Forms/LwxSelect.vue';
    import InputLabel from '@/Components/Forms/InputLabel.vue';
    import DatePickr from '@/Components/Forms/DatePickr.vue';
    import WeekDaySelect from '@/Components/Forms/WeekDaySelect.vue';
    import moment from 'moment';
    import { XCircleIcon } from '@heroicons/vue/24/outline';
    import AreaLabel from '@/Components/AreaLabel.vue';
    import { useConfigStore } from '@/Stores';
    import { storeToRefs } from 'pinia';
    import { LockClosedIcon } from '@heroicons/vue/20/solid';

    const emit = defineEmits(['shifts:save', 'shifts-validated-data:change', 'shifts:update', 'builder-form:reset'])

    const configStore = useConfigStore()
    const { config } = storeToRefs(configStore)

    const props = defineProps({
        client_type: {
            type: String,
            required: true
        },
        edit_state: {
            type: [Object, null],
            required: true
        },
        refresh: {
            type: Boolean,
            required: true
        },
        charge_rates: {
            type: Array,
            default: []
        },
        prospect_rate_adjustment: {
            type: Object,
            required: true
        }
    })

    const defaultState = {
        stay_type: 'live_out',
        duration_type: 'ongoing',
        min_charge_rate_amount: null,
        max_charge_rate_amount: null,
        charge_rate_amount: null,
        charge_duration_rate_type: 'hourly',
        rate_modifier: 'standard',
        start_time: {
            id: '09:00am',
            name: '09:00am'
        },
        duration: {
            id: '12:00',
            name: '12:00'
        },
        assigned_caregiver: {
            id: undefined,
            first_name: undefined,
            last_name: undefined,
        },
        start_date: util.date.lastSunday(),
        end_date: null,
        dayOfWeeks: []
    }
    const shiftState = ref({...defaultState})

    const lengthWindows = util.generateTimeWindows(15).map(window => ({
        id: window,
        name: window
    }))

    const startTimeSelections = util.generate24HoursTime().map(window => ({
        id: window,
        name: window
    }))

    function extractOutput() {
        const info = deepCopy(shiftState.value)
        if (info.start_time) {
            info.start_time = moment(info.start_time.id, 'hh:mma').format('HH:mm')
        }

        if (info.duration) {
            info.duration = info.duration.id
            info.duration = util.date.toMinute(info.duration)
        }
        info.assigned_caregiver_id = info.assigned_caregiver ? info.assigned_caregiver.id : null

        info.min_charge_rate_amount = info.min_charge_rate_amount ? info.min_charge_rate_amount.id : null
        info.max_charge_rate_amount = info.max_charge_rate_amount ? info.max_charge_rate_amount.id : null

        return info
    }

    function saveShifts() {
        if (props.edit_state == null) {
            emit('shifts:save', extractOutput())
        } else {
            if (shiftState.start_date != props.edit_state.start_date || shiftState.end_date != props.edit_state.end_date) {
                emit('shifts:update', extractOutput(), null)
            }
        }
    }

    function reset() {
        shiftState.value = {...defaultState}
        emit('builder-form:reset')
    }

    watch(shiftState, (newValue) => {
        if (newValue.stay_type == 'live_out') {
            shiftState.value.charge_duration_rate_type = 'hourly'
        }
        if (newValue.duration_type == 'ongoing') {
            shiftState.value.end_date = null
        }
    }, { immediate: true, deep: true })

    const editMode = computed(() => {
        return props.edit_state != null
    })

    watch(() => shiftState, (newValue) => {
        emit('shifts-validated-data:change', extractOutput())
    }, {immediate: true, deep: true})

    watch(() => props.edit_state, (newValue, oldValue) => {
        if (newValue == null) {
            shiftState.value = {...defaultState}
            return
        }

        if(JSON.stringify(newValue) != JSON.stringify(oldValue)) {
            applyEditState(newValue)
        }
    })

    function applyEditState(stateValue)
    {
        const editState = {
            ...stateValue
        }

        if (stateValue.start_time) {
            const formattedTime = util.date.to12CycleTime(stateValue.start_time).toLowerCase().replace(/\s+/g, '');
            editState.start_time = {
                id: formattedTime,
                name: formattedTime
            }
        }

        if (stateValue.duration) {
            editState.duration = {
                id: util.date.durationToHour(stateValue.duration),
                name: util.date.durationToHour(stateValue.duration)
            }
        }

        if (stateValue.min_charge_rate_amount) {
            editState.min_charge_rate_amount = {
                id: parseFloat(stateValue.min_charge_rate_amount),
                name: parseFloat(stateValue.min_charge_rate_amount)
            }
        }

        if (stateValue.max_charge_rate_amount) {
            editState.max_charge_rate_amount = {
                id: parseFloat(stateValue.max_charge_rate_amount),
                name: parseFloat(stateValue.max_charge_rate_amount)
            }
        }

        Object.keys(editState).forEach(key => shiftState.value[key] = editState[key])
    }

    watch(() => props.refresh, (newValue, oldValue) => {
        if (newValue = true) {
            shiftState.value = {...defaultState}
        }
    }, {immediate: true})

    watch(shiftState, (newValue) => {
        if (props.client_type == 'prospect') {
            return
        }

        const representDayOfWeek = shiftState.value.dayOfWeeks.reduce((acc, day) => {
            if (acc === '') {
                return acc
            }

            if (acc === null) {
                return day
            }

            const weekend = ['sunday', 'saturday']
            if (weekend.includes(acc) && !weekend.includes(day)) {
                return ''
            }

            if (!weekend.includes(acc) && weekend.includes(day)) {
                return ''
            }

            return day
        }, null)

        shiftState.value.charge_rate_amount = util.schedule.getChargeRateAmount(
            shiftState.value.assigned_caregiver.id,
            representDayOfWeek,
            shiftState.value.charge_duration_rate_type,
            props.charge_rates
        )
    }, {immediate: true, deep: true})

    const hourlyRates = computed(() => {
        return config.value.prospect_rates.hourly.map(rate => ({
            id: rate,
            name: rate
        }))
    })

    const dailyRates = computed(() => {
        return config.value.prospect_rates.daily.map(rate => ({
            id: rate,
            name: rate
        }))
    })

    const isHourlyRateInActive = computed(() => {
        return shiftState.value.stay_type == null || shiftState.value.charge_duration_rate_type == 'daily'
    })

    const isDailyRateInActive = computed(() => {
        return shiftState.stay_type == 'live_out' || !isHourlyRateInActive.value
    })

    const weekdayComponentTitle = computed(() => {
        if (editMode.value) {
            return 'Update care need schedule:'
        }

        return 'Create care need schedule:'
    })
</script>

<template>
    <div class="w-full px-5 py-4">
        <div class="grid grid-cols-3 gap-4 mb-5">
            <div class="col-span-1 flex justify-between border-r">
                <div>
                    <AreaLabel value="Recurrence"/>
                    <div class="mb-1">
                        <RadioInput value="ongoing" label="Ongoing" v-model="shiftState.duration_type"/>
                    </div>
                    <div>
                        <RadioInput value="temp" label="Temp" v-model="shiftState.duration_type"/>
                    </div>
                </div>
                <div class="pr-10">
                    <AreaLabel value="Type"/>
                    <div class="mb-1">
                        <RadioInput value="live_in" label="Live In" v-model="shiftState.stay_type"/>
                    </div>
                    <div>
                        <RadioInput value="live_out" label="Live Out" v-model="shiftState.stay_type"/>
                    </div>
                </div>
            </div>
            <div class="col-span-1 border-r px-5">
                <AreaLabel value="Shift Length"/>
                <div class="flex gap-5">
                    <div class="">
                        <InputLabel
                            for="grid-start-time"
                            value="Start Time"
                            palette="blue"
                        >
                            <LwxSelect
                                class="mt-1"
                                id="grid-start-time"
                                v-model="shiftState.start_time"
                                :options="startTimeSelections"
                            />
                        </InputLabel>
                    </div>
                    <div class="">
                        <InputLabel
                            for="grid-length"
                            value="Length HH:MM"
                            palette="blue"
                        >
                            <LwxSelect
                                class="mt-1"
                                id="grid-length"
                                v-model="shiftState.duration"
                                :options="lengthWindows"
                            />
                        </InputLabel>
                    </div>
                </div>
            </div>
            <div class="col-span-1">
                <AreaLabel value="Date Span"/>
                <div class="flex gap-5">
                    <InputLabel
                        for="grid-start-date"
                        value="Start Date"
                        palette="blue"
                    >
                        <DatePickr :disabled="shiftState.duration_type == null" :value="shiftState.start_date" :minDate="util.date.lastSunday()" v-model="shiftState.start_date"/>
                    </InputLabel>
                    <InputLabel
                        for="grid-end-date"
                        value="End Date"
                        palette="blue"
                        :disabled="shiftState.duration_type == 'ongoing'"
                    >
                        <DatePickr :disabled="shiftState.duration_type == 'ongoing' || shiftState.duration_type == null" :value="shiftState.end_date" :minDate="shiftState.start_date" v-model="shiftState.end_date"/>
                    </InputLabel>
                </div>
            </div>
        </div>
        <div class="w-full border-t border-t-stone-300"></div>
        <div class="grid grid-cols-4 gap-4 pt-5">
            <div class="col-span-2">
                <AreaLabel value="Estimated Charge Rate or Range"/>
                <div class="flex flex-col">
                    <div class="grid grid-cols-3 gap-4 mb-3">
                        <div class="col-span-1 flex flex-col">
                            <div class="mb-1">
                                <RadioInput value="daily" :disabled="shiftState.stay_type == 'live_out' || shiftState.stay_type == null" label="Daily Rate" v-model="shiftState.charge_duration_rate_type"/>
                            </div>
                            <div>
                                <RadioInput value="hourly" :disabled="shiftState.stay_type == null" label="Hourly Rate" v-model="shiftState.charge_duration_rate_type"/>
                            </div>
                        </div>
                        <div class="col-span-2" v-if="props.client_type == 'client'">
                            <InputLabel
                                for="grid-hourly-charge-rate-amount"
                                value="Hourly"
                                class="text-xs text-stone-400"
                            >
                                <div class="flex justify-between border rounded bg-stone-100 w-full border-stone-400 p-2">
                                    <span>
                                        <span v-if="shiftState.charge_duration_rate_type == 'hourly'"></span>
                                        <span v-if="shiftState.charge_duration_rate_type == 'hourly'">{{ shiftState.charge_rate_amount }}</span>
                                    </span>
                                    <span class="text-right"><LockClosedIcon  class="flex-none inline-flex h-6 w-6 text-stone-400"/> </span>
                                </div>
                            </InputLabel>
                        </div>
                        <div class="col-span-1" v-if="props.client_type == 'prospect'">
                            <InputLabel
                                for="grid-hourly-min-charge-rate-amount"
                                value="Hourly Min"
                                class="text-xs text-stone-400"
                            >
                                <LwxSelect
                                    class="mt-1"
                                    id="grid-hourly-min-charge-rate-amount"
                                    v-model="shiftState.min_charge_rate_amount"
                                    :options="hourlyRates"
                                    :disabled="isHourlyRateInActive"
                                />
                            </InputLabel>
                        </div>
                        <div class="col-span-1" v-if="props.client_type == 'prospect'">
                            <InputLabel
                                for="grid-hourly-max-charge-rate-amount"
                                value="Hourly Max"
                                class="text-xs text-stone-400"
                            >
                                <LwxSelect
                                    class="mt-1"
                                    id="grid-hourly-max-charge-rate-amount"
                                    v-model="shiftState.max_charge_rate_amount"
                                    :options="hourlyRates"
                                    :disabled="isHourlyRateInActive"
                                />
                            </InputLabel>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 gap-4">
                        <div class="col-span-1 flex flex-col">
                            <div class="mb-1">
                                <RadioInput value="premium" :disabled="props.client_type =='client'" :label="`Premium - ${props.prospect_rate_adjustment.premium}`" v-model="shiftState.rate_modifier"/>
                            </div>
                            <div class="mb-1">
                                <RadioInput value="standard" :disabled="props.client_type =='client'" label="Standard" v-model="shiftState.rate_modifier"/>
                            </div>
                            <div>
                                <RadioInput value="discount" :disabled="props.client_type =='client'" :label="`Discount - ${props.prospect_rate_adjustment.discount}`" v-model="shiftState.rate_modifier"/>
                            </div>
                        </div>
                        <div class="col-span-2" v-if="props.client_type == 'client'">
                            <InputLabel
                                for="grid-daily-charge-rate-amount"
                                value="Daily"
                                class="text-xs text-stone-400"
                            >
                                <div class="flex justify-between border rounded bg-stone-100 w-full border-stone-400 p-2">
                                    <span>
                                        <span v-if="shiftState.charge_duration_rate_type == 'daily'"></span>
                                        <span v-if="shiftState.charge_duration_rate_type == 'daily'">{{ shiftState.charge_rate_amount }}</span>
                                    </span>
                                    <span class="text-right"><LockClosedIcon  class="flex-none inline-flex h-6 w-6 text-stone-400"/> </span>
                                </div>
                            </InputLabel>
                        </div>
                        <div class="col-span-1" v-if="props.client_type == 'prospect'">
                            <InputLabel
                                for="grid-daily-min-charge-rate-amount"
                                value="Daily Min"
                                class="text-xs text-stone-400"
                            >
                                <LwxSelect
                                    class="mt-1"
                                    id="grid-daily-min-charge-rate-amount"
                                    v-model="shiftState.min_charge_rate_amount"
                                    :options="dailyRates"
                                    :disabled="isDailyRateInActive"
                                />
                            </InputLabel>
                        </div>
                        <div class="col-span-1" v-if="props.client_type == 'prospect'">
                            <InputLabel
                                for="grid-daily-max-charge-rate-amount"
                                value="Daily Max"
                                class="text-xs text-stone-400"
                            >
                                <LwxSelect
                                    class="mt-1"
                                    id="grid-daily-max-charge-rate-amount"
                                    v-model="shiftState.max_charge_rate_amount"
                                    :options="dailyRates"
                                    :disabled="isDailyRateInActive"
                                />
                            </InputLabel>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-1">
                <WeekDaySelect :disabled="editMode" v-model="shiftState.dayOfWeeks" :title="weekdayComponentTitle"/>
            </div>
            <div class="col-span-1">
                <AreaLabel value="Caregiver"/>
                <div class="flex flex-col">
                    <CaregiverAutocomplete
                        id="grid-assigned-caregiver"
                        class="w-full py-1 text-stone-700 mt-1 mb-2"
                        v-model="shiftState.assigned_caregiver"
                    />
                    <div class="flex justify-between">
                        <button @click="reset" class="rounded h-14 mr-2 py-2 px-4 border-lifeworx-red-500 border-2">
                            <XCircleIcon class="flex-none inline-flex h-6 w-6 cursor-pointer text-lifeworx-red-500"/>
                        </button>
                        <button
                            :class="{
                                'bg-lifeworx-blue-600 hover:bg-lifeworx-blue-700' : shiftState.dayOfWeeks.length != 0,
                                'bg-slate-300': shiftState.dayOfWeeks.length == 0
                            }"
                            class="w-full right-5 p-4 border-0 h-14 rounded shadow-md  text-white text-lg font-semibold transition-all duration-300"
                            :disabled="shiftState.dayOfWeeks.length == 0"
                            @click="saveShifts"
                        >
                            {{ editMode ? 'Update Selected' : 'Add Shift' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
