<script setup>

    import { onMounted, ref } from 'vue'

    import { useCaregiverStore, useClientSingleStore, useFormStatusStore, useStaffStore, useViewStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import LatteStyledContainer from '@/Components/App/LatteStyledContainer.vue'

    import { useRoute } from 'vue-router'
    import WeekPicker from '@/Components/WeekPicker.vue'
    import LatteClientSchedule from '@/Components/Client/LatteClientSchedule.vue'
    import { util } from '@/Helpers'

    const viewStore = useViewStore()
    const clientSingleStore = useClientSingleStore()
    const caregiverStore = useCaregiverStore()
    const staffStore = useStaffStore()
    const formStatusStore = useFormStatusStore()
    const route = useRoute()

    const { client } = storeToRefs(clientSingleStore)

    const { isEmbedded } = storeToRefs(viewStore)
    const scheduleDate = ref(util.date.lastSunday())
    const scheduleBuilderMode = ref(null)

    onMounted(async () => {
        formStatusStore.addLoading('shiftForm')

        clientSingleStore.getClientByCoffeeUserProfileId(route.params.coffee_client_user_profile_id)
            .then(response => {
                rehydrate(response.data)
            })

        staffStore.getAllStaffs()
            .then(response => {
                staffStore.hydrateAllStaffs(response.data)
            })

        caregiverStore.getAllCaregivers()
            .then(response => {
                caregiverStore.hydrateAllCaregivers(response.data)
            })
    })

    async function hydrate(data) {
        await clientSingleStore.hydrate(data)
    }

    function rehydrate(data) {
        hydrate(data)
    }

</script>

<template>

    <div
        class="mx-auto max-w-7xl"
        :class="{
            'pt-0': isEmbedded,
            'sm:pt-4 md:pt-6 md:px-8': !isEmbedded
        }"
    >

        <div class="mx-auto max-w-5xl">

            <LatteStyledContainer
                id="schedule"
                title="Care Schedule"
                :collapsible="true"
                :forceSingleLineMobileLayout="true"
            >
                <template #header>
                    <div class="w-full flex justify-center items-center">
                        <WeekPicker v-model="scheduleDate" :disabled="scheduleBuilderMode == 'edit'"/>
                    </div>
                </template>
                <template #default>
                    <LatteClientSchedule
                        :client_id="Number(client.id)"
                        :start_date="scheduleDate"
                        @builder-mode:change="(mode) => scheduleBuilderMode = mode"
                    />
                </template>
            </LatteStyledContainer>

        </div>

    </div>

</template>
