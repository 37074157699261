<script setup>

    import { watch, onMounted, ref } from 'vue'

    import { Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption } from '@headlessui/vue'
    import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
    import { storeToRefs } from 'pinia'
    import { useGoogleMapsStore } from '@/Stores'

    const googleMapsStore = useGoogleMapsStore()

    const { googleMapsLoader, googleMapsApiAutocompleteSessionToken, googleMapsApiAutocompleteService, googleMapsApiPlacesService } = storeToRefs(googleMapsStore)

    const props = defineProps({
        modelValue: {
            type: String
        },
        validity: {
            type: String
        },
        placeholder: {
            type: String,
            default: 'Start typing an address...'
        },
        input_class: {
            type: String,
            default: 'border-stone-400 border-stone-400 text-gray-900 placeholder:text-stone-400 focus:ring-lifeworx-blue-600 focus:border-lifeworx-blue-600'
        }
    })

    const emit = defineEmits(['update:modelValue', 'addressFill'])

    const addressSearchInput = ref(null)

    const searchInput = ref('')
    const ignoreInputChange = ref(false)
    const searchResults = ref([])
    const selectedPlaceResult = ref()


    function displaySuggestions(predictions, status) {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            searchResults.value = []
            return;
        }
        searchResults.value = predictions
    }

    function selectPlaceCallback(place, status) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
            selectedPlaceResult.value = place
            searchInput.value = place.address_components[0].long_name + ' ' + place.address_components[1].long_name
            emit('update:modelValue', searchInput.value)
            emit('addressFill', place)
            searchResults.value = []
        }
    }

    function selectPlace(place) {

        if (!place) {
            emit('update:modelValue', searchInput.value)
            return;
        }

        ignoreInputChange.value = true

        googleMapsApiPlacesService.value.getDetails({
            placeId: place.place_id,
            fields: ['address_components', 'formatted_address', 'geometry'],
            sessionToken: googleMapsApiAutocompleteSessionToken.value
        }, selectPlaceCallback)

        ignoreInputChange.value = false
    }

    onMounted(async () => {

        const { PlacesService, AutocompleteService, AutocompleteSessionToken } = await googleMapsLoader.value.importLibrary('places')

        googleMapsApiAutocompleteSessionToken.value = new AutocompleteSessionToken()
        googleMapsApiAutocompleteService.value = new AutocompleteService()
        googleMapsApiPlacesService.value = new PlacesService(document.createElement("div"))

        watch(searchInput, (newValue) => {
            emit('update:modelValue', newValue)
            if (newValue && !ignoreInputChange.value) {
                googleMapsApiAutocompleteService.value.getPlacePredictions({
                    input: searchInput.value,
                    componentRestrictions: { country: "us" },
                    types: ['address'],
                    sessionToken: googleMapsApiAutocompleteSessionToken.value
                }, displaySuggestions)
            }
        })

    })


</script>

<template>

    <Combobox
        as="div"
        :modelValue="props.modelValue"
        @update:modelValue="selectPlace"
        nullable
    >
        <div class="relative">

            <ComboboxInput
                ref="addressSearchInput"
                class="w-full rounded-md border focus:outline-none  px-2.5 py-1.5  sm:text-sm  focus:ring-1 border-stone-400 placeholder:text-stone-400 focus:ring-lifeworx-blue-600 focus:border-lifeworx-blue-600"
                :class="props.input_class"
                :placeholder="props.placeholder"
                @change="searchInput = $event.target.value"
                autocomplete="new-password"
            />

            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </ComboboxButton>

            <ComboboxOptions
                class="absolute z-popover mt-1 max-h-60 min-w-fit w-auto overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >

                <ComboboxOption
                    v-for="(result, i) in searchResults"
                    :key="i"
                    :value="result"
                    as="template"
                    v-slot="{ active }"
                >
                    <li
                        :class="[
                            'cursor-pointer select-none rounded-md px-4 py-2',
                            active && 'bg-lifeworx-blue-600 bg-opacity-20 text-lifeworx-blue-800'
                        ]"
                    >
                        {{ result.description }}
                    </li>
                </ComboboxOption>

            </ComboboxOptions>

        </div>

    </Combobox>

</template>
