<script setup>

    import { onMounted, ref, watch } from 'vue'

    import { TrashIcon } from '@heroicons/vue/20/solid'

    import Modal from '@/Components/App/Modal.vue'
    import LatteClientContactViewList from '@/Components/Contacts/LatteClientContactViewList.vue'
    import LatteContactForm from '@/Components/Contacts/LatteContactForm.vue'
    import DangerButton from '@/Components/Forms/DangerButton.vue'
    import SecondaryButton from '@/Components/Forms/SecondaryButton.vue'

    import Loader from '@/Components/App/Loader.vue'
    import { useClientSingleStore, useFormStatusStore, useNotificationStore, useModuleLockStore } from '@/Stores'
    import { storeToRefs } from 'pinia'
    import { useJobRunner } from '@/Helpers/jobRunner'
    import { deepCopy } from '@/Helpers'
    import { finish, isFinished, isRunBefore, run } from '@/Helpers/jobState'

    const clientSingleStore = useClientSingleStore()
    const formStatusStore = useFormStatusStore()
    const { client } = storeToRefs(clientSingleStore)

    const props = defineProps({
        addNew: {
            type: Boolean,
            default: false
        },
        selectedContactId: {
            type: Number,
            default: null,
        },
        showNotification: {
            type: Boolean,
            default: true
        },
        enable: {
            type: Boolean,
            default: true
        }
    })

    let notificationStore = { add: () => {}, removeById: () => {}}
    watch(() => props.showNotification, function(newValue) {
        if (newValue == true) {
            notificationStore = useNotificationStore()
        }
    }, {immediate : true})

    const jobContactRunner = useJobRunner('contact')
    jobContactRunner.subscribe('addedRetryJob', function(errorCode, retryTime) {
        notificationStore.add('Error Saving Changes', 'error', `Retrying automatically... (${retryTime}/20)`, 15000)
        clientSingleStore.stopInactivityTimeout()
    })
    jobContactRunner.subscribe('finishedJobSuccess', function() {
        clientSingleStore.resetInactivityRefreshTimer()
    })
    jobContactRunner.subscribe('addedJob', function() {
        clientSingleStore.resetInactivityRefreshTimer()
        notificationStore.removeById(clientSingleStore.getMaxRetryDialogId())
        clientSingleStore.removeMaxRetryDialogId()
    })
    jobContactRunner.subscribe('reachedMaxRetry', function() {
        const maxRetryDialogId = notificationStore.add('Error Saving Changes', 'error', 'Latest changes not saved. Make an edit to try saving again.', 7 * 24 * 60 * 60 * 1000)
        clientSingleStore.setMaxRetryDialogId(maxRetryDialogId)
    })

    const jobClientRunner = useJobRunner('client')
    jobClientRunner.subscribe('addedRetryJob', function(errorCode, retryTime) {
        notificationStore.add('Error Saving Changes', 'error', `Retrying automatically... (${retryTime}/20)`, 15000)
        clientSingleStore.stopInactivityTimeout()
    })
    jobClientRunner.subscribe('finishedJobSuccess', function() {
        clientSingleStore.resetInactivityRefreshTimer()
    })
    jobClientRunner.subscribe('addedJob', function() {
        clientSingleStore.resetInactivityRefreshTimer()
    })

    const confirmingContactDeletion = ref(false)
    const pendingDelete = ref(false)
    const loading = ref(true)

    const defaultContact = {
        id: null,
        primary_contact: false,
        billing_contact: false,
        marketable_contact: false,
        comm_phone: false,
        comm_sms: false,
        comm_email: false,
        type: null,
        first_name: "",
        last_name: "",
        address: "",
        city: "",
        state: null,
        zip: "",
        profession: null,
        gender: "",
        company: "",
        note: "",
        emails: [],
        phones: [
            {
                number: "",
                country_code: "1",
                type: "cell"
            },
            {
                number: "",
                country_code: "1",
                type: "work"
            },
            {
                number: "",
                country_code: "1",
                type: "home"
            }
        ]
    }

    async function hydrateClient(data) {
        await clientSingleStore.hydrate(data)
        loading.value = false
    }

    async function hydrateContact(data) {
        await clientSingleStore.hydrateContact(data)
        loading.value = false
    }

    const selectedContact = ref({...defaultContact})

    function selectContact(contact_id) {
        const index = client.value.contacts.findIndex(item => item.id == contact_id);
        if (index != -1) {
            selectedContact.value = client.value.contacts[index]
        }
        emit('update:selected', contact_id)
    }

    function unMarkTypeClientContact(excludeId) {
        client.value.contacts.forEach(contact => {
            if (contact.type === 'Client' && contact.id != excludeId) {
                contact.type = null
                return;
            }
        })
    }

    function formatName(contact) {
        if (contact.first_name && contact.last_name) {
            return contact.first_name + ' ' + contact.last_name;
        } else if (contact.first_name) {
            return contact.first_name;
        } else if (contact.last_name) {
            return contact.last_name;
        } else if (contact.company) {
            return contact.company;
        } else {
            return 'Unnamed Contact ' + contact.id;
        }
    }

    function getSaveSuccessMessage(contact) {
        return 'Successfully saved contact: ' + formatName(contact);
    }

    function getDeleteSuccessMessage(contact) {
        return 'Successfully deleted contact: ' + formatName(contact);
    }

    function getSaveErrorMessage(contact) {
        return 'Error saving contact: ' + formatName(contact);
    }

    function getDeleteErrorMessage(contact) {
        return 'Error deleting contact: ' + formatName(contact);
    }

    function getModifiedFields(oldContact, newContact) {
        var modifiedFields = []
        for (var field in oldContact) {
            if (oldContact[field] != newContact[field]) {
                if (field == 'emails') {
                    let result = oldContact[field].filter(email =>!newContact[field].includes(email));
                    if (result.length > 0) {
                        modifiedFields.push(field)
                    }
                } else if (field == 'phones') {
                    let result = oldContact[field].filter(oldPhoneObject =>
                        !newContact[field].some(newPhoneObject => {
                            return oldPhoneObject.number === newPhoneObject.number &&
                                oldPhoneObject.type === newPhoneObject.type &&
                                oldPhoneObject.country_code === newPhoneObject.country_code;
                        })
                    )
                    if (result.length > 0) {
                        modifiedFields.push(field)
                    }
                } else {
                    modifiedFields.push(field)
                }
            }
        }
        return modifiedFields;
    }


    let contactFollowUpDataAfterCreate = null
    let originalContactBeforePostMessage = {}
    function addUpdateContactJob(contact, formId, runNow = false) {
        const func = runNow ? 'addAndRunJob' : 'addJob'

        jobContactRunner[func]('update', contact.id, contact, [formId], async (contact, formId, jobId) => {
                const index = client.value.contacts.findIndex(item => item.id == contact.id)
                if (!originalContactBeforePostMessage[contact.id]) {
                    originalContactBeforePostMessage[contact.id] = deepCopy(client.value.contacts[index])
                }

                const oldContactType = client.value.contacts[index].type
                client.value.contacts[index] = deepCopy(contact)
                selectedContact.value = client.value.contacts[index]

                if (contact.type !== 'Client' && oldContactType === 'Client') {
                    unMarkTypeClientContact()
                }

                if (contact.type == 'Client') {
                    unMarkTypeClientContact(contact.id)
                }

                await clientSingleStore.updateContact(client.value, contact, formId)
                    .then(function (response) {
                        if (jobContactRunner.hasScheduleJob('update', contact.id) == false) {
                            hydrateContact(response.data)
                            client.value.contacts[index]
                        }

                        formStatusStore.add('contactForm', getSaveSuccessMessage(response.data))

                        jobContactRunner.handleJobSuccess('update', contact.id, jobId)

                        const modifiedFields = getModifiedFields(originalContactBeforePostMessage[contact.id], contact)
                        window.parent.postMessage({
                            'clientUpdate': response.data.type === 'Client' || oldContactType === 'Client',
                            'modifiedFields': modifiedFields,
                        }, '*')
                        delete originalContactBeforePostMessage[contact.id]

                        notificationStore.add('Changes Saved Successfully')
                    })
                    .catch(function(error) {
                        formStatusStore.add('contactForm', getSaveErrorMessage(contact), 'error')

                        jobContactRunner.handleJobError('update', contact.id, jobId, error.error.response?.status)
                    })
            })
    }

    const moduleLockStore = useModuleLockStore()
    async function updateContact(contact, formId) {
        if (contact.id) {
            addUpdateContactJob(contact, formId)
        } else if (!client.value.id) {
            moduleLockStore.disable('topLeftModule')
            if (isRunBefore('client', 'create') == false || isFinished('client', 'create') == true) {
                jobClientRunner.addJob('create', null, contact, [formId], async (contactData, formId, jobId) => {
                    run('client', 'create')
                    const clientRequest = {
                        contacts: [contactData]
                    }
                    await clientSingleStore.storeClient(clientRequest, null)
                        .then(function (response) {
                            moduleLockStore.enable('topLeftModule')
                            let responseClient = response.data
                            hydrateClient(responseClient)

                            let responseContact = response.data.contacts[0]

                            if (selectedContact.value.id == null) {
                                selectContact(responseContact.id)
                            }

                            formStatusStore.add('contactForm', getSaveSuccessMessage(responseContact))
                            history.pushState(
                                {},
                                null,
                                `/client/${response.data.id}`
                            )

                            jobClientRunner.handleJobSuccess('create', null, jobId)
                            if (contactFollowUpDataAfterCreate) {
                                addUpdateContactJob({...contactFollowUpDataAfterCreate, id: responseContact.id}, formId, true)
                            }
                            finish('client', 'create')

                            notificationStore.add('New Client Created')
                        })
                        .catch(function(error) {
                            finish('client', 'create')
                            formStatusStore.add('contactForm', "Failed to create new client and contact", 'error')
                            if (contactFollowUpDataAfterCreate) {
                                updateContact(contactFollowUpDataAfterCreate, true)
                            }
                            jobClientRunner.handleJobError('create', null, jobId, error.error.response?.status)
                        })
                        .finally(function() {
                            contactFollowUpDataAfterCreate = null
                        })
                })
            } else {
                contactFollowUpDataAfterCreate = contact
            }
        } else {
            if (isRunBefore('contact', 'create') == false || isFinished('contact', 'create') == true) {
                jobContactRunner.addJob('create', null, contact, [formId], async (contact, formId, jobId) => {
                    run('contact', 'create')
                    await clientSingleStore.storeContact(client.value, contact, formId)
                        .then(function (response) {
                            if (response.data.type === 'Client') {
                                unMarkTypeClientContact()
                            }

                            client.value.contacts.push(response.data)
                            if (selectedContact.value.id == null) {
                                selectContact(response.data.id)
                            }

                            formStatusStore.add('contactForm', getSaveSuccessMessage(response.data))
                            window.parent.postMessage({
                                'clientUpdate': response.data.type === 'Client',
                                'modifiedFields': Object.keys(response.data),
                            }, '*')

                            jobContactRunner.handleJobSuccess('create', null, jobId)
                            finish('contact', 'create')
                            if (contactFollowUpDataAfterCreate) {
                                addUpdateContactJob({...contactFollowUpDataAfterCreate, id: response.data.id}, formId, true)
                            }
                            notificationStore.add('Changes Saved Successfully')
                        })
                        .catch(function(error) {
                            finish('contact', 'create')
                            formStatusStore.add('contactForm', "Failed to create new contact", 'error')
                            if (contactFollowUpDataAfterCreate) {
                                updateContact(contactFollowUpDataAfterCreate)
                            }
                            jobContactRunner.handleJobError('create', null, jobId, error.error.response?.status)
                        })
                        .finally(function() {
                            contactFollowUpDataAfterCreate = null
                        })
                })
            } else {
                contactFollowUpDataAfterCreate = contact
            }
        }
    }

    function deleteContact(contact) {
        pendingDelete.value = true
        clientSingleStore.deleteContact(client.value, contact)
            .then(function (response) {
                if (response.status == 204) {
                    const index = client.value.contacts.findIndex(item => item.id == contact.id)
                    formStatusStore.add('contactForm', getDeleteSuccessMessage(contact))
                    client.value.contacts.splice(index, 1)
                    selectedContact.value = client.value.contacts.length == 0 ? {...defaultContact} : client.value.contacts[0]
                    pendingDelete.value = false
                    closeModal()
                }
            }).catch(function() {
                formStatusStore.add('contactForm', getDeleteErrorMessage(contact), 'error')
                pendingDelete.value = false
            })
    }

    const confirmContactDeletion = () => {
        confirmingContactDeletion.value = true;
    }

    const closeModal = () => {
        confirmingContactDeletion.value = false;
    }

    onMounted(async () => {
        loading.value = false
    })

    watch(() => [props.addNew, loading], (value) => {
        if (props.addNew && !loading.value) {
            selectedContact.value = {...defaultContact}
            if (client.value.contacts.length == 0) {
                selectedContact.value.type = 'Client'
            }

            if (clientSingleStore.isPrimaryContactAddNewEnable()) {
                selectedContact.value.primary_contact = true
                clientSingleStore.changeAddNewPrimaryContactMode(false)
            }
        }
    }, {immediate: true, deep: true})

    watch(() => client.value, (value) => {
        if (!props.addNew) {
            selectContact(selectedContact.value.id)
        }
    })

    watch (() => props.selectedContactId, (value) => {
        if (value) {
            selectContact(value)
        }
    })

    const emit = defineEmits(['update:selected'])

</script>

<template>

    <div
        v-if="loading || props.enable == false"
        class="relative z-50 h-[46rem] -mb-[46rem] flex justify-center items-center bg-lifeworx-blue-800 bg-opacity-50"
    >
        <Loader />
    </div>

    <div class="grid grid-cols-4 md:grid-cols-16 h-[46rem]">

        <div class="col-span-4 md:col-span-5 md:overflow-scroll md:border-r-2">
            <LatteClientContactViewList
                :contacts="client.contacts"
                @update:selected="selectContact"
                :selected="selectedContact"
                :addNew="(!loading && props.addNew)"
            />
        </div>

        <div class="hidden md:block md:col-span-11">
            <LatteContactForm
                v-if="(!loading && props.addNew) || selectedContact.id"
                @update="updateContact"
                :contact="selectedContact"
            >

                <template #actions>
                    <button
                        :disabled="selectedContact.type === 'Client'"
                        v-if="client.contacts.length > 0"
                        @click.stop="confirmContactDeletion"
                        class="-mt-1 p-1 grid justify-center group/trash"
                        :class="{
                            'text-lifeworx-red-500 hover:text-lifeworx-red-800': selectedContact.type !== 'Client',
                            'text-gray-400 cursor-default': selectedContact.type === 'Client',
                        }"
                    >
                        <TrashIcon class="w-6 h-6" />
                    </button>
                </template>

            </LatteContactForm>

        </div>

    </div>

    <Modal :show="confirmingContactDeletion">
        <div class="p-6">
            <h2 class="text-lg font-medium text-gray-900 dark:text-gray-100">
                Are you sure you want to delete this contact?
            </h2>

            <div class="mt-6 flex justify-end">
                <SecondaryButton
                    :disabled="pendingDelete"
                    @click="closeModal"
                >
                    Cancel
                </SecondaryButton>

                <DangerButton
                    class="ml-3"
                    :class="{ 'opacity-50': pendingDelete }"
                    :disabled="pendingDelete"
                    @click="deleteContact(selectedContact)"
                >
                    {{ !pendingDelete ? 'Delete Contact' : 'Deleting... ' }}
                    <svg class="-mr-2 ml-2 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="pendingDelete">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>

                </DangerButton>
            </div>
        </div>
    </Modal>

</template>
