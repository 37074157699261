function length(rule) {
    const parts = rule.split(':')

    if (parts[0] !== 'len') {
        throw new Error('Wrong validator');
    }

    const length = parts[1]

    function isValid(value) {
        if (value.length > length || value.length < length) {
            return false;
        }
    }

    return { isValid };
}

function number(rule) {
    const parts = rule.split(':')

    if (parts[0] !== 'number') {
        throw new Error('Wrong validator');
    }

    function isValid(value) {
        return /^[0-9]+$/.test(value);
    }

    return { isValid };
}

function email(rule) {
    const parts = rule.split(':')

    if (parts[0] !== 'email') {
        throw new Error('Wrong validator');
    }

    function isValid(value) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    }

    return { isValid };
}

function nullable(rule) {
    if (rule !== 'nullable') {
        throw new Error('Wrong validator');
    }

    function isValid(value) {
        return true;
    }

    return { isValid };
}

export function factory(rule) {
    const parts = rule.split(':')

    switch (parts[0]) {
        case 'len':
            return length(rule);

        case 'number':
            return number(rule);

        case 'email':
            return email(rule);

        case 'nullable':
            return nullable(rule);

        case 'nullable':
            return nullable(rule)

        default:
            throw new Error('Unhandle validation');
    }
}
