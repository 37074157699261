<script setup>

    import { ref, watch } from 'vue'

    import { storeToRefs } from 'pinia'
    import { useViewStore } from '@/Stores'

    import { Switch } from '@headlessui/vue'
    import { useStorage } from '@vueuse/core'

    import { MinusIcon, ArrowPathIcon, QueueListIcon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid'

    import ProgressBarTiny from '@/Components/ProgressBarTiny.vue'
    import ProgressBar from '@/Components/ProgressBar.vue'

    const viewStore = useViewStore()

    const { isEmbedded } = storeToRefs(viewStore)

    const scopeAll = ref(false)

    const props = defineProps({
        id: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: 'Untitled'
        },
        collapsible: {
            type: Boolean
        },
        collapsed: {
            type: Boolean,
            default: false
        },
        showRefreshButton: {
            type: Boolean,
            default: false
        },
        scoped: {
            type: Boolean
        },
        forceSingleLineMobileLayout: {
            type: Boolean,
            default: false
        },
        progress: {
            type: Number
        },
        start: {
            type: Number,
        },
        end: {
            type: Number
        },
        includeMissingStartDate: {
            type: Boolean,
            default: false
        }
    })

    const collapsed =  useStorage(props.id + '-collapsed', false)

    const emit = defineEmits(['refresh'])

    watch(scopeAll, () => { refresh() });

    function refresh() {
        emit('refresh', {
            scopeAll: scopeAll.value,
            start: props.start,
            end: props.end,
            id: props.id,
            includeMissingStartDate: props.includeMissingStartDate
        })
    }

</script>

<template>

    <div
        :id="id"
        class="my-12 shadow-better md:rounded-t-lg"
        :class="{
            'rounded-b-lg': collapsed,
            'my-4': isEmbedded
        }"
    >

        <div
            class="flex md:h-12 md:items-center justify-between md:rounded-t-lg bg-lifeworx-blue-700 px-4 py-2.5 border-b border-lifeworx-blue-600 transition duration-200 z-module-container sticky top-16 md:top-0 md:relative"
            :class="{
                'md:rounded-b-lg bg-lifeworx-blue-700 bg-opacity-70': collapsed,
                'h-12': forceSingleLineMobileLayout,
                'h-20': !forceSingleLineMobileLayout,
            }"
        >

            <div class="flex items-center space-x-2 md:space-x-8">

                <div
                    class=""
                    :class="{
                        'flex w-auto': forceSingleLineMobileLayout,
                        'flex-none w-24 md:flex md:w-auto': !forceSingleLineMobileLayout,
                    }"
                >
                    <h2 class="text-white text-sm md:text-sm font-semibold drop-shadow tracking-wide select-none cursor-default">
                        {{ title }}
                    </h2>
                </div>

                <div class="my-2 hidden md:flex">
                    <ProgressBarTiny
                        v-if="progress || progress == 0"
                        :progress="progress"
                    />
                </div>

            </div>

            <div class="flex md:flex self-center">

                <div class="flex space-x-4 md:space-x-8">

                    <div
                        v-if="!collapsed && scoped"
                        class="inline-flex items-center justify-center space-x-2">

                        <div class="flex-shrink-0">
                            <h2 class="text-white text-sm md:text-xs font-semibold drop-shadow tracking-wide select-none cursor-default">
                                Mine
                            </h2>
                        </div>

                        <Switch
                            v-model="scopeAll"
                            class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-lifeworx-blue-700 focus:ring-offset-2 focus:ring-offset-lifeworx-blue-500"
                        >
                            <span class="sr-only">Scope Toggle</span>
                            <span
                                aria-hidden="true"
                                class="pointer-events-none absolute h-full w-full rounded-md bg-transparent"
                                />
                            <span
                                aria-hidden="true"
                                :class="[
                                    scopeAll ? 'bg-lifeworx-blue-500' : 'bg-gray-200',
                                    'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                                    ]"
                                />
                            <span
                                aria-hidden="true"
                                :class="[
                                    scopeAll ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                                ]"
                                />
                        </Switch>

                        <div class="flex-shrink-0">
                            <h2 class="text-white text-sm md:text-xs font-semibold drop-shadow tracking-wide select-none cursor-default">
                                All
                            </h2>
                        </div>

                    </div>

                    <button
                        v-if="showRefreshButton"
                        @click="refresh"
                        class="h-10 w-10 md:h-7 md:w-7 p-1 inline-flex items-center justify-center rounded-md text-white hover:bg-lifeworx-blue-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-lifeworx-blue-700"
                    >
                        <span class="sr-only">Collapse</span>
                        <ArrowPathIcon class="block h-5 w-5" aria-hidden="true" />
                    </button>

                    <button
                        v-if="collapsible"
                        @click="collapsed=!collapsed"
                        class="h-10 w-10 md:h-7 md:w-7 p-1 inline-flex items-center justify-center rounded-md text-white hover:bg-lifeworx-blue-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-lifeworx-blue-700  "
                    >
                        <span class="sr-only">Collapse</span>
                        <MinusIcon v-if="!collapsed" class="block h-5 w-5" aria-hidden="true" />
                        <QueueListIcon v-else class="block h-5 w-5" aria-hidden="true" />
                    </button>

                </div>

            </div>

        </div>

        <div class="bg-white" :class="{ 'hidden': collapsed }">

            <div
                v-if="progress || progress == 0"
                class="py-4 px-4 md:hidden"
            >
                <ProgressBar
                    :progress="progress"
                />
            </div>

            <slot></slot>

        </div>
    </div>



</template>

