<script setup>

    import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
    import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
    import LwxLotus from '@/Components/LwxLotus.vue'

    import { ref, computed, watch } from 'vue';
    import { useAuthStore, useStaffStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import { getModifiedProperties } from '@/Helpers'

    const staffStore = useStaffStore()
    const authStore = useAuthStore()

    const { allStaffs } = storeToRefs(staffStore)
    const { user } = storeToRefs(authStore)

    const { staff } = defineProps({
        staff: {
            type: Object,
            required: true
        },
    })

    const filteredStaffs = computed(() => {
        const staffs = allStaffs.value.filter(staffElement => staffElement.is_active || staffElement.id == staff.id || staffElement.id == user.value.user_id)
        staffs.sort((a, b) => a.first_name.localeCompare(b.first_name))
        return staffs
    })

    const selected = ref(staff)

    const emit = defineEmits(['assigned-staff:update'])

    watch(() => selected.value, (newValue, oldValue) => {
        if (oldValue !== undefined) {
            const modifiedData = getModifiedProperties(
                JSON.stringify(newValue),
                JSON.stringify(oldValue),
                [
                    'coffee_auth_user_id', 'email', 'first_name', 'id', 'is_active', 'last_name', 'phone_numbers'
                ]
            )
            if (modifiedData && Object.keys(modifiedData).length  > 0) {
                emit('assigned-staff:update', newValue);
            }
        }
    }, {immediate: true})

    watch(() => filteredStaffs.value, () => {
        if (staff.id) {
            selected.value = filteredStaffs.value.find(filteredStaff => filteredStaff.id == staff.id)
        } else {
            selected.value = filteredStaffs.value.find(filteredStaff => filteredStaff.id == user.value.user_id)
        }
    }, {immediate: true})

</script>

<template>
    <Listbox as="div" v-model="selected">
      <div class="relative mt-2">
        <ListboxButton class="relative w-full cursor-default rounded-md bg-lifeworx-alabaster-200 py-1.5 pl-3 pr-10 text-left text-lifeworx-blue-700 font-semibold shadow-sm ring-1 ring-inset ring-stone-400 focus:outline-none focus:ring-2 focus:ring-lifeworx-blue-600 sm:text-sm sm:leading-6">
          <span class="flex items-center">
            <LwxLotus class="inline-flex h-4 -mt-0.5 text-lifeworx-blue-600" />
            <span class="ml-3 block truncate">{{ selected.first_name + ' ' + selected.last_name }}</span>
          </span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ListboxOptions class="absolute z-popover mt-1 max-h-60 w-full overflow-auto rounded-md bg-lifeworx-alabaster-200 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption as="template" v-for="staff in filteredStaffs" :key="staff.id" :value="staff" v-slot="{ active, selected }">
              <li :class="[active ? 'bg-lifeworx-blue-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                <div class="flex items-center">
                  <span :class="[selected ? active ? 'font-semibold text-white' : 'font-semibold text-lifeworx-blue-600' : 'font-normal', 'ml-3 block truncate']">
                    {{ staff.first_name + ' ' + staff.last_name }}
                  </span>
                </div>

                <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4">
                  <CheckIcon
                    :class="[active ? 'text-white' : 'text-lifeworx-blue-600', 'h-5 w-5']"
                    aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </template>
