<script setup>

    import { useRoute, useRouter } from 'vue-router'

    import { useAuthStore, useConfigStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
    import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'

    import LwxLogo from '@/Components/LwxLogo.vue'
    import LwxLotus from '@/Components/LwxLotus.vue'
    import NavLink from '@/Components/Site/NavLink.vue'

    import { ref, computed, onMounted } from 'vue'

    const router = useRouter()
    const route = useRoute()

    const authStore = useAuthStore()
    const configStore = useConfigStore()

    const { config } = storeToRefs(configStore)

    const profilesEnabled = false

    const props = defineProps({
        links: {
            type: Array,
            default: [
                {
                    label: 'Back to Coffee',
                    url: import.meta.env.VITE_COFFEE_BASEURL
                },
                {
                    label: 'Feedback',
                    route: 'feedback',
                    guarded: true
                },
                {
                    label: 'COI',
                    url: import.meta.env.VITE_COFFEE_BASEURL + '/coi',
                    guarded: true
                },
            ]
        },
        user: {
            type: Object
        }
    })

    const user = computed(() => props.user)
    const userProfileImageBase = import.meta.env.VITE_STORAGE_PATH + '/assets/staff-photos/'
    const navBarEnvColor =  import.meta.env.VITE_NAVBAR_ENV === "true"

    function redirect(path) {
        window.location.href = path
    }

</script>

<template>

    <Disclosure
        as="nav"
        class="fixed w-full z-global-nav h-16 bg-lifeworx-blue shadow-md"
        v-slot="{ open }"
        :class="
            { 'bg-purple-500': ['development', 'local'].includes(config?.env) && navBarEnvColor == true },
            { 'bg-lifeworx-orange': config?.env == 'staging' && navBarEnvColor == true }
        "
    >
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 serif">
            <div class="flex h-16 justify-between">
                <div class="flex">
                    <div class="flex flex-shrink-0 items-center">
                        <router-link to="/">
                            <LwxLogo
                                class="block h-9 w-48 mt-1 fill-current text-white dark:text-gray-200"
                            />
                        </router-link>
                    </div>
                    <div class="hidden sm:ml-6 sm:flex sm:space-x-8">

                        <template v-for="link in props.links">
                            <a
                                v-if="link.url && props.user || !link.guarded"
                                class="flex"
                                :href="link.url"
                                target="_blank"
                            >
                                <NavLink
                                    :active="route.name == link.route"
                                >
                                    {{ link.label }}
                                </NavLink>
                            </a>
                            <NavLink
                                    v-else-if="!link.url && props.user || !link.guarded"
                                    @click="!link.url ? router.push({ name: link.route }) : redirect(link.url)"
                                    :active="route.name == link.route"
                                >
                                    {{ link.label }}
                            </NavLink>
                        </template>

                    </div>
                </div>
                <div v-if="props.user" class="hidden sm:ml-6 sm:flex sm:items-center">

                    <!-- Profile dropdown -->
                    <Menu as="div" class="relative ml-3">
                        <div>
                            <MenuButton class="flex justify-center items-center">
                                <h4 class="tracking-wide text-white font-semibold text-sm pr-3 hidden">
                                    <LwxLotus class="inline-flex h-4 w-4 -mt-0.5" />
                                    {{ user.first_name + ' ' + user.last_name }}
                                </h4>
                                <div class="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 border-2 border-white">
                                    <span class="absolute -inset-1.5" />
                                    <span class="sr-only">Open user menu</span>
                                    <img class="h-10 w-10 rounded-full" :src="userProfileImageBase + user.coffee_auth_user_id + '.jpg'" alt="" />
                                </div>
                            </MenuButton>
                        </div>
                        <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                            <MenuItems class="absolute right-0 z-popover mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <MenuItem
                                    v-if="profilesEnabled"
                                    v-slot="{ active }"
                                >
                                    <button
                                        @click="router.push('/profile')"
                                        :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                                    >
                                        Your Profile
                                    </button>
                                </MenuItem>
                                <MenuItem v-slot="{ active }">
                                    <button
                                        @click="authStore.logout()"
                                        :class="[active ? 'bg-gray-100' : '', 'w-full text-left block px-4 py-2 text-sm text-gray-700']"
                                    >
                                        Log out
                                    </button>
                                </MenuItem>
                            </MenuItems>
                        </transition>
                    </Menu>

                </div>
                <div class="-mr-2 flex items-center sm:hidden">

                    <!-- Mobile menu button -->
                    <DisclosureButton class="relative inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span class="absolute -inset-0.5" />
                        <span class="sr-only">Open main menu</span>
                        <Bars3Icon v-if="!open" class="block h-8 w-8" aria-hidden="true" />
                        <XMarkIcon v-else class="block h-8 w-8" aria-hidden="true" />
                    </DisclosureButton>

                </div>
            </div>
        </div>

        <DisclosurePanel class="sm:hidden bg-white h-screen">

            <div class="space-y-1 pb-3 pt-2">

                <template v-for="link in props.links">
                    <template v-if="!link.url">
                        <DisclosureButton
                            v-if="props.user || !link.guarded"
                            as="a"
                            @click="router.push({ name: link.route })"
                            class="block w-full border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"
                            :class="{ 'bg-indigo-50 border-indigo-500 text-indigo-700': link.route && route.fullPath == link.route }"
                        >
                            {{ link.label }}
                        </DisclosureButton>
                    </template>
                    <template v-if="link.url">
                        <DisclosureButton
                            v-if="props.user || !link.guarded"
                            as="a"
                            :href="link.url"
                            class="block w-full border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"
                            :class="{ 'bg-indigo-50 border-indigo-500 text-indigo-700': link.route && route.fullPath == link.route }"
                        >
                            {{ link.label }}
                        </DisclosureButton>
                    </template>
                </template>

            </div>

            <div v-if="props.user"  class="border-t border-gray-200 pb-3 pt-4">
                <div class="flex items-center px-4">
                    <div class="flex-shrink-0">
                        <img
                            class="h-10 w-10 rounded-full"
                            :src="userProfileImageBase + user.coffee_auth_user_id + '.jpg'"
                            :alt=" user.first_name + ' ' + user.last_name"
                        />
                    </div>
                    <div class="ml-3">
                        <div class="text-base font-medium text-gray-800">
                            {{ user.first_name + ' ' + user.last_name }}
                        </div>
                        <div class="text-sm font-medium text-gray-500">
                            {{ user.email }}
                        </div>
                    </div>
                </div>
                <div class="mt-3 space-y-1">
                    <DisclosureButton
                        v-if="profilesEnabled"
                        as="a"
                        href="#"
                        class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                    >
                        Your Profile
                    </DisclosureButton>
                    <button
                        @click="authStore.logout()"
                        class="block w-full text-left px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                    >
                        Log out
                    </button>
                </div>
            </div>

        </DisclosurePanel>

    </Disclosure>

</template>
