<script setup>

    import Dropdown from '@/Components/App/Dropdown.vue'
    import Loader from '@/Components/App/Loader.vue'
    import StaffDropdown from '@/Components/Forms/StaffDropdown.vue'
    import { useClientSingleStore, useConfigStore, useStaffStore, useNotificationStore } from '@/Stores'
    import { storeToRefs } from 'pinia'
    import { computed, ref, watch, onMounted } from 'vue'
    import { util, getModifiedProperties, deepCopy } from '@/Helpers'
    import { useStorage } from '@vueuse/core'

    import { EnvelopeIcon, PhoneArrowUpRightIcon, UserIcon, UserPlusIcon, ClipboardDocumentListIcon, MapIcon } from '@heroicons/vue/24/solid'
    import LwxCoffee from '@/Components/LwxCoffee.vue'
    import LwxSelect from '@/Components/Forms/LwxSelect.vue'
    import Address from '@/Components/Forms/Address.vue'
    import NameInput from '@/Components/Forms/NameInput.vue'
    import InputLabel from '@/Components/Forms/InputLabel.vue'
    import TextInput from '@/Components/Forms/TextInput.vue'

    const clientCoffeeBaseUrl = import.meta.env.VITE_COFFEE_BASEURL + '/staff/sales-manager/information/pid/'
    const clientSingleStore = useClientSingleStore()
    const configStore = useConfigStore()
    const { config } = storeToRefs(configStore)
    const staffStore = useStaffStore()
    const notificationStore = useNotificationStore()

    const props = defineProps({
        enable: {
            type: Boolean,
            default: true
        }
    })

    const { client, isEdit } = storeToRefs(clientSingleStore)

    const emit = defineEmits(['client:store', 'client:update', 'client:cancel-update', 'contact:selected', 'contact:addNewPrimary'])

    const localClient = ref(clientSingleStore.defaultClient())

    const clientPropsChange = ref(false)
    const loading = ref(true)

    const types = ref([
        {
            id: null,
            name: ""
        }
    ])

    watch(config, (value) => {
        types.value = value.contact.types.map(item => ({
            id: item,
            name: item
        }))
        .filter(item => !(item.id === 'Client' && item.name === 'Client'));
    }, { deep: true, immediate : true })
    const hasClientData = computed(() => {
        const hasData = client.value && client.value.id
        return hasData
    })

    const clientName = computed(() => {
        if (client.value.first_name && client.value.last_name) {
            return client.value.first_name + ' ' + client.value.last_name
        }

        return client.value.first_name || client.value.last_name || '(Unnamed Client)';
    })

    const clientAddress = computed(() => {
        const address = client.value.address?.address ?? '';
        const address_2 = client.value.address?.address_2 ?? '';
        const city = client.value.address?.city ?? '';
        const state = client.value.address?.state ?? '';
        const zip = client.value.address?.zip ?? '';

        const addressParts = [address, address_2, city, state].filter(part => part !== '');
        const addressComplete = `${addressParts.join(', ')} ${zip}`.trim();
        return addressComplete.length > 1 ? addressComplete : "(no address)";
    })

    const phoneLinks = computed(() => {
        const links = []
        client.value.phone_numbers?.forEach(phone => {
            if (!phone.number) { return; }
            links.push({
                label: 'Call +' + phone.country_code + '-' + util.addDashesToPhone(phone.number) + ' (' + phone.type + ')',
                href: 'tel:+' + phone.country_code + phone.number
            })
        })
        return links;
    })

    const emailLinks = computed(() => {
        const links = []
        client.value.emails?.forEach(email => {
            if (!email || email == '') { return; }
            links.push({
                label: 'Email ' + email,
                href: 'mailto:' + email
            })
        })
        return links;
    })

    const basicClientInfo = computed(() => {
        let info =  'Client: ' + clientName.value + ' Address: ' + clientAddress.value + ' Phone: '
        const cell = client.value.phone_numbers?.find(phone => phone.type === 'cell')
        if (cell?.number) {
            info += util.addDashesToPhone(cell.number)
        } else {
            const home = client.value.phone_numbers?.find(phone => phone.type === 'home')
            if (home?.number) {
                info += util.addDashesToPhone(home.number)
            }
        }
        return info
    })

    const primaryContactIndexes = computed(() => {
        const contactIndex = []

        localClient.value.contacts.forEach((contact, index) => {
            if (contact.primary_contact) {
                contactIndex.push(index)
            }
        })
        return contactIndex;
    })

    watch(() => client.value, (value) => {
        if (hasClientData) {
            clientPropsChange.value = true
            const oldContacts = deepCopy(localClient.value.contacts)
            localClient.value = deepCopy(value)
            localClient.value.contacts = combineContacts(oldContacts, value.contacts)
        }
    }, {immediate: true, deep: true})

    function combineContacts(oldContacts, newContacts) {
        const result = []
        const changedContacts = deepCopy(newContacts)
        for (const i in oldContacts) {
            if (!oldContacts[i].id) {
                if (util.isEmpty(oldContacts[i].first_name) && util.isEmpty(oldContacts[i].last_name) && util.isEmpty(oldContacts[i].type)) {
                    result.push(deepCopy(oldContacts[i]))
                } else {
                    const index = changedContacts.findIndex(item => item.uuid == oldContacts[i].uuid)
                    if (index != -1) {
                        result.push(transformContactSelects(deepCopy(changedContacts[index])))
                    }
                }
            } else {
                const index = changedContacts.findIndex(item => item.uuid == oldContacts[i].uuid)
                if (index != -1) {
                    result.push(transformContactSelects(deepCopy(changedContacts[index])))
                    changedContacts.splice(index, 1)
                }
            }
        }

        for (const i in changedContacts) {
            const index = result.findIndex(item => item.uuid == changedContacts[i].uuid)
            if (index == -1) {
                result.push(deepCopy(changedContacts[i]))
            }
        }
        return result
    }

    const handleStoreChange = (data) => {
        if (Object.keys(data).length > 0) {
            emit('client:store', data, 'profile_client_intro');
        }
    }

    const handleUpdateChange = (data) => {
        if (Object.keys(data).length > 0) {
            emit('client:update', data, 'profile_client_intro');
        } else {
            emit('client:cancel-update', client.value.id);
        }
    }

    watch(localClient, (newValue, oldValue) => {
        if (oldValue === undefined) {
            clientPropsChange.value = false;
            return;
        }
        if (newValue.id && oldValue.id === newValue.id) {

            if(clientPropsChange.value == false) {
                const diff = getModifiedProperties(
                    JSON.stringify(clientSingleStore.extractClient(newValue)),
                    JSON.stringify(client.value),
                    [
                        'first_name', 'last_name',
                        {
                            key: 'address',
                            diff: (newObj, oldObj) => {
                                return (newObj.address != oldObj.address ||
                                    newObj.address_2 != oldObj.address_2 ||
                                    newObj.city != oldObj.city ||
                                    newObj.state != oldObj.state ||
                                    newObj.zip != oldObj.zip)
                                ? newObj : null
                            }
                        },
                        {
                            key: 'assigned_sales_manager',
                            diff: (newObj, oldObj) => {
                                return newObj.id != oldObj.id ? newObj : null
                            }
                        },
                        {
                            key: 'contacts',
                            diff: (newArr, oldArr) => {
                                const oldArrDict = {}
                                const diff = []
                                oldArr.forEach(contact => oldArrDict[contact.id] = contact)

                                for (const i in newArr) {
                                    if (!newArr[i].id) {
                                        if (!util.isEmpty(newArr[i].first_name) || !util.isEmpty(newArr[i].last_name || !util.isEmpty(newArr[i].type))) {
                                            diff.push(newArr[i])
                                        }
                                    } else {
                                        if (newArr[i].first_name != oldArrDict[newArr[i].id].first_name ||
                                            newArr[i].last_name != oldArrDict[newArr[i].id].last_name ||
                                            newArr[i].type != oldArrDict[newArr[i].id].type) {
                                            diff.push(newArr[i])
                                        }
                                    }
                                }
                                return diff.length ? diff : null
                            }
                        }
                    ]
                )
                handleUpdateChange(diff)
                return;
            }
        } else if (clientPropsChange.value == false) {
            const diff = getModifiedProperties(
                JSON.stringify(clientSingleStore.extractClient(newValue)),
                JSON.stringify(client.value),
                [
                    'first_name', 'last_name', {
                        key: 'address',
                        diff: (newObj, oldObj) => {
                            return (newObj.address != oldObj?.address ||
                                newObj.address_2 != oldObj?.address_2 ||
                                newObj.city != oldObj?.city ||
                                newObj.state != oldObj?.state ||
                                newObj.zip != oldObj?.zip)
                            ? newObj : null
                        }
                    },
                    {
                        key: 'contacts',
                        diff: (newArr, oldArr) => {
                            if (newArr.length == 0) {
                                return null
                            }

                            const diff = []
                            for (const i in newArr) {
                                if (!util.isEmpty(newArr[i].first_name) || !util.isEmpty(newArr[i].last_name || !util.isEmpty(newArr[i].type))) {
                                    diff.push(newArr[i])
                                }
                            }

                            return diff.length ? diff : null
                        }
                    }
                ]
            )
            if (Object.keys(diff).length > 0) {
                diff['assigned_sales_manager'] = localClient.value.assigned_sales_manager
            }
            handleStoreChange(diff)
        }
        clientPropsChange.value = false
    }, { immediate: true, deep: true })

    function handleAssignedStaffChange(staff) {
        localClient.value.assigned_sales_manager = staff
    }

    function handleAddressUpdate(newAddress) {
        localClient.value.address = newAddress;
    }

    function selectPrimaryContact(contact) {
        emit('contact:selected', contact.id);
        util.scrollToElement('contact')
    }

    const contactsCollapsed = useStorage('contact-collapsed', false)

    function selectAddNewContact() {
        emit('contact:addNewPrimary');
        contactsCollapsed.value = false
        util.scrollToElement('contact')
    }

    function toggleEdit() {
        isEdit.value = !isEdit.value
    }

    function pushNewPrimaryContact() {
        localClient.value.contacts.push({
            first_name: null,
            last_name: null,
            primary_contact: true,
            type: null,
            uuid: crypto.randomUUID(),
        })
    }

    onMounted(async () => {
        staffStore.getAllStaffs()
            .then(response => {
                    rehydrateStaffs(response.data)
                })
    })

    async function hydrateStaffs(data) {
        await staffStore.hydrateAllStaffs(data)
        loading.value = false
    }

    function rehydrateStaffs(data) {
        loading.value = true
        hydrateStaffs(data)
    }

    async function saveToClipboard(text) {
        if (document.hasFocus()) {
            await navigator.clipboard.writeText(text)
        }
    }

    function transformContactSelects(proxyContact) {
        return {
            ...proxyContact,
            type: getOptionName(proxyContact.type, types.value) ? {id: proxyContact.type, name: getOptionName(proxyContact.type, types.value)} : proxyContact.type,
        }
    }

    function getOptionName(id, array) {
        const index = array.findIndex(item => item.id == id);
        return index == -1 ? undefined :  array[index].name;
    }

</script>

<template>
    <div class="sm:col-span-1 pt-4 pb-8 md:py-0 md:col-span-7 md:pr-6 md:border-r md:border-r-stone-500 md:border-opacity-20" v-if="!props.enable">
        <Loader />
    </div>

    <div v-else class="sm:col-span-1 pt-4 pb-8 md:py-0 md:col-span-7 md:pr-6 md:border-r md:border-r-stone-500 md:border-opacity-20">

        <template
            v-if="(hasClientData || isEdit) && !loading"
        >

            <div class="mt-4 w-full md:w-1/2 pb-2 md:pr-1">
                <StaffDropdown
                    :staff="client.assigned_sales_manager"
                    @assigned-staff:update="handleAssignedStaffChange"
                />
            </div>

            <div v-if="!isEdit">

                <h1 class="font-serif text-4xl font-medium py-2 text-lifeworx-blue-800">
                    {{ clientName }}
                </h1>

                <div
                    v-if="primaryContactIndexes.length"
                    class="pt-1 pb-1"
                >
                    <UserIcon
                        class="inline-flex h-5 w-5 mr-2 text-lifeworx-blue-800 hover:text-lifeworx-blue-700 cursor-pointer"
                    />
                    <template
                        v-for="(index, i) in primaryContactIndexes" :key="index"
                    >
                        <span
                            class="cursor-pointer font-bold text-sm text-lifeworx-blue-800"
                            @click="selectPrimaryContact(localClient.contacts[index])"
                        >
                            <template v-if="localClient.contacts[index].first_name">{{ localClient.contacts[index].first_name }}</template>
                            <template v-if="localClient.contacts[index].first_name && localClient.contacts[index].last_name">&nbsp;</template>
                            <template v-if="localClient.contacts[index].last_name">{{ localClient.contacts[index].last_name }}</template>
                            <template v-if="typeof localClient.contacts[index].type == 'string'">&nbsp;&lpar;{{ localClient.contacts[index].type }}&rpar;</template>
                            <template v-else-if="localClient.contacts[index].type">&nbsp;&lpar;{{ getOptionName(localClient.contacts[index].type.id, types) }}&rpar;</template>
                        </span>
                        <template v-if="i < primaryContactIndexes.length - 1">, </template>
                    </template>
                </div>

                <div
                    class="pt-1 pb-5"
                >
                    <span
                        class="cursor-pointer group font-bold underline text-sm text-lifeworx-blue-600 hover:text-lifeworx-blue-700"
                        @click="selectAddNewContact"
                    >
                        <UserPlusIcon
                            class="inline-flex h-5 w-5 mr-2 text-lifeworx-blue-600 cursor-pointer group-hover:text-lifeworx-blue-700"
                        />{{ 'Add Primary Contact' }}
                    </span>
                </div>

                <div
                    v-if="client.address"
                    class="font-bold text-sm text-lifeworx-blue-800 flex items-center"
                >



                    <MapIcon
                        class="pr-2 text-right h-6 w-6 text-xs select-none"
                        :class="clientAddress !== '(no address)' ? 'text-lifeworx-blue-600' : 'text-lifeworx-red-500'"
                    />

                    <VTooltip
                        v-if="clientAddress !== '(no address)'"
                        :triggers="['hover']"
                        :autoHide="true"
                    >
                        <a
                            :href="'https://maps.google.com/?q=' + encodeURIComponent(clientAddress)"
                            target="_blank"
                            class="text-lifeworx-blue-800 hover:text-lifeworx-blue-600"
                        >{{ clientAddress }}</a>

                        <template #popper>
                            <span class="text-sm">
                                Open Google Maps
                            </span>
                        </template>

                    </VTooltip>

                    <a
                        v-else
                        class="text-lifeworx-red-500 cursor-pointer hover:text-lifeworx-red-600"
                        @click="util.scrollToElement('contact')"
                    >Missing Client Address</a>
                </div>
            </div>

            <div v-else>

                <div class="flex flex-wrap -mx-3 mb-3">

                    <div class="md:w-1/2 pl-3 pr-1 mb-4 md:mb-0">
                        <NameInput
                            type="text"
                            v-model="localClient.first_name"
                            label="First Name"
                        >
                        </NameInput>
                    </div>

                    <div class="md:w-1/2 pl-1 pr-3 mb-4 md:mb-0">
                        <NameInput
                            type="text"
                            v-model="localClient.last_name"
                            label="Last Name"
                        >
                        </NameInput>
                    </div>

                </div>

                <div
                    v-if="primaryContactIndexes.length"
                    class="pt-1 pb-1"
                >
                    <template
                        v-for="(index, i) in primaryContactIndexes" :key="index"
                    >
                        <div class="flex flex-wrap items-end pl-3 -mx-3 mb-3">

                            <UserPlusIcon
                                class="flex-none inline-flex h-5 mb-2 w-5 text-lifeworx-blue-800"
                            />

                            <div class="md:w-44 pl-3 pr-1 mb-4 md:mb-0">
                                <InputLabel
                                    :for="`primary-contact-first-name-${index}`"
                                    value="First Name"
                                    class="text-xs text-stone-400"
                                >
                                    <TextInput
                                        class="w-full px-2.5 py-1.5 text-sm font-semibold bg-lifeworx-alabaster-200 text-lifeworx-blue-800"
                                        type="text"
                                        :id="`primary-contact-first-name-${index}`"
                                        v-model="localClient.contacts[index]['first_name']"
                                    />
                                </InputLabel>
                            </div>
                            <div class="md:w-44 pl-2 pr-1 mb-4 md:mb-0">
                                <InputLabel
                                    :for="`primary-contact-last-name-${index}`"
                                    value="Last Name"
                                    class="text-xs text-stone-400"
                                >
                                    <TextInput
                                        class="w-full px-2.5 py-1.5 text-sm font-semibold bg-lifeworx-alabaster-200 text-lifeworx-blue-800"
                                        type="text"
                                        :id="`primary-contact-first-name-${index}`"
                                        v-model="localClient.contacts[index]['last_name']"
                                    />
                                </InputLabel>
                            </div>
                            <div class="md:w-44 pl-2 pr-1 mb-4 md:mb-0">
                                <InputLabel
                                    :for="`primary-contact-type-${index}`"
                                    value="Type"
                                    class="text-xs text-stone-400"
                                >
                                    <LwxSelect
                                        input_class="w-full px-2.5 py-1.5 text-sm font-semibold bg-lifeworx-alabaster-200 text-lifeworx-blue-800"
                                        :id="`primary-contact-type-${index}`"
                                        v-model="localClient.contacts[index]['type']"
                                        :options="types"
                                    />
                                </InputLabel>
                            </div>
                        </div>
                    </template>
                </div>

                <div
                    class="pt-1 pb-5"
                >
                    <span
                        class="cursor-pointer font-bold underline text-sm text-lifeworx-blue-600 group hover:text-lifeworx-blue-700"
                        @click="pushNewPrimaryContact"
                    >
                        <UserPlusIcon
                            class="inline-flex h-5 w-5 mr-2 text-lifeworx-blue-600 cursor-pointer group-hover:text-lifeworx-blue-700"
                        />{{ 'Add Primary Contact' }}
                    </span>
                </div>

                <div>
                    <Address
                        :address="localClient.address"
                        @update:address="handleAddressUpdate"
                    >
                    </Address>
                </div>
            </div>

            <div class="flex items-center mb-4 md:mb-0">
                <div class="grid grid-cols-3">

                    <div class="mt-2">
                        <Dropdown
                            v-if="clientSingleStore.clientHasPhoneNumber(client)"
                            :links="phoneLinks"
                            direction="right"
                        >
                            <template #button>
                                <VTooltip
                                    :triggers="['hover']"
                                    :autoHide="true"
                                >
                                    <PhoneArrowUpRightIcon
                                        class="inline-flex h-6 w-6 text-lifeworx-blue-600 hover:text-lifeworx-blue-500 cursor-pointer"
                                    />
                                    <template #popper>
                                        <span class="text-sm">
                                            Phone Numbers
                                        </span>
                                    </template>
                                </VTooltip>
                            </template>
                        </Dropdown>

                        <PhoneArrowUpRightIcon
                            v-else
                            class="inline-flex m-2 h-6 w-6 text-zinc-300"
                        />
                    </div>

                    <div class="mt-2">
                        <Dropdown
                            v-if="client?.emails && client.emails.length > 0"
                            :links="emailLinks"
                            direction="right"
                        >
                            <template #button>
                                <VTooltip
                                    :triggers="['hover']"
                                    :autoHide="true"
                                >
                                    <EnvelopeIcon
                                        class="inline-flex h-6 w-6 text-lifeworx-blue-600 hover:text-lifeworx-blue-500 cursor-pointer"
                                    />
                                    <template #popper>
                                        <span class="text-sm">
                                            Email Addresses
                                        </span>
                                    </template>
                                </VTooltip>
                            </template>
                        </Dropdown>

                        <EnvelopeIcon
                            v-else
                            class="inline-flex m-2 h-6 w-6 text-zinc-300"
                        />
                    </div>

                    <div class="mt-2">
                        <VTooltip
                            :triggers="['hover']"
                            :autoHide="true"
                        >
                            <ClipboardDocumentListIcon
                                v-if="client.id"
                                class="inline-flex m-2 h-6 w-6 text-lifeworx-blue-600 hover:text-lifeworx-blue-500 cursor-pointer"
                                @click="saveToClipboard(basicClientInfo).then(() => { notificationStore.add('Client Info Copied To Clipboard') })"
                            />

                            <ClipboardDocumentListIcon
                                v-else
                                class="inline-flex m-2 h-6 w-6 text-zinc-300"
                            />

                            <template #popper>
                                <span class="text-sm">
                                    Copy Client Info
                                </span>
                            </template>

                        </VTooltip>
                    </div>

                </div>

                <div class="flex items-center mx-4 mt-2">
                    <div class="h-6 w-0.5 bg-neutral-300"></div>
                </div>

                <div class="flex items-center text-sm font-bold text-lifeworx-blue-600 mt-2">

                    <VTooltip
                        :triggers="['hover']"
                        :autoHide="true"
                        v-if="client.coffee_user_profile_id"
                    >

                        <a
                            :href="clientCoffeeBaseUrl + client.coffee_user_profile_id"
                            target="_blank"
                            class="hover:text-lifeworx-blue-500 hover:underline group text-center"
                        >
                            <LwxCoffee
                                class="inline-flex -mt-1 mr-2 h-7 w-7"
                            />
                            <span class="group-hover:underline">Coffee ID {{ client.coffee_user_profile_id }}</span>
                        </a>

                        <template #popper>
                            <span class="text-sm">
                                Open Coffee Profile
                            </span>
                        </template>

                    </VTooltip>

                    <span
                        v-else
                    >
                        <LwxCoffee
                            class="inline-flex -mt-1 mr-2 h-7 w-7 text-zinc-300"
                        />
                        New Prospect/Client
                    </span>
                </div>
            </div>

        </template>

        <Loader v-else />

    </div>

</template>
