<script setup>
    import Modal from '@/Components/App/Modal.vue'
    import flatpickr from 'flatpickr';
    import 'flatpickr/dist/flatpickr.css';
    import PrimaryButton from '@/Components/Forms/PrimaryButton.vue';
    import SecondaryButton from '@/Components/Forms/SecondaryButton.vue';
    import DatePickr from '@/Components/Forms/DatePickr.vue';
    import { ref, watch } from 'vue';
    import InputLabel from '@/Components/Forms/InputLabel.vue';

    const emit = defineEmits(['range:save', 'range:cancel'])

    const start_date = ref(null)
    const end_date = ref(null)

    const props = defineProps({
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        notice: {
            type: String,
            default: ''
        },
        start_date: {
            type: [String, null],
            required: true
        },
        button_text: {
            type: String,
            default: ''
        },
        button_style: {
            type: String,
            default: undefined
        }
    })

    function handleConfirm() {
        emit('range:save', start_date.value, end_date.value)
    }

    watch(() => props.start_date, (newValue) => {
        start_date.value = newValue
    }, {immediate : true})
</script>

<template>

    <Modal :show="props.start_date != null">
        <div v-if="props.description != ''" v-html="props.description">
        </div>

        <div>
            <h2 v-if="props.title != ''" class="text-center mb-2 font-semibold text-xl">{{ props.title }}</h2>
            <div class="flex justify-between mb-5">
                <div>
                    <div>
                        <InputLabel
                            for="grid-start-date"
                            value="Start"
                            palette="blue"
                        >
                            <DatePickr :value="start_date" :min-date="props.start_date" v-model="start_date"/>
                        </InputLabel>
                    </div>
                </div>
                <div>
                    <div>
                        <InputLabel
                            for="grid-end-date"
                            value="Until"
                            palette="blue"
                        >
                            <DatePickr :value="end_date" :min-date="start_date" v-model="end_date"/>
                        </InputLabel>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="props.notice != ''">
            <span class="font-bold">NB: </span>{{ props.notice }}
        </div>

        <div class="mt-6 flex justify-end border-t pt-5">
            <SecondaryButton
                @click="() => emit('range:cancel')"
            >
                Cancel
            </SecondaryButton>

            <PrimaryButton
                class="ml-3"
                :button_style="button_style"
                @click="handleConfirm"
            >
                {{ button_text }}
            </PrimaryButton>
        </div>
    </Modal>

</template>
