<script setup>

    import { ref, watch, computed, nextTick } from 'vue'
    import parsePhoneNumber from 'libphonenumber-js'

    const props = defineProps({
        phone: {
            type: Object,
            default: {
                number: "",
                country_code: 1
            }
        },
        label: {
            type: String,
            default: ""
        },
        context: {
            type: [String, Number, null],
            default: null
         }
    })

    const formattedNumber = ref(null)
    const isValid = ref(false)
    const cursorPosition = ref(null)
    const inputRef = ref(null)
    const pastedData = ref(null)
    const countryCode = ref(1)
    let oldFormattedNumber = formatPhoneNumber(props.phone.number)

    const unformattedNumber = computed(() => formattedNumber.value ? formattedNumber.value.replace(/\D/g, '') : null)

    watch(() => props.context, (newValue, oldValue) => {
        if (newValue != oldValue) {
            formattedNumber.value = formatPhoneNumber(props.phone.number)
            oldFormattedNumber = formatPhoneNumber(props.phone.number)
        }
    })

    watch(() => props.phone.number, (newValue, oldValue) => {
        if (newValue !== oldValue) {
            formattedNumber.value = formatPhoneNumber(newValue)
        }
        isValid.value = isPhoneValid(countryCode.value, newValue)
    }, {immediate: true})

    watch(countryCode, (newValue, oldValue) => {
        isValid.value = isPhoneValid(newValue, unformattedNumber.value)
    })

    watch(formattedNumber, (newValue, oldValue) => {
        if (newValue == null) {
            formattedNumber.value = ''
            return
        }

        if (newValue && !countryCode.value) {
            countryCode.value = '1'
        }

        if (newValue && newValue.length <= 12) {
            oldFormattedNumber = newValue
        }

        if (newValue == pastedData.value) {
            formattedNumber.value = formatPhoneNumber(newValue.replace(/\D/g, ''))
        }
        else if (newValue && newValue.length > 12) {
            formattedNumber.value = oldFormattedNumber;
        } else {
            formattedNumber.value = formatPhoneNumber(newValue ? newValue.replace(/\D/g, '') : newValue)
        }
        if (newValue == '' && (oldValue == null || oldValue == '')) {
            return;
        }

        const noFormatPhoneNumber = newValue ? newValue.replace(/\D/g, '') : null
        isValid.value = isPhoneValid(countryCode.value, noFormatPhoneNumber)
        if (newValue != null && newValue !== oldValue && props.phone.number != noFormatPhoneNumber && isValid.value) {
            if (newValue.length <= 12) {
                props.phone.number = newValue.replace(/\D/g, '')
            }
        }
    })

    function formatPhoneNumber(value) {
        if (!value) return value;
        if (value.length <= 3) {
            return value;
        } else if (value.length <= 6) {
            return `${value.slice(0, 3)}-${value.slice(3)}`;
        } else {
            return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
        }
    }

    function isPhoneValid(country_code, number) {
        if (!number || number.trim() == "") {
            return true;
        }

        const phoneNumber = parsePhoneNumber(`+${country_code}${number}`)
        if (!phoneNumber) {
            return false;
        }
        return phoneNumber.isValid();
    }

    function handleKeyDownCursor(e) {
        if ((e.getModifierState("Meta") && ['a'].indexOf(e.key) != -1)) {
            cursorPosition.value = 0
            return;
        }
    }

    function handleKeyUpCursor(e) {
        if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Shift', 'Meta'].indexOf(e.key) != -1) {
            cursorPosition.value = e.target.selectionStart
            return;
        }
    }

    function handleInput(e) {
        if (!isNaN(parseInt(e.data))) {
            if (formattedNumber.value.length <= 12 && cursorPosition.value < 12) {
                cursorPosition.value += 1
                if (cursorPosition.value == 4 && formattedNumber.value.length >= 5) {
                    cursorPosition.value += 1
                }
                if (cursorPosition.value == 8 && formattedNumber.value.length >= 9) {
                    cursorPosition.value += 1
                }
            }

            inputRef.value.setSelectionRange(cursorPosition.value, cursorPosition.value)
        }

        if (e.inputType === 'deleteContentBackward') {
            cursorPosition.value -= 1
            if (formattedNumber.value.length == 0) {
                cursorPosition.value = 0
            }
            inputRef.value.setSelectionRange(cursorPosition.value, cursorPosition.value)
        }
    }

    function focusCursor() {
        inputRef.value.focus()
    }

    function pasteHelper(e) {
        pastedData.value = e.clipboardData.getData('text')
    }

</script>

<template>
    <div
        class="flex rounded-md border overflow-hidden cursor-text"
        :class="{
            'border-lifeworx-red-500 ring-1 ring-lifeworx-red-500 bg-lifeworx-red-500 bg-opacity-10' : isValid == false,
            'border-stone-400 focus-within:ring-1 focus-within:ring-lifeworx-blue-600': isValid == true,
        }"
        @click="focusCursor"
    >
        <div class="w-16 mr-3 relative select-none">
            <span
                class="absolute inset-y-0 left-0 flex items-center pl-2 text-stone-700"
            >+</span>
            <span
                class="absolute inset-y-0 left-3 flex items-center pl-2"
                @click="focusCursor"
            >1</span>
        </div>
        <div class="flex flex-1 mr-1">
            <input
                ref="inputRef"
                class="w-full border-0 text-sm py-1.5 text-stone-700 placeholder:text-stone-400 focus:ring-0 bg-transparent"
                type="text"
                v-model="formattedNumber"
                placeholder="Phone Number"
                @input="handleInput"
                @keydown="handleKeyDownCursor"
                @keyup="handleKeyUpCursor"
                @paste="pasteHelper"
                @click="(e) => cursorPosition = e.target.selectionStart"
            />
            <div
                class="px-2 pt-2 text-right w-16 text-xs select-none"
                :class="{
                    'text-lifeworx-red-500': !isValid,
                    'text-stone-500': isValid
                }"
            >
                {{ props.label.toUpperCase() }}
            </div>
        </div>
    </div>
</template>
