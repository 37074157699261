<script setup>

    import { ref, onMounted } from 'vue'

    import { storeToRefs } from 'pinia'
    import { useConfigStore, useViewStore } from '@/Stores'

    import NavBar from '@/Components/Site/NavBar.vue'
    import LatteFooter from '@/Components/Site/LatteFooter.vue'

    const viewStore = useViewStore()
    const configStore = useConfigStore()

    const { isEmbedded } = storeToRefs(viewStore)

    const props = defineProps({
        config: {
            type: Object
        },
        user: {
            type: Object
        },
        data: {
            type: Object
        },
    })

    onMounted(() => {
        configStore.retrieveConfig()
    })

</script>

<template>

    <div>

        <NavBar
            v-if="!isEmbedded"
            :user="user"
        />

        <div
            :class="{
                'py-20 min-h-screen': !isEmbedded
            }"
        >

            <!-- Page Header -->
            <header
                v-if="$slots.header"
                class="bg-white dark:bg-gray-800 shadow"
            >
                <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <slot name="header" />
                </div>
            </header>

            <!-- Page Content -->
            <main>
                <slot />
            </main>

            <LatteFooter
                v-if="!isEmbedded"
                :config="props.config"
            />

        </div>
    </div>

</template>
