<script setup>
    const props = defineProps({
        modelValue: {
            required: true
        },
        value: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    })

    const emit = defineEmits(['update:modelValue']);
    const onChange = (value) => {
        emit('update:modelValue', props.value)
    };

</script>

<template>
    <input
        :id="`grid-stay-type-${props.value}`"
        type="radio"
        v-model="props.modelValue"
        :value="props.value"
        class="mr-2"
        :class="{
            'opacity-50': props.disabled,
            'cursor-pointer': !props.disabled
        }"
        :disabled="props.disabled"
        @change="onChange"
    />
    <label
        :for="`grid-stay-type-${props.value}`"
        class="text-sm font-semibold text-lifeworx-blue-700"
        :class="{
            'opacity-50': props.disabled,
            'cursor-pointer': !props.disabled
        }"
    >{{ props.label }}</label>

</template>
