<script setup>

    import { useStorage } from '@vueuse/core'

    import { MinusIcon, QueueListIcon} from '@heroicons/vue/20/solid'

    const props = defineProps({
        id: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: 'Untitled'
        },
        collapsible: {
            type: Boolean
        },
        collapsed: {
            type: Boolean,
            default: false
        },
        start: {
            type: Number,
        },
        end: {
            type: Number
        },
        forceSingleLineMobileLayout: {
            type: Boolean,
            default: false
        },
        isEmbedded: {
            type: Boolean,
            default: false
        }
    })

    const collapsed = useStorage(props.id + '-collapsed', false)

</script>

<template>

    <div
        :id="id"
        class="my-12 shadow-better md:rounded-t-lg"
        :class="{
            'rounded-b-lg': collapsed,
            'my-4': props.isEmbedded
        }"
    >

        <div
            class="flex md:h-12 md:items-start md:rounded-t-lg space-x-2 bg-lifeworx-blue-700 px-4 py-2.5 border-b border-lifeworx-blue-600 transition duration-200 z-module-container sticky top-16 md:top-0 md:relative"
            :class="{
                'md:rounded-b-lg bg-lifeworx-blue-700 bg-opacity-70': collapsed,
                'h-12': forceSingleLineMobileLayout,
                'h-20': !forceSingleLineMobileLayout,
            }"
        >

            <div class="flex items-center space-x-2 md:space-x-8">

                <div
                    class=""
                    :class="{
                        'flex w-auto': forceSingleLineMobileLayout,
                        'flex-none w-24 md:flex md:w-auto': !forceSingleLineMobileLayout,
                    }"
                >
                    <h2 class="mt-0.5 mr-5 text-white text-sm md:text-base font-semibold drop-shadow tracking-wide select-none cursor-default">
                        {{ title }}
                    </h2>
                </div>

            </div>

            <div class="flex grow md:flex self-center">
                <div class="flex grow space-x-4 md:space-x-8">
                    <div
                        class="flex grow items-start space-x-2"
                    >
                        <slot
                            name="header"
                            v-if="!collapsed"
                        />
                    </div>

                    <button
                        v-if="collapsible"
                        @click="collapsed=!collapsed"
                        class="h-10 w-10 mt-0.5 md:h-7 md:w-7 p-1 grow-0 justify-self-end	items-center justify-center rounded-md text-white hover:bg-lifeworx-blue-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-lifeworx-blue-700  "
                    >
                        <span class="sr-only">Collapse</span>
                        <MinusIcon v-if="!collapsed" class="block h-5 w-5" aria-hidden="true" />
                        <QueueListIcon v-else class="block h-5 w-5" aria-hidden="true" />
                    </button>

                </div>

            </div>

        </div>

        <div class="bg-white" :class="{ 'hidden': collapsed }">
            <slot></slot>

        </div>
    </div>

</template>

