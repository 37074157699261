import { ref } from 'vue';

import { acceptHMRUpdate, defineStore } from 'pinia';

import { util } from '@/Helpers';

import * as Sentry from "@sentry/vue";

import moment from 'moment';
import { useAuthStore } from './auth';

/*
    This store is the store dedicated for the client list, generated primarily from the jobs and
    nearby client APIs.
*/

export const useClientStore = defineStore('client', () => {

    const authStore = useAuthStore()

    const clients = ref({})
    const lastUpdated = ref(null)

    const loading = ref(false)

    //
    // Hydration
    //

    async function hydrateFromJobs(jobsData) {
        clients.value = Object.assign(clients.value, util.convertArrayToObject(jobsData.map(job => job.client), 'id'))

        // Reshape phone numbers
        Object.values(clients.value).forEach(client => {
            if (Array.isArray(client.phone_numbers)) {
                client.phone_numbers = util.convertArrayToObject(client.phone_numbers, 'type')
            }
        })

        lastUpdated.value = moment.utc()

    }


    // Load client data to store
    async function hydrateFromNearby(clientsData) {
        clients.value = Object.assign(clients.value, util.convertArrayToObject(clientsData, 'id'))

        // Reshape phone numbers
        Object.values(clients.value).forEach(client => {
            if (Array.isArray(client.phone_numbers)) {
                client.phone_numbers = util.convertArrayToObject(client.phone_numbers, 'type')
            }
        })

        lastUpdated.value = moment.utc()

    }


    async function retrieveNearbyClients(address, coffee_user_profile_id = null) {
        const params = {
            address: address
        }
        if (coffee_user_profile_id) {
            params.coffee_user_profile_id = coffee_user_profile_id
        }

        return await new Promise((resolve, reject) => {
            axios.get(
                '/api/v1/clients/nearby',
                {
                    params: params
                },
            ).then(response => {
                resolve(response)
            }).catch((err) => {
                // authStore.handleError(err)
                reject({ "error": err })
            })
        });
    }

    async function getClientById(client_id) {
        return await new Promise((resolve, reject) => {
            axios.get(
                `/api/v1/clients/${client_id}`
            ).then(response => {
                resolve(response)
            }).catch((err) => {
                authStore.handleError(err)
                reject({ "error": err })
            })
        });
    }

    return {
        hydrateFromJobs, hydrateFromNearby, retrieveNearbyClients,
        getClientById,
        clients, lastUpdated, loading
    }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useClientStore, import.meta.hot))
}
