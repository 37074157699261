<script setup>

    import { watch } from 'vue'

    import LatteIconMapPin from '@/Icons/LatteIconMapPin.vue'
    import LatteMapViewList from '@/Components/Maps/LatteMapViewList.vue'
    import LatteMapNearby from '@/Components/Maps/LatteMapNearby.vue'
    import LatteClientSummaryCard from '@/Components/LatteClientSummaryCard.vue'
    import Loader from '@/Components/App/Loader.vue'

    import { useRoute, useRouter } from 'vue-router'

    import { util } from '@/Helpers'

    import { storeToRefs } from 'pinia'
    import { useMapStore, useGoogleMapsStore, useClientStore, useJobStore, useViewStore } from '@/Stores'

    const route = useRoute()
    const router = useRouter()

    const mapStore = useMapStore()
    const googleMapsStore = useGoogleMapsStore()
    const clientStore = useClientStore()
    const jobStore = useJobStore()
    const viewStore = useViewStore()

    const { nearbyClients, nearbyError, areaSummary, selectedId, mapCenter, mapCenterCache, defaultCenter, loading } = storeToRefs(mapStore)
    const { mapRef } = storeToRefs(googleMapsStore)
    const { clients } = storeToRefs(clientStore)
    const { isEmbedded } = storeToRefs(viewStore)

    const props = defineProps({
        mapCenter: {}
    })

    async function loadWhosNearby() {

        loading.value = true

        console.log('cache:', mapCenterCache.value)

        if (mapCenterCache.value) {
            console.log('cached address value from message: ', mapCenter.value)
            mapCenter.value = mapCenterCache.value
        } else if (util.getQueryParam('address') && isEmbedded.value) {
            mapCenter.value = { address: util.objectifyAddressString(util.getQueryParam('address')), geo: { lat: 0, lng: 0 } }
            console.log('onMounted has address: ', mapCenter.value)
        } else if (route.params.client) {

            await jobStore.index(null, null, null, true, route.params.client, null).then(response => {

                loading.value = true

                console.log('Rehydrating from API', { jobs: response.data })

                mapCenter.value = { address: response.data[0].client.address, geo: response.data[0].client.geo }

            })

            console.log('route has client id: ', mapCenter.value)
        } else if (props.mapCenter) {
            mapCenter.value = { address: props.mapCenter.address, geo: props.mapCenter.geo },
            console.log('onMounted has prop: ', mapCenter.value)
        } else {
            mapCenter.value = defaultCenter.value
            console.log('onMounted no address, using default: ', mapCenter.value)
        }

        await clientStore.retrieveNearbyClients(util.stringifyAddressObject(mapCenter.value.address)).then(response => {

            nearbyError.value = null
            console.log('Rehydrating from API', { data: response.data })
            clientStore.hydrateFromNearby(response.data.clients)

            areaSummary.value = response.data.areaSummary

            nearbyClients.value = response.data.clients
            mapCenter.value.geo.lat = parseFloat(response.data.center.geo.lat)
            mapCenter.value.geo.lng = parseFloat(response.data.center.geo.lng)

            loading.value = false

        }).catch((err) => {

            loading.value = false
            console.error('catching err lattewhosnearby:', err)
            nearbyError.value = err.error

        })

    }

    watch(() => mapRef.value?.ready, (ready) => {
        if (!ready) return;
        loadWhosNearby().then(() => mapStore.resetMap() )
    })

</script>

<template>

    <div
        class="px-5 py-2.5 text-sm md:text-xs border-b text-lifeworx-blue-800 bg-white sticky top-28 md:top-0 md:relative md:flex"
        :class="{ 'py-0': isEmbedded.value }"
    >

        <span
            v-if="loading || nearbyError"
            class="text-gray-500"
        >
        &mdash;
        </span>

        <template v-else>

            <div class="flex-wrap flex-auto">

                <span class="inline">
                    Clients &amp; prospects near
                </span>
                <span
                    class="tracking-wider hover:underline text-left cursor-pointer"
                    @click="mapStore.recenterMap"
                >

                    <LatteIconMapPin class="text-lifeworx-green inline-block ml-0.5 -mt-1.5 -mb-1 h-4 w-4" />
                    <span class="font-bold text-lifeworx-green">
                        {{ mapCenter.address?.address + ', ' + mapCenter.address?.city + ', ' + mapCenter.address?.state + ' ' + mapCenter.address?.zip }}
                    </span>

                </span>

            </div>

            <button
                class="justify-end text-lifeworx-blue-500 hover:text-lifeworx-blue-700 font-semibold underline text-sm -mt-1"
                @click="mapStore.resetMap()"
            >
                Reset view
            </button>

        </template>

    </div>

    <div class="md:grid md:grid-cols-3 md:max-h-96">

        <div
            v-if="!loading && !nearbyError"
            class="shadow-inner border-r-2 h-48 md:h-96 overflow-scroll"
        >

            <LatteMapViewList
                :data="nearbyClients"
                :selected="selectedId"
                @update:selected="mapStore.selectMarker"
                @reset="mapStore.resetMap()"
            />

        </div>


        <div
            class="md:col-span-2 shadow-inner h-96 sticky bottom-14"
            :class="{
                'md:col-span-3': loading || nearbyError,
            }"
        >

            <div class="h-96">

                <LatteMapNearby
                    :mapCenter="mapCenter"
                    :nearbyClients="nearbyClients"
                />

            </div>

            <div
                v-if="selectedId && selectedId.value !== null && !loading && !nearbyError"
                class="relative h-fit w-1/2 p-2 z-auto -top-[24rem] flex items-center"
            >

                <div
                    class="w-full h-fit bg-white shadow p-4 overflow-scroll text-sm"
                >

                    <LatteClientSummaryCard
                        :client="clients[selectedId]"
                        @close="mapStore.resetMap(false)"
                    />

                </div>

            </div>

            <div
                v-if="loading"
                class="h-96 w-full opacity-50 bg-lifeworx-blue-800 relative -top-96 flex items-center justify-center"
            >
                <Loader />
            </div>

            <div
                v-else-if="nearbyError"
                class="h-96 w-auto bg-opacity-50 bg-lifeworx-red-200 relative -top-96 flex items-center justify-center backdrop-blur-sm"
            >
                <div class="p-10 text-lifeworx-red-950 text-center">

                    <h2 class="font-bold text-2xl">Error</h2>
                    <p class="w-96 my-5 font-bold">
                        {{ nearbyError.message  }}
                    </p>
                    <p class="w-96 my-5 font-bold">
                        {{ nearbyError.response?.data.message }}
                    </p>

                    <button
                        v-if="![422].includes(nearbyError.response?.status)"
                        class="w-96 my-5 font-bold underline"
                        @click="loadWhosNearby()"
                    >
                        retry
                    </button>

                </div>
            </div>


        </div>

    </div>

    <div class="px-5 py-2.5 text-sm md:text-xs border-t text-lifeworx-blue-800 bg-white sticky bottom-0 md:relative ">

        <span
            v-if="loading || nearbyError"
            class="text-gray-500"
        >
            &mdash;
        </span>

        <template v-else>

            <span>
                Showing {{ nearbyClients?.length }} closest of
            </span>
            <span class="font-bold text-lifeworx-purple">
                {{ areaSummary?.clientCount }} {{ areaSummary?.clientCount == 1 ? 'client' : 'clients' }}
            </span>
            <span>
                and
            </span>
            <span class="font-bold text-lifeworx-orange">
                {{ areaSummary?.prospectCount }} {{ areaSummary?.prospectCount == 1 ? 'prospect' : 'prospects' }}
            </span>
            <span>
                within 10 miles
            </span>

        </template>

    </div>

</template>
