<script setup>

    import { useViewStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import { util } from '@/Helpers'

    const viewStore = useViewStore()

    const { scrollDist, pageNavTitle, pageNavItems } = storeToRefs(viewStore)

</script>

<template>

    <div
        v-if="pageNavItems && Object.keys(pageNavItems).length > 0"
        class="fixed z-floating-nav left-5 transition-all duration-300 flex"
        :class="{
            'bottom-5': scrollDist > 500,
            '-bottom-32 lg:bottom-5': scrollDist <= 500,
        }"
    >

        <div
            class="hidden xl:flex"
        >

            <ol>
                <li
                    v-if="pageNavTitle"
                    class="text-lifeworx-blue-700 text-sm font-bold mb-4 cursor-default"
                >
                    {{ pageNavTitle }}
                </li>
                <template
                    v-for="item in pageNavItems"
                >
                    <li
                        class="text-xs text-lifeworx-blue-600 font-bold mb-2 cursor-pointer hover:text-lifeworx-blue-700 group"
                        @click="util.scrollToElement(item.id)"
                    >
                        {{ item.label }}
                        <span
                            v-if="item.status"
                            class="inline-flex items-center rounded-full bg-lifeworx-blue-600 text-white ml-2 px-1.5 py-0.5 text-xs font-semibold group-hover:bg-lifeworx-blue-700">
                            {{ item.status }}%
                        </span>
                    </li>
                </template>
            </ol>

        </div>

    </div>

</template>
