<script setup>

    import { onMounted, onUnmounted, ref, watch } from 'vue'

    import { util } from '@/Helpers'
    import moment from 'moment'

    import LatteStaffLabel from '@/Components/LatteStaffLabel.vue'
    import LatteRatingBar from '@/Components/Feedback/LatteRatingBar.vue'

    import { XMarkIcon } from '@heroicons/vue/20/solid'

    const clientCoffeeBaseUrl = import.meta.env.VITE_COFFEE_BASEURL + '/staff/sales-manager/information/pid/'
    const caregiverCoffeeBaseUrl = import.meta.env.VITE_COFFEE_BASEURL + '/user/recruiter-candidates/personalinfo/id/'

    const props = defineProps({
        client: {}
    })

    const loadingImages = ref([])

    const emit = defineEmits(['close'])

    const close = () => {
        emit('close')
    }

    const closeOnEscape = (e) => {
        if (e.key === 'Escape') {
            close()
        }
    }

    onMounted(() => document.addEventListener('keydown', closeOnEscape))

    onUnmounted(() => {
        document.removeEventListener('keydown', closeOnEscape)
    })

    watch(() => props.client.recent_caregivers, (newValue, oldValue) => {
        if (props.client?.recent_caregivers?.length > 0) {
            props.client.recent_caregivers.forEach(caregiver => {
                loadingImages.value[caregiver.profile_image_url] = true
            })
        }
    })


</script>

<template>

    <div class="relative text-sm text-gray-500">

        <div class="absolute right-0 top-0 hidden sm:block">
            <button
                type="button"
                class="text-zinc-400 hover:text-zinc-500 focus:outline-none focus:ring-0"
                @click="emit('close')"
            >
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
        </div>

        <div
            class="uppercase text-2xs tracking-wider font-bold"
            :class="{
                'text-lifeworx-purple-500': props.client.type == 'client',
                'text-lifeworx-orange-500': props.client.type == 'prospect'
            }"
        >
            {{  props.client.type }}
        </div>

        <a
            :href="clientCoffeeBaseUrl + props.client.coffee_user_profile_id"
            class="text-lg font-semibold text-lifeworx-blue-700 hover:text-lifeworx-blue-600 hover:underline"
            target="_blank"
        >
            {{ props.client.first_name + ' ' + props.client.last_name }}
        </a>

        <LatteStaffLabel
            :staff="props.client.assigned_sales_manager"
        />

        <template
            v-if="props.client.type == 'client'"
        >
            <div class="mt-1">
                <LatteRatingBar
                    :subject="props.client"
                    :subjectType="props.client.type"
                />
            </div>
        </template>

        <div class="mt-2">
            Address
            <div class="font-semibold text-lifeworx-blue-900">
                <span
                    v-if="!props.client.address?.address || props.client.address?.address == null || props.client.address?.address == ''"
                    class="text-lifeworx-red-500 italic"
                >
                    Missing Street Address*
                </span>
                {{ props.client.address?.address }}<br>
                {{ props.client.address?.city }}, {{ props.client.address?.state }} {{ props.client.address?.zip }}<br>
                <span
                    v-if="!props.client.address?.address || props.client.address?.address == null || props.client.address?.address == ''"
                    class="text-gray-500 italic text-xs"
                >
                    *Estimated Map Marker Location
                </span>
            </div>
        </div>

        <template
            v-if="props.client.type == 'client'"
        >

            <div class="mt-2">
                <!-- TODO -->
                Lifetime Revenue
                <div class="font-semibold text-lifeworx-blue-900">
                    {{ props.client.total_revenue ? util.currencyWithoutFraction(props.client.total_revenue) : 'Unknown' }}
                </div>
            </div>

            <div class="mt-2 grid grid-cols-2">
                <div>
                    Service Start
                    <div class="font-semibold text-lifeworx-blue-900">
                        {{ props.client.service_start_date ? moment(props.client.service_start_date).utc().format('MMM D, YYYY') : '&mdash;' }}
                    </div>
                </div>

                <div>
                    Most Recent
                    <div class="font-semibold text-lifeworx-blue-900">
                        {{ props.client.recent_service_date ? moment(props.client.recent_service_date).utc().format('MMM D, YYYY') : '&mdash;' }}
                    </div>
                </div>
            </div>

            <div
                v-if="props.client.recent_caregivers?.length > 0"
                class="mt-2"
            >
                <div>
                    Recent Caregivers
                </div>
                <div class="mt-1 flex">

                    <template
                        v-for="caregiver in props.client.recent_caregivers.slice(0, 3)"
                    >

                        <VTooltip
                            :triggers="['click', 'touch', 'hover']"
                            :autoHide="true"
                        >
                            <div
                                class="flex-shrink-0 rounded-full overflow-clip h-14 w-14 border-2 border-zinc-300 bg-zinc-100 mr-1"
                            >

                                <a
                                    :href="caregiverCoffeeBaseUrl + caregiver.coffee_job_profile_id"
                                    target="_blank"
                                >
                                    <div
                                        v-if="loadingImages[caregiver.profile_image_url]"
                                        class="flex w-full h-full justify-center items-center"
                                    >
                                        <svg
                                            class="h-6 w-6 animate-spin text-zinc-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        >
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </div>

                                    <img
                                        :src="caregiver.profile_image_url"
                                        :alt="caregiver.first_name + ' ' + caregiver.last_name"
                                        class="object-cover h-14 w-14"
                                        :class="{
                                            'invisible': loadingImages[caregiver.profile_image_url]
                                        }"
                                        @load="loadingImages[caregiver.profile_image_url] = false"
                                    />


                                </a>

                            </div>

                            <template #popper>
                                <span class="text-sm">
                                    {{ caregiver.first_name + ' ' + caregiver.last_name }}
                                </span>
                            </template>

                        </VTooltip>

                    </template>

                    <div
                        class="flex-shrink-0 h-14 w-20 justify-center flex items-center"
                    >
                        <a
                            :href="'/feedback/client/' + props.client.id"
                            class="font-semibold text-lifeworx-blue-600 hover:underline"
                            target="_blank"
                        >
                            SEE ALL
                        </a>
                    </div>

                </div>
            </div>

        </template>

    </div>


</template>
