<script setup>

    import { computed } from 'vue'

    const props = defineProps({
        href: {
            type: String,
        },
        active: {
            type: Boolean,
        },
    })

    const classes = computed(() =>
        props.active
            ? 'inline-flex items-center px-1 pt-1 border-b-2 border-lifeworx-blue-800 dark:border-indigo-600 text-sm font-semibold leading-5 text-lifeworx-blue-800 dark:text-gray-100 focus:outline-none focus:border-white transition duration-150 ease-in-out'
        : 'inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-semibold leading-5 text-white dark:text-gray-400 hover:text-lifeworx-blue-800 dark:hover:text-gray-300 hover:border-lifeworx-blue-800 dark:hover:border-gray-800 focus:outline-none focus:text-lifeworx-blue-800 dark:focus:text-gray-300 focus:border-white dark:focus:border-gray-700 transition duration-150 ease-in-out'
    )

</script>

<template>
    <button
        :class="classes"
    >
        <slot />
    </button>
</template>
