<script setup>

    import { ref } from 'vue'

    import { storeToRefs } from 'pinia'
    import { useViewStore } from '@/Stores'

    import NavBar from '@/Components/Site/NavBar.vue'
    import LatteFooter from '@/Components/Site/LatteFooter.vue'

    const viewStore = useViewStore()
    const { isEmbedded } = storeToRefs(viewStore)

    const showingNavigationDropdown = ref(false)

    const props = defineProps({
        config: {
            type: Object
        },
        user: {
            type: Object
        },
        data: {
            type: Object
        },
    })

</script>

<template>

    <div>

        <NavBar
            v-if="!isEmbedded"
        />

        <div class="min-h-screen flex flex-col sm:justify-center items-center py-20">
            <div class="w-full sm:max-w-md mt-6 px-6 py-4 dark:bg-gray-800">
                <slot />
            </div>

            <LatteFooter
                v-if="!isEmbedded"
                :config="props.config"
            />

        </div>
    </div>

</template>
