<script setup>

    import { watch, onMounted, ref, computed} from 'vue'

    const props = defineProps({
        modelValue: {
            type: [ Object, String ],
            default: {}
        },
        options: {
            type: Array,
            required: true
        },
        useId: {
            type: Boolean,
            default: false
        },
        showNullOption: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            default: ''
        },
        input_class: {
            type: String,
            default: 'bg-white text-stone-700'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    })

    const emit = defineEmits(['update:modelValue'])

    const modelProxy = computed({
      get: () => JSON.stringify(props.modelValue),
      set: (value) => emit('update:modelValue', JSON.parse(value)),
    })

</script>

<template>
        <select
            v-model="modelProxy"
            class="relative w-full pl-2.5 h-[34px] pr-10 md:px-2.5 py-7 md:py-1.5 rounded-md text-base tracking-wider border border-stone-400 sm:text-sm shadow-sm focus:border-lifeworx-blue-600 focus:ring-lifeworx-blue-600 focus:ring-1 focus:outline-none"
            :disabled="props.disabled"
            :class="[
                props.input_class,
                props.disabled ? 'cursor-default' : 'cursor-pointer'
            ]"
        >
            <option
                v-if="props.showNullOption"
                :value="JSON.stringify({ id: null, name: '' })"
            >
                &mdash;
            </option>
            <option
                v-for="(option, index) in props.options"
                :key="option.id"
                :value="JSON.stringify(option)"
            >
                {{ props.useId ? option.id : option.name }}
            </option>
        </select>

</template>
