    import { ref, computed } from 'vue'

    import { defineStore, acceptHMRUpdate } from 'pinia'

    export const useNotificationStore = defineStore('notification', () => {

        const notificationQueue = ref([])

        const modalActionsShow = ref(false)
        const modalActionsType = ref(null)
        const modalActionsSubject = ref(null)
        const modalActionsCloseable = ref(true)

        const cleanupTimer = setInterval(cleanup, 1000)


        //
        // Notification helpers
        //

        function add(message, variant='message', details, expireTime = 5000) {
            const id = crypto.randomUUID()
            notificationQueue.value.push({
                id: id,
                variant: variant,
                message: message,
                details: details,
                show: true,
                expires: new Date(new Date().getTime() + expireTime)
            })

            return id
        }

        function cleanup() {
            notificationQueue.value.forEach((notif) => {
                if (notif.expires < new Date()) {
                    notif.show = false
                }
            })
            let i
            for (i = notificationQueue.value.length - 1; i >= 0; i -= 1) {
                if (notificationQueue.value[i].show == false && new Date(notificationQueue.value[i].expires.getTime() + 300) < new Date()) {
                    notificationQueue.value.splice(i, 1);
                }
            }
        }

        function removeById(id) {
            notificationQueue.value = notificationQueue.value.filter(item => item.id != id)
        }

        function remove(index) {
            notificationQueue.value[index].expires = new Date(new Date().getTime() - 10000)
        }

        const activeNotifications = computed(() => {
            let clone = (x) => x;
            return notificationQueue.value.map(clone).reverse();
        })



        //
        // Handler for action modal
        //
        const bodyEl = document.getElementsByTagName('body')[0];

        function showActionModal(subject, type, closeable=true) {
            modalActionsShow.value = true
            modalActionsType.value = type
            modalActionsSubject.value = subject
            bodyEl.classList.add("overflow-hidden")
        }


        return {
            activeNotifications, add, remove, removeById,
            modalActionsShow, modalActionsType, modalActionsSubject, modalActionsCloseable, showActionModal
        }

    })

    if (import.meta.hot) {
        import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot))
    }
