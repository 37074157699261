import { ref } from 'vue'

import { defineStore, acceptHMRUpdate } from 'pinia'

import { util } from '@/Helpers'

import moment from 'moment'

export const useCaregiverStore = defineStore('caregiver', () => {

    const allCaregivers = ref({})
    const caregivers = ref({})
    const lastUpdated = ref(null)


    //
    // Hydration
    //

    async function hydrateFromJobs(jobsData) {

        caregivers.value = Object.assign(caregivers.value, util.convertArrayToObject(jobsData.map(job => job.caregiver), 'id'))

        // Reshape phone numbers
        Object.values(caregivers.value).forEach(caregiver => {
            if (Array.isArray(caregiver.phone_numbers)) {
                caregiver.phone_numbers = util.convertArrayToObject(caregiver.phone_numbers, 'type')
            }
        })

        lastUpdated.value = moment.utc()

    }

    async function hydrateAllCaregivers(data) {
        allCaregivers.value = data
    }

    async function getAllCaregivers() {
        const params = {
            additional_fields: [
                'job_rating_bar_path',
            ],
        }
        return await new Promise((resolve, reject) => {
            axios.get(
                `/api/v1/caregivers`,
                {
                    params: params
                },
            ).then(response => {
                resolve(response)
            }).catch((err) => {
                authStore.handleError(err)
                reject({ "error": err })
            })
        });
    }

    async function findCaregiversFromSearch(searchKey) {
        if (!searchKey) {
            return [];
        }

        const searchKeyLower = searchKey.toLowerCase();
        const searchKeyArray = searchKeyLower.split(" ");

        const filterCaregivers = (caregiver) => {
            const firstName = caregiver.first_name.toLowerCase();
            const lastName = caregiver.last_name.toLowerCase();

            if (searchKeyArray.length > 1) {
                return firstName.startsWith(searchKeyArray[0]) && lastName.startsWith(searchKeyArray[1]);
            } else {
                return firstName.startsWith(searchKeyLower) || lastName.startsWith(searchKeyLower);
            }
        };

        return Object.values(allCaregivers.value).filter(filterCaregivers);
    }

    function findCaregiverById(id) {
        if (!id) {
            return null;
        }

        return allCaregivers.value.find(caregiver => caregiver.id === id) || null;
    }



    return {
        hydrateFromJobs, hydrateAllCaregivers,
        getAllCaregivers, findCaregiversFromSearch, findCaregiverById,
        allCaregivers, caregivers
     }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCaregiverStore, import.meta.hot))
}
