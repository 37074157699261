<script setup>

</script>

<template>

    <div class="w-32 mx-auto flex justify-center object-center">
        <div style="border-top-color:transparent" class="w-12 h-12 border-4 border-lifeworx-blue-400 border-dotted rounded-full animate-spin"></div>
    </div>

</template>
