import { reactive } from 'vue'

/**
 * The client of these codes is responsible for calling run at start and finish at the finish of the request.
 * If a call is missed. The data here is no longer correct.
 */
const jobHash = reactive({})

function computeKey(resource, action) {
    return `${resource}_${action}`
}

export function run(resource, action) {
    const key = computeKey(resource, action)

    if (!jobHash[key]) {
        jobHash[key] = 0
    }

    jobHash[key] += 1
}

export function finish(resource, action) {
    const key = computeKey(resource, action)

    if (!jobHash[key]) {
        jobHash[key] = 0
        return
    }

    jobHash[key] -= 1
}

export function isFinished(resource, action) {
    const key = computeKey(resource, action)
    return jobHash[key] == 0
}

export function isRunBefore(resource, action) {
    const key = computeKey(resource, action)
    return jobHash[key] != undefined
}
