import { ref, reactive } from 'vue'

import * as Sentry from "@sentry/vue";

import { useNProgress } from '@vueuse/integrations/useNProgress'

import { defineStore, storeToRefs, acceptHMRUpdate } from 'pinia'

import { useConfigStore, useNotificationStore } from '@/Stores'

import { util } from '@/Helpers'

export const useAuthStore = defineStore('auth', () => {

    const configStore = useConfigStore()
    const { config } = storeToRefs(configStore)

    const notificationStore = useNotificationStore()

    const { isLoading } = useNProgress()

    const auth = reactive({
        loading: true,
        authenticated: false
    })

    const progress = ref()

    const form = reactive({
        email: '',
        password: '',
        remember: false,
        processing: false,
        errors: {}
    })

    const user = ref({})

    //
    // Reactive handler for nav bar loading indicator
    //

    const loading = auth.loading


    //
    // Error Handler
    //

    function handleError(err) {

        console.log('handling error in auth.js')

        if (err.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            if (!err.response.ok) {

                notificationStore.add('Error ' + err.response?.status, 'error', err.response?.data.message)
                Sentry.captureException(err)

            }

        } else if (err.request) {

            // The request was made but no response was received
            notificationStore.add('Error', 'error', util.titleCase(err.message))
            Sentry.captureException(err)

        } else {

            // Something happened in setting up the request that triggered an Error
            notificationStore.add('Error', 'error', util.titleCase(err.message))
            Sentry.captureException(err)

        }

    }

    // Refresh user data every 5 minutes
    // const refreshTimer = setInterval(refreshData, 300000)

    // function refreshData() {
    //     checkAuth(false)
    // }

    // Cleanup
    // onBeforeUnmount(() => {
    //     clearInterval(refreshTimer)
    // })



    //
    // Auth action handlers
    //

    async function login(username, password, redirect='/') {

        isLoading.value = true
        form.processing = true

        await axios.post(
            '/login',
            {
                email: username.toLowerCase(),
                password: password
            },
        ).then(response => {

            if ([200].includes(response.status)) {
                window.location.href = redirect
            }

        }).catch((err) => {

            isLoading.value = !isLoading.value
            form.errors = err.response.data.errors
            form.processing = false
            handleError(err)

        })

    }

    async function logout() {

        isLoading.value = true
        auth.loading = true

        await axios.post('/logout').then(response => {

            window.location.href = '/login'

        }).catch((err) => {

            handleError(err)
            window.location.href = '/login'

        })

    }

    return { auth, form, user, login, logout, handleError, progress }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
