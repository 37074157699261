<script setup>

    import { ref, reactive, onMounted, watch, computed } from 'vue'

    import pluralize from 'pluralize';

    import { MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/vue/20/solid'

    const props = defineProps({
        modelValue: {},
        currency: {
            type: Boolean,
            default: false
        },
        unit: {
            type: String
        },
        step: {
            type: Number,
            default: 1
        },
        rangeMin: {
            type: Number,
            default: 0
        },
        rangeMax: {
            default: 'Infinity'
        }
    })

    const emit = defineEmits(['update:modelValue'])

    const number = ref(null)

    const state = reactive({
        number: props.rangeMin ? props.rangeMin : 1,
        currencyDisplay: ''
    })

    onMounted(() => {
        number.value.value = props.modelValue
        if (props.currency) {
            state.currencyDisplay = '$' + Number(currency(number.value.value)).toFixed(2)
            number.value.dispatchEvent(new Event("change"))
        }
    })

    watch(
        () => state.number,
        (numberState) => {
            number.value.value = numberState
            number.value.dispatchEvent(new Event("change"))
        }
    )

    function increment() {
        number.value.stepUp()
        if (props.currency) {
            state.currencyDisplay = '$' + Number(currency(number.value.value)).toFixed(2)
        }
        number.value.dispatchEvent(new Event("change"))
    }

    function decrement() {
        if (number.value.value > props.rangeMin) {
            number.value.stepDown()
            if (props.currency) {
                state.currencyDisplay = '$' + Number(currency(number.value.value)).toFixed(2)
            }
        } else {
            number.value.value = ''
        }
        number.value.dispatchEvent(new Event("change"))
    }

    function updateCurrency() {
        number.value.value = Number(state.currencyDisplay.replace(/[^0-9.-]+/g, '')).toFixed(2)
        number.value.dispatchEvent(new Event("change"))
    }

    function focus() {
        number.value.focus()
    }

    function currency(rate) {
        return (rate).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });
    }

    const unitText = computed(() => {
        return props.modelValue !== 1 ? pluralize(props.unit, props.modelValue) : props.unit
    })



</script>

<template>

    <form
        class="flex border border-stone-400 px-2 py-1 rounded-md items-center"
        @submit.prevent
        novalidate
    >
        <button
            class="border-2 h-5 border-lifeworx-blue-600 rounded-full cursor-pointer hover:bg-lifeworx-blue-600 disabled:cursor-not-allowed disabled:text-stone-300 text-lifeworx-blue-600 hover:text-white disabled:border-stone-300 disabled:hover:text-stone-300 disabled:hover:bg-white"
            @click="decrement()"
            :disabled="props.modelValue !== 0 && props.modelValue == null || props.modelValue == ''"
        >
            <MinusIcon
                v-if="!props.modelValue || props.modelValue > props.rangeMin"
                class="h-4 w-4 stroke-2 "
                aria-hidden="true"
            />
            <XMarkIcon
                v-else
                class="h-4 w-4 stroke-2  "
                aria-hidden="true"
            />
        </button>

        <div class="flex flex-col justify-items-center items-center">
            <input
                :type="props.currency ? 'hidden' : 'number'"
                :min="props.rangeMin"
                :max="props.rangeMax"
                :step="props.step"
                placeholder="—"
                autocomplete="new-password"
                v-model="state.number"
                :value="modelValue"
                ref="number"
                class="w-20 h-6 mt-0.5 text-center rounded border-none appearance-none font-semibold text-xl border-transparent focus:border-transparent focus:ring-0 focus:outline-none placeholder:text-stone-400"
                @change="$emit('update:modelValue', $event.target.value)"
                @keyup="$emit('update:modelValue', $event.target.value)"
                @keydown.enter.stop.prevent
            />

            <input
                v-if="props.currency"
                v-model="state.currencyDisplay"
                ref="currencyDisplay"
                autocomplete="new-password"
                class="text-stone-400 h-6 mt-0.5 col-span-2 md:col-span-6 text-center rounded border-none appearance-none font-semibold text-xl border-transparent focus:border-transparent focus:ring-0 focus:outline-none"
                @change="updateCurrency()"
                @keyup="updateCurrency()"
                @keydown.enter.stop.prevent
            />
            <span
                v-if="props.unit"
                class="text-xs"
                @click="focus()"
                >{{ unitText }}</span>
        </div>

        <button
            class="border-2 h-5 border-lifeworx-blue-600 rounded-full cursor-pointer hover:bg-lifeworx-blue-600 disabled:cursor-not-allowed disabled:text-stone-300 text-lifeworx-blue-600 hover:text-white disabled:border-stone-300 disabled:hover:text-stone-300 disabled:hover:bg-white"
            @click="increment()"
            :disabled="props.modelValue >= props.rangeMax"
        >
            <PlusIcon class="h-4 w-4 stroke-2" aria-hidden="true" />
        </button>

    </form>

</template>
