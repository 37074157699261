import { ref, computed } from 'vue'

import { defineStore, acceptHMRUpdate } from 'pinia'

import { useNProgress } from '@vueuse/integrations/useNProgress'

export const useMapStore = defineStore('map', () => {

    const { isLoading } = useNProgress()
    const loading = ref(isLoading)

    const mapLoading = ref(isLoading)

    const defaultCenter = ref({
        geo: {
            lat: 41.1594000000,
            lng: -73.7652700000,
        },
        address: {
            address: '400 King St',
            city: 'Chappaqua',
            state: 'NY',
            zip: '10514'
        }
    })

    const mapCenter = ref({ address: { address: null, city: null, state: null, zip: null, }, geo: { lat: 0, lng: 0 }})

    const mapCenterCache = ref(null)

    const clientId = ref(null)

    const selectedId = ref(null)
    const scrollToId = ref(null)

    const nearbyClients = ref([])
    const areaSummary = ref()

    const lastUpdated = ref(null)

    const mapClient = ref(null)

    const nearbyError = ref(null)

    function bindClient(client) {
        mapClient.value = client
    }

    function resetMap(centerMap=true) {
        selectedId.value = null
        if (centerMap) { mapClient.value?.resetMap(mapCenter.value.geo, Object.values(mapMarkers.value)) }
    }

    function recenterMap() {
        selectedId.value = null
        mapClient.value?.centerMap(mapCenter?.value.geo.lat, mapCenter?.value.geo.lng)
    }

    function selectMarker(id) {
        if (id == 0 || id == null) {

            mapStore.resetMap()
            selectedId.value = id

        } else {

            selectedId.value = id
            scrollToId.value = id

            mapClient.value?.zoomToMarker(mapMarkers.value[id].lat, mapMarkers.value[id].lng)
        }
    }

    const mapMarkers = computed(() => {
        let markers = {}

        if (!nearbyClients) return;

        nearbyClients.value.forEach((client) => markers[client.id] = { lat: parseFloat(client.geo.lat), lng: parseFloat(client.geo.lng) })
        return markers;
    })



    //
    // UI Helpers
    //

    function purge() {
        nearbyClients.value = {}
    }


    return {
        purge,
        loading, nearbyError, nearbyClients, areaSummary,
        mapLoading, mapCenter, mapCenterCache, defaultCenter, mapMarkers, clientId, selectedId, scrollToId, lastUpdated,
        resetMap, recenterMap, bindClient, selectMarker
    }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useMapStore, import.meta.hot))
}
