import { ref,toRaw, watch } from 'vue';

import { acceptHMRUpdate, defineStore } from 'pinia';
import { useNProgress } from '@vueuse/integrations/useNProgress';

import * as Sentry from "@sentry/vue";

import moment from 'moment';
import { patch } from '@/Helpers/takeLatestResponseRequest';
import { useAuthStore } from './auth';
import { useFormStatusStore } from './formStatus';
import { useStaffStore } from '@/Stores';
import { deepCopy, util } from '@/Helpers';

/*
    This store is the store dedicated for a single client and its clients. Operations such as add,
    edit, and delete contact data are supported through this store and the client data will be
    updated with the latest data from any client and/or contact changes in different components.
*/

const inactivityRefreshTime = 120000
export const useClientSingleStore = defineStore('clientSingle', () => {

    const authStore = useAuthStore()
    const staffStore = useStaffStore()
    const formStatusStore = useFormStatusStore()

    const client = ref(defaultClient())

    const isEdit = ref(false)
    const enableAddNewPrimaryContactMode = ref(false)

    const lastUpdated = ref(null)

    const { isLoading } = useNProgress()
    const loading = ref(isLoading)

    const maxRetryDialogId = ref(null)
    function setMaxRetryDialogId(id) {
        maxRetryDialogId.value = id
    }
    function removeMaxRetryDialogId() {
        maxRetryDialogId.value = null
    }
    function getMaxRetryDialogId() {
        return maxRetryDialogId.value
    }


    function clientHasPhoneNumber(targetClient) {
        if (!targetClient) {
            return false
        }

        if (!targetClient?.phone_numbers || targetClient?.phone_numbers.length == 0) {
            return false
        }

        for (const i in targetClient.phone_numbers) {
            if (!util.isEmpty(targetClient.phone_numbers[i].number)) {
                return true
            }
        }

        return false
    }

    const inactivityTimeout = ref(null);
    const resetInactivityRefreshTimer = () => {
        clearTimeout(inactivityTimeout.value)
        inactivityTimeout.value = setTimeout(refreshClientData, inactivityRefreshTime); // 2 minutes
    }

    function stopInactivityTimeout() {
        clearTimeout(inactivityTimeout.value)
    }

    function defaultClient() {
        return {
            contacts: [],
            first_name: null,
            last_name: null,
            assigned_sales_manager: staffStore.defaultStaff(),
            address: {
                address: null,
                address_2: null,
                city: null,
                state: null,
                zip: null,
            },
            health_conditions: [],
            expert_must_have_factors: [],
            expert_wow_factors: [],
            height: null,
            weight: null,
            charge_rates: [],
        }
    }

    function refreshClientData() {
        if (!client.value.id) {
            return
        }

        getClientById(client.value.id)
            .then(response => {
                hydrate(response.data)
                resetInactivityRefreshTimer()
            })
    }

    watch([() => client.value.id], (newValue, oldValue) => {
        resetInactivityRefreshTimer()
    }, {immediate: true})

    watch([() => client.value.contacts], (newValue, oldValue) => {
        const clientContact = client.value.contacts.find(contact => contact.type === 'Client')

        if (clientContact) {
            updateClientContactInfo(clientContact)
        }
    }, {immediate: true, deep: true})

    //
    // Hydration
    //

    async function hydrate(clientData) {
        client.value = clientData
        lastUpdated.value = moment.utc()
    }

    async function hydrateContact(contactData) {
        const index = client.value.contacts.findIndex(item => item.id == contactData.id)
        client.value.contacts[index] = contactData
        lastUpdated.value = moment.utc()
    }

    function updateClientContactInfo(data) {
        if (data.type == 'Client') {
            client.value.first_name = data.first_name
            client.value.last_name = data.last_name
            client.value.address.address = data.address
            client.value.address.address_2 = data.address_2
            client.value.address.city = data.city
            client.value.address.state = data.state
            client.value.address.zip = data.zip
            client.value.emails = data.emails
            // todo: update geo fields (not part of contact)
            client.value.phone_numbers = data.phones
        } else if (!client.value.contacts.find(contact => contact.type === 'Client')) {
            client.value.first_name = null
            client.value.last_name = null
            client.value.address.address = null
            client.value.address.address_2 = null
            client.value.address.city = null
            client.value.address.state = null
            client.value.address.zip = null
            client.value.emails = null
            client.value.geo = null,
            client.value.phone_numbers = null
        }
    }

    function formatRequest(data) {
        if (data.assigned_sales_manager) {
            data.assigned_sales_manager_id = data.assigned_sales_manager.id
            delete data.assigned_sales_manager
        }
        return data
    }

    async function getClientByCoffeeUserProfileId(coffee_user_profile_id) {
        return await new Promise((resolve, reject) => {
            axios.get(
                `/api/v1/clients/coffee/${coffee_user_profile_id}`
            ).then(response => {
                resolve(response)
            }).catch((err) => {
                authStore.handleError(err)
                reject({ "error": err })
            })
        });
    }

    async function getClientById(client_id) {
        return await new Promise((resolve, reject) => {
            axios.get(
                `/api/v1/clients/${client_id}`
            ).then(response => {
                resolve(response)
            }).catch((err) => {
                authStore.handleError(err)
                reject({ "error": err })
            })
        });
    }

    async function storeClient(clientData, formId, referenceId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }
        return await new Promise((resolve, reject) => {
            const config = {
                headers: {
                    'X-Request-ID' : referenceId,
                }
            }
            axios.post('/api/v1/clients/', formatRequest(clientData), config)
                .then(function (response) {
                    if (response.status === 201) {
                        const redirectUrl = response.headers.location;
                        return axios.get(redirectUrl);
                    }
                }).then(function (response) {
                    resolve(response)
                }).catch(function (error) {
                    reject({ "error": error })
                    Sentry.captureException(error)
                }).finally(() => {
                    loading.value = false
                    if (formId) {
                        formStatusStore.removeLoading(formId)
                    }
                })
        });
    }

    async function updateClient(clientId, modifiedClientData, formId, referenceId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }

        return await new Promise((resolve, reject) => {
            const config = {
                headers: {
                    'X-Request-ID' : referenceId,
                }
            }
            patch(`/api/v1/clients/${clientId}`, formatRequest(modifiedClientData), config)
                .then(function (response) {
                    resolve(response)
                }).catch(function (error) {
                    reject({ "error": error })
                    Sentry.captureException(error)
                }).finally(() => {
                    loading.value = false
                    if (formId) {
                        formStatusStore.removeLoading(formId)
                    }
                })
        });
    }

    async function storeContact(client, contact, formId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }

        return await new Promise((resolve, reject) => {
            axios.post('/api/v1/clients/' + client.id + '/contacts', contact)
                .then(function (response) {
                    if (response.status === 201) {
                        const redirectUrl = response.headers.location;
                        return axios.get(redirectUrl);
                    }
                }).then(function (response) {
                    resolve(response)
                }).catch(function (error) {
                    reject({ "error": error })
                    Sentry.captureException(error)
                }).finally(() => {
                    loading.value = false
                    if (formId) {
                        formStatusStore.removeLoading(formId)
                    }
                })
        });
    }

    async function updateContact(client, contact, formId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }

        return await new Promise((resolve, reject) => {
            patch(`/api/v1/clients/${client.id}/contacts/${contact.id}`, contact)
               .then(function (response) {
                    resolve(response)
                }).catch(function (error) {
                    reject({ "error": error })
                    Sentry.captureException(error)
                }).finally(() => {
                    loading.value = false
                    if (formId) {
                        formStatusStore.removeLoading(formId)
                    }
                })
        });
    }

    async function deleteContact(client, contact) {
        loading.value = true
        return await new Promise((resolve, reject) => {
            axios.delete(`/api/v1/clients/${client.id}/contacts/${contact.id}`, contact)
               .then(function (response) {
                    resolve(response)
                }).catch(function (error) {
                    reject({ "error": error })
                    Sentry.captureException(error)
                }).finally(() => {
                    loading.value = false
                })
        });
    }

    function applyLocalChangeToActiveClient(change) {
        const noApplyFields = ['contacts']
        for (const attr in change) {
            if (noApplyFields.indexOf(attr) == -1) {
                client.value[attr] = change[attr]
            } else if (attr == 'contacts') {
                for (const i in change['contacts']) {
                    const index = client.value['contacts'].findIndex(item => item.uuid == change['contacts'][i].uuid)
                    if (index != -1) {
                        client.value['contacts'][index] = deepCopy(change['contacts'][i])
                    } else {
                        client.value['contacts'].push(deepCopy(change['contacts'][i]))
                    }
                }
            }
        }
    }

    function extractContact(proxyContact) {
        const getTypeIdOrString = (type) => {
            return typeof type === 'object' && type !== null ? type.id : type;
        };

        const obj =  {
            ...proxyContact,
            type: proxyContact.type ? getTypeIdOrString(proxyContact.type) : null,
            profession: proxyContact.profession ? getTypeIdOrString(proxyContact.profession) : null,
            state: proxyContact.state ? getTypeIdOrString(proxyContact.state) : null,
            gender: proxyContact.gender ? getTypeIdOrString(proxyContact.gender) : null,
        };

        obj['emails'] = toRaw(proxyContact.emails);
        obj['phones'] = toRaw(proxyContact.phones);

        return obj;
    }

    function extractClient(proxyClient) {
        return {
            ...proxyClient,
            contacts: proxyClient.contacts ? proxyClient.contacts.map(extractContact) : [],
        }
    }

    watch(client, function(newValue, oldValue) {
        if (newValue.id == null) {
            return
        }

        let title = ''

        if (newValue.first_name) {
            title += ` ${newValue.first_name}`
        }

        if (newValue.last_name) {
            title += ` ${newValue.last_name}`
        }

        if (title == '') {
            title = `Unnamed Client ${newValue.id} - LifeWorx Latte`
        } else {
            title = `${title} - Client ${newValue.id} - LifeWorx Latte`
        }

        document.title = title

    }, { immediate: true, deep: true})

    function isPrimaryContactAddNewEnable() {
        return enableAddNewPrimaryContactMode.value
    }

    function changeAddNewPrimaryContactMode(status) {
        enableAddNewPrimaryContactMode.value = status
    }

    return {
        defaultClient,
        hydrate, hydrateContact, getClientByCoffeeUserProfileId, getClientById,
        formatRequest, storeClient, updateClient,
        storeContact, updateContact, deleteContact,
        client, isEdit, lastUpdated, loading,
        clientHasPhoneNumber,
        applyLocalChangeToActiveClient,
        extractContact, extractClient,
        stopInactivityTimeout, resetInactivityRefreshTimer,
        getMaxRetryDialogId, setMaxRetryDialogId, removeMaxRetryDialogId,
        isPrimaryContactAddNewEnable, changeAddNewPrimaryContactMode,
    }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useClientSingleStore, import.meta.hot))
}
