import { useJobProcessQueue } from "./jobProcessQueue"
import { useJobRepo } from "./jobRepo"
import { usePubSub } from "./pubSub"

const maxRetry = 20
const changeWindow = 1500
const timeBetweenRetry = 15000

export function useJobRunner(resource) {
    let timer = null
    let retryTimer = null

    let retryTime = 0
    const queue = {}
    const jobRepo = useJobRepo()
    const retryJobRepo = useJobRepo()
    const publisher = usePubSub([
        'addedRetryJob',
        'mergedRetryJob',
        'startedRetryJob',
        'finishedJobSuccess',
        'reachedMaxRetry',
        'startedJob',
        'addedJob'
    ])

    function computeFinalResource(identityResource, action) {
        return `${identityResource}_${action}`
    }

    function computeIdentityResource(identity) {
        return identity ? `${resource}_${identity}` : resource
    }

    function addJobWithTime(action, identity, requestData, additionalData, handle, time) {
        const identityResource = computeIdentityResource(identity)

        retryTime = 0
        publisher.publish('addedJob')

        let finalRequestData = requestData
        if (retryJobRepo.doesJobExistForResource(identityResource, action)) {
            const job = retryJobRepo.removeJob(identityResource, action)
            finalRequestData = {...job.request_data, ...finalRequestData}

            publisher.publish('mergedRetryJob')
        }

        jobRepo.addJob(identityResource, action, finalRequestData, additionalData, handle)
        clearTimeout(timer)
        startTimer(jobRepo, action, identity, time)
    }

    function addJob(action, identity, requestData, additionalData, handle) {
        addJobWithTime(action, identity, requestData, additionalData, handle, changeWindow)
    }

    function addAndRunJob(action, identity, requestData, additionalData, handle) {
        addJobWithTime(action, identity, requestData, additionalData, handle, 0)
    }

    function hasScheduleJob(action, identity) {
        const identityResource = computeIdentityResource(identity)
        return jobRepo.doesJobExistForResource(identityResource, action)
    }

    function startTimer(repo, action, identity, time, jobStartAction) {
        timer = setTimeout(() => {
            const jobId = crypto.randomUUID()

            const identityResource = computeIdentityResource(identity)
            const job = repo.retrieveJob(identityResource, action)

            if (job) {
                job['handle'](job['request_data'], ...job['additional_data'], jobId)
                if (typeof jobStartAction == 'function') {
                    jobStartAction()
                }
                publisher.publish('startedJob')
            }

            const finalResource = computeFinalResource(identityResource, action)
            if (!queue[finalResource]) {
                queue[finalResource] = useJobProcessQueue(finalResource)
            }
            job.id = jobId
            queue[finalResource].push(job)
        }, time)
    }

    function handleJobSuccess(action, identity, jobId) {
        const identityResource = computeIdentityResource(identity)
        const finalResource = computeFinalResource(identityResource, action)

        queue[finalResource].markFinish(jobId)

        retryTime = 0

        publisher.publish('finishedJobSuccess')
    }

    function handleJobError(action, identity, jobId, errorCode) {
        const identityResource = computeIdentityResource(identity)
        const finalResource = computeFinalResource(identityResource, action)

        const unSavedData = queue[finalResource].computeUnSavedData(jobId)
        queue[finalResource].markFinish(jobId)

        if (Object.keys(unSavedData).length == 0) {
            return
        }

        if (hasScheduleJob(action, identity) == true) {
            const job = jobRepo.getJob(identityResource, action)
            const finalRequestData = {...unSavedData, ...job['request_data']}
            jobRepo.replaceJobData(identityResource, action, finalRequestData)
            return
        }

        const job = queue[finalResource].get(jobId)
        addRetryJob(action, identity, unSavedData, job['additional_data'], job['handle'], errorCode)
    }

    function addRetryJob(action, identity, requestData, additionalData, handle, errorCode) {
        if (retryTime == maxRetry) {
            publisher.publish('reachedMaxRetry')
            return
        }

        const identityResource = computeIdentityResource(identity)
        retryJobRepo.addJob(identityResource, action, requestData, additionalData, handle)

        clearTimeout(retryTimer)
        startTimer(retryJobRepo, action, identity, timeBetweenRetry, function() {
            retryTime += 1
            publisher.publish('startedRetryJob')
        })

        publisher.publish('addedRetryJob', [errorCode, retryTime + 1])
    }

    function removeJob(action, identity) {
        const identityResource = computeIdentityResource(identity)
        return jobRepo.removeJob(identityResource, action)
    }

    function subscribe(event, callback) {
        return publisher.subscribe(event, callback)
    }

    function unsubscribe(event, id) {
        return publisher.unsubscribe(event, id)
    }

    window.addEventListener('beforeunload', function (e) {
        if (jobRepo.isEmpty() == false || retryJobRepo.isEmpty() == false) {
            e.preventDefault();
            return 'Are you sure you want to leave?';
        }
    })

    return {
        addJob, addAndRunJob, hasScheduleJob, removeJob,
        handleJobSuccess, handleJobError,
        subscribe, unsubscribe
    }
}

