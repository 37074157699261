import axios from 'axios'

const UNAUTHORIZED = 401

window.axios = axios

// window.axios.defaults.baseURL = import.meta.env.VITE_BASE_URL
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.headers.common['Content-Type'] = 'application/json'
window.axios.defaults.headers.common['Accept'] = 'application/json'
window.axios.defaults.xsrfCookieName = 'LATTE-XSRF-TOKEN'
// window.axios.defaults.withCredentials = true
window.axios.defaults.timeout = import.meta.env.VITE_AXIOS_TIMEOUT ? import.meta.env.VITE_AXIOS_TIMEOUT : 30000


// Handle 401 unauthorized requests to API (usually expired sessions)
axios.interceptors.response.use(
    response => response,
    error => {
        console.log('error intercept:', error)
        if (error.response) {
            const { status: status = null } = error.response
            if (status === UNAUTHORIZED) {
                localStorage.setItem('AUTH_SESSION_EXPIRED', 1)
                window.location.href = '/login'
            }
        }
      return Promise.reject(error);
   }
)


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
//     wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });
