<script setup>
    import { onBeforeUnmount, onMounted, ref, computed, watch } from 'vue';
    import { util } from '@/Helpers';

    import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, UserPlusIcon } from '@heroicons/vue/20/solid'
    import flatpickr from 'flatpickr';
    import 'flatpickr/dist/flatpickr.css';
    import weekSelectPlugin from "flatpickr/dist/plugins/weekSelect/weekSelect";

    const props = defineProps({
        modelValue: {
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    })

    const emit = defineEmits(['update:modelValue']);
    const datepicker = ref(null);
    let picker;

    const onChange = (selectedDates) => {
        emit('update:modelValue', util.date.lastSunday(util.date.toString(selectedDates[0])))
    };

    const openDatePicker = () => {
        datepicker.value.focus();
        picker.open();
    };

    const handleNextWeek = () => {
        if (!props.disabled) {
            emit('update:modelValue', util.date.nextDate(props.modelValue, 7))
        }
    }

    const handlePreviousWeek = () => {
        if (!props.disabled) {
            emit('update:modelValue', util.date.nextDate(props.modelValue, -7))
        }
    }

    onMounted(() => {
        picker = flatpickr(datepicker.value, {
            plugins: [weekSelectPlugin({})],
            defaultDate: props.modelValue,
            onChange,
        });
    });

    onBeforeUnmount(() => {
        if (picker) {
            picker.destroy();
        }
    });

    watch(() => props.modelValue, (newValue) => {
        picker.setDate(newValue, false);
    })

    const summary = computed(() => {
        return util.date.getWeekText(props.modelValue)
    })
</script>

<template>

    <div class="flex justify-between bg-white rounded w-64 h-7">
        <div class="cursor-pointer border-r" @click="handlePreviousWeek">
            <ChevronLeftIcon class="block h-7 w-5 pr-1" aria-hidden="true"/>
        </div>
        <div class="flex h-7" @click="openDatePicker">
            <input class="w-0 p-0 m-0 border-0" type="hidden" ref="datepicker" :disabled="props.disabled"/>
            <span class="text-sm">{{ summary }}</span><ChevronDownIcon class="block h-7 w-5 pr-1" aria-hidden="true"/>
        </div>
        <div class="cursor-pointer border-l" @click="handleNextWeek">
            <ChevronRightIcon class="block h-7 w-5 pr-1" aria-hidden="true"/>
        </div>
    </div>

</template>
