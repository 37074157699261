import { deepCopy } from "./util"

export function useJobProcessQueue(resource) {
    let queue = []

    function push(job) {
        queue.push(job)
        clearOldJobCountDown()
    }

    function get(jobId) {
        const index = queue.findIndex(item => item.id === jobId)

        return index == -1 ? null : queue[index]
    }

    function markFinish(jobId) {
        const index = queue.findIndex(item => item.id === jobId)
        queue[index]['finish'] = true
    }

    function computeUnSavedData(jobId) {
        const index = queue.findIndex(item => item.id === jobId)

        if (index == -1) {
            return {}
        }

        let unSavedData = deepCopy(queue[index]['request_data'])
        for (let i = index + 1; i < queue.length; i++) {
            unSavedData = extractUnSavedData(unSavedData, queue[i]['request_data'])
        }
        return unSavedData
    }

    function extractUnSavedData(oldData, newData) {
        const diff = {}
        const oldFields = Object.keys(oldData)
        const oldDataCopy = deepCopy(oldData)

        for (const i in oldFields) {
            if (!newData[i]) {
                diff[i] = oldDataCopy[i]
            }
        }

        return diff
    }

    function clearOldJobCountDown() {
        setTimeout(function() {
            let stopIndex = 0
            for (let i in queue) {
                if (queue[i]['finish'] == true) {
                    stopIndex = i
                } else {
                    break
                }
            }
            queue = queue.filter((item, index) => index >= stopIndex)
        }, 5000)
    }

    return {
        push, get, computeUnSavedData, markFinish
    }
}

