import { computed, reactive, ref } from 'vue';

import { acceptHMRUpdate, defineStore } from 'pinia';
import { useFormStatusStore } from '@/Stores'

import * as Sentry from "@sentry/vue";
import { useNProgress } from '@vueuse/integrations/useNProgress';
import { get } from '@/Helpers/takeLatestResponseRequest';

export const useClientShiftStore = defineStore('clientShift', () => {
    const formStatusStore = useFormStatusStore()

    const { isLoading } = useNProgress()
    const loading = ref(isLoading)

    const shiftDict = reactive({})

    function replaceLocalStoreClientShifts(client_id, shifts) {
        shiftDict[client_id] = shifts
    }

    function addClientShiftsToLocalStore(client_id, shifts) {
        if (!shiftDict[client_id]) {
            shiftDict[client_id] = []
        }
        shiftDict[client_id] = shiftDict[client_id].concat(shifts)
    }

    function addOrReplaceClientShiftInLocalStore(client_id, shifts) {
        if (!shiftDict[client_id]) {
            shiftDict[client_id] = []
            shiftDict[client_id] = shiftDict[client_id].concat(shifts)
            return
        }

        const existingShiftsMap = {};
        shiftDict[client_id].forEach(shift => {
            existingShiftsMap[shift.id] = shift;
        });

        shifts.forEach(newShift => {
            existingShiftsMap[newShift.id] = newShift;
        });

        shiftDict[client_id] = Object.values(existingShiftsMap);
    }

    function computedClientShift(client_id) {
        return computed(() => shiftDict[client_id] ? shiftDict[client_id] : [])
    }

    function computedClientShiftMap(client_id) {
        return computed(() => {
            const map = {}

            if (shiftDict[client_id]) {
                shiftDict[client_id].forEach((shift, index) => {
                    map[shift.id] = index
                })
            }

            return map
        })
    }

    async function retrieveShifts(client_id, lowerBoundDate, upperBoundDate, formId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }

        return await new Promise((resolve, reject) => {
            get(
                `/api/v1/clients/${client_id}/shifts`,
                {
                    params: {
                        start_date: lowerBoundDate,
                        end_date: upperBoundDate
                    }
                },
            ).then(response => {
                resolve(response)
            }).catch((err) => {
                reject({ "error": err })
            }).finally(() => {
                loading.value = false
                if (formId) {
                    formStatusStore.removeLoading(formId)
                }
            })
        });
    }

    async function storeShift(client, shifts, formId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }

        return await new Promise((resolve, reject) => {
            axios.post('/api/v1/clients/' + client.id + '/shifts', shifts)
                .then(function (response) {
                    resolve(response)
                }).catch(function (error) {
                    reject({ "error": error })
                    Sentry.captureException(error)
                }).finally(() => {
                    loading.value = false
                    if (formId) {
                        formStatusStore.removeLoading(formId)
                    }
                })
        });
    }

    async function updateShifts(client_id, shiftChanges, start_date, end_date, formId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }

        const data = {
            shifts: shiftChanges
        }
        if (start_date) {
            data['start_date'] = start_date
        }
        if (end_date) {
            data['end_date'] = end_date
        }

        return await new Promise((resolve, reject) => {
            axios.patch('/api/v1/clients/' + client_id + '/shifts', data).then(function (response) {
                resolve(response)
            }).catch(function (error) {
                reject({ "error": error })
                Sentry.captureException(error)
            }).finally(() => {
                loading.value = false
                if (formId) {
                    formStatusStore.removeLoading(formId)
                }
            })
        });
    }

    async function deleteShifts(client_id, shift_ids, start_date, end_date, formId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }

        const params = {}

        if (shift_ids) {
            params['shift_ids'] = shift_ids.join(',')
        }
        if (start_date) {
            params['start_date'] = start_date
        }
        if (end_date) {
            params['end_date'] = end_date
        }

        return await new Promise((resolve, reject) => {
            axios.delete(`/api/v1/clients/${client_id}/shifts`, {
                params: params
            }).then(function (response) {
                resolve(response)
            }).catch(function (error) {
                reject({ "error": error })
                Sentry.captureException(error)
            }).finally(() => {
                loading.value = false
                if (formId) {
                    formStatusStore.removeLoading(formId)
                }
            })
        });
    }

    return {
        storeShift, retrieveShifts, replaceLocalStoreClientShifts, addClientShiftsToLocalStore,
        updateShifts, addOrReplaceClientShiftInLocalStore,
        deleteShifts,
        computedClientShift, computedClientShiftMap
    }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useClientShiftStore, import.meta.hot))
}
