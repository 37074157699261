    import { computed, ref } from 'vue'

    import { defineStore, acceptHMRUpdate } from 'pinia'

    export const useModuleLockStore = defineStore('moduleLock', () => {

        const lock = ref({})

        function computedModule(moduleId) {
            return computed(() => lock.value[moduleId])
        }

        function enable(moduleId) {
            lock.value[moduleId] = true
        }

        function disable(moduleId) {
            lock.value[moduleId] = false
        }

        return {
            computedModule,
            enable, disable
        }

    })

    if (import.meta.hot) {
        import.meta.hot.accept(acceptHMRUpdate(useModuleLockStore, import.meta.hot))
    }
