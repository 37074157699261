<script setup>

    import { onMounted, onUnmounted, ref } from 'vue'

    import { useClientStore, useMapStore, useViewStore } from '@/Stores'

    import { storeToRefs } from 'pinia'

    import LatteContainer from '@/Components/App/LatteContainer.vue'
    import LatteWhosNearby from '@/Components/Maps/LatteWhosNearby.vue'
    import Loader from '@/Components/App/Loader.vue'

    import { util } from '@/Helpers'

    const mapStore = useMapStore()
    const clientStore = useClientStore()
    const viewStore = useViewStore()

    const { nearbyClients, nearbyError, areaSummary, mapCenter, mapCenterCache, loading } = storeToRefs(mapStore)
    const { isEmbedded } = storeToRefs(viewStore)

    let address, coffee_user_profile_id

    function receiveMessage(event) {

        const ignoredSources = [
            'vue-devtools-proxy',
            'vue-devtools-backend',
            'vue-devtools-backend-injection',
            '_vue-devtools-send-message'
        ]

        if (ignoredSources.includes(event.data.source) || ignoredSources.includes(event.data.key) || event.data.message?.vueDetected == true) {
            return false;
        }

        console.log('message received:', event)

        address = event.data

        coffee_user_profile_id = util.getQueryParam('exclude_client_id')

        loading.value = true
        clientStore.retrieveNearbyClients(address, coffee_user_profile_id).then(response => {

            nearbyError.value = null
            console.log('Rehydrating from API', { data: response.data })
            clientStore.hydrateFromNearby(response.data.clients)

            areaSummary.value = response.data.areaSummary

            nearbyClients.value = response.data.clients
            mapCenter.value = {
                address: util.objectifyAddressString(address),
                geo: {
                    lat: parseFloat(response.data.center.geo.lat),
                    lng: parseFloat(response.data.center.geo.lng)
                }
            }

            mapCenterCache.value = mapCenter.value

            loading.value = false

        }).catch((err) => {

            mapCenter.value = {
                address: util.objectifyAddressString(address),
                geo: {
                    lat: 0,
                    lng: 0
                }
            }

            mapCenterCache.value = mapCenter.value

            loading.value = false
            console.error('catching err mapnearby:', err)
            nearbyError.value = err.error

        })

    }

    onMounted(async () => {

        if (isEmbedded.value) {
            window.addEventListener('message', receiveMessage, false)
        }

    })

    onUnmounted(() => {

        if (isEmbedded.value) {
            window.removeEventListener('message', receiveMessage, false)
        }

        mapStore.purge()

    })

</script>

<template>

    <div
        class="mx-auto max-w-7xl"
        :class="{
            'pt-0': isEmbedded,
            'sm:pt-4 md:pt-6 md:px-8': !isEmbedded
        }"
    >

        <div class="mx-auto max-w-5xl">

            <LatteContainer
                id="latte-map-nearby"
                title="Who&apos;s Nearby"
                :forceSingleLineMobileLayout="true"
            >

                <LatteWhosNearby />

            </LatteContainer>

        </div>

    </div>

</template>
