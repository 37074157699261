<script setup>

    import { computed, ref } from 'vue'
    import { storeToRefs } from 'pinia'

    import { useStaffStore, useClientStore, useNotificationStore } from '@/Stores'

    import LatteRatingBar from '@/Components/Feedback/LatteRatingBar.vue'
    import LatteStaffLabel from '@/Components/LatteStaffLabel.vue'
    import LatteFeedbackActionsComms from '@/Components/Feedback/LatteFeedbackActionsComms.vue'

    import { CheckIcon } from '@heroicons/vue/20/solid'
    import { ArrowRightCircleIcon } from '@heroicons/vue/24/outline'

    const staffStore = useStaffStore()
    const { staffs } = storeToRefs(staffStore)

    const clientStore = useClientStore()
    const { clients } = storeToRefs(clientStore)

    const notificationStore = useNotificationStore()

    const props = defineProps({
        client: {
            type: Object
        },
        jobs: {
            type: Object
        }
    })

    const clientRef = ref(clients.value[props.client.id])

    const clientCoffeeBaseUrl = import.meta.env.VITE_COFFEE_BASEURL + '/staff/sales-manager/information/pid/'


    const isRated = computed(() => {

        let value = false;

        Object.values(props.jobs).forEach((job, index) => {
            if (job.ratings.length > 0) {
                value = true;
            }
        })

        return value;

    })

    const isCompleted = computed(() => {

        let value = true;

        Object.values(props.jobs).forEach((job, index) => {
            if (job.ratings.length == 0) {
                value = false;
            }
        })

        return value;

    })


</script>

<template>

    <div class="grid grid-cols-16 bg-gradient-to-t from-zinc-200 to-zinc-50 py-3 border-b-zinc-400">

        <div class="col-span-1 pl-6 md:pl-4 flex items-center justify-center">

            <div
                class="flex-shrink-0 rounded-full h-8 w-8 border-2 z-40 border-opacity-100 flex justify-center items-center"
                :class="{
                    'border-zinc-300': !isCompleted,
                    'border-lifeworx-blue-500 bg-lifeworx-blue-500': isRated && isCompleted,
                    'bg-zinc-100': !isCompleted
                }"
            >
                <CheckIcon
                    v-if="isRated && !isCompleted"
                    class="inline-flex h-4 w-4 text-lifeworx-blue-600 stroke-1 stroke-lifeworx-blue-600 opacity-30"
                />
                <CheckIcon
                    v-if="isCompleted"
                    class="inline-flex h-4 w-4 text-white stroke-1 stroke-white"
                />
            </div>

        </div>

        <div class="col-span-12 md:col-span-5 pl-8 md:pl-4 flex items-center">

            <div class="w-full grid grid-rows-3 gap-0 py-1">

                <h4 class="tracking-wide text-lifeworx-blue-700 font-semibold text-sm whitespace-nowrap">
                    <a
                        :href="clientCoffeeBaseUrl + client.coffee_user_profile_id"
                        target="_blank"
                        class="hover:underline"
                    >
                        {{ client.first_name + ' ' + client.last_name }}
                    </a>
                </h4>

                <div class="flex h-6">
                    <div class="flex-grow">
                        <LatteStaffLabel
                            :staff="staffs[client.assigned_sales_manager.id]"
                        />
                    </div>
                </div>
                <div class="w-48 py-0.5">
                    <LatteRatingBar
                        :subject="client"
                        subjectType="client"
                    />
                </div>

            </div>


        </div>

        <div class="hidden md:flex md:col-span-7" />

        <LatteFeedbackActionsComms
            class="hidden md:flex"
            :subject="client"
            subjectType="client"
        />


        <div class="flex md:hidden justify-end items-center">
            <button
                class="relative left-8 w-10 h-10 text-lifeworx-blue-600"
                @click="notificationStore.showActionModal(client, 'client')"
            >
                <ArrowRightCircleIcon class="w-7 h-7" />
            </button>
        </div>

    </div>

</template>
