<script setup>
    import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
    import flatpickr from 'flatpickr';
    import 'flatpickr/dist/flatpickr.css';
    import { util } from '@/Helpers';

    const props = defineProps({
        modelValue: {
            required: true
        },
        value: {
            type: String,
            default: null,
        },
        minDate: {
            type: [String, null],
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    });

    const emit = defineEmits(['update:modelValue']);
    const datepicker = ref(null);
    let picker;

    const onChange = (selectedDates) => {
        emit('update:modelValue', util.date.toString(selectedDates[0]))
    };

    onMounted(() => {
        picker = flatpickr(datepicker.value, {
            defaultDate: props.value,
            minDate: props.minDate,
            onChange,
        });
    });

    watch(() => props.value, (newValue) => {
        picker.setDate(newValue, false);
    });

    watch(() => props.minDate, (newValue) => {
        picker.set('minDate', newValue);
    });

    onBeforeUnmount(() => {
        if (picker) {
            picker.destroy();
        }
    });

</script>

<template>
    <div>
        <input :disabled="props.disabled" class="w-full" ref="datepicker" />
    </div>
</template>
