import { ref } from 'vue'

import { defineStore, acceptHMRUpdate } from 'pinia'

import { util } from '@/Helpers'

import moment from 'moment'

export const useStaffStore = defineStore('staff', () => {

    const allStaffs = ref([])
    const staffs = ref({})

    const lastUpdated = ref(null)

    function defaultStaff() {
        return {
            id: null,
            coffee_auth_user_id: null,
            email: null,
            first_name: '',
            last_name: '',
            phone_numbers: [],
        }
    }

    //
    // Hydration
    //

    async function hydrateFromJobs(jobsData) {

        staffs.value = Object.assign(staffs.value, util.convertArrayToObject(jobsData.map(job => job.client.assigned_sales_manager), 'id'))

        staffs.value = Object.assign(staffs.value, util.convertArrayToObject(jobsData.map(job => job.caregiver.assigned_recruiter), 'id'))

        // Reshape phone numbers
        Object.values(staffs.value).forEach(staff => {
            if (Array.isArray(staff.phone_numbers)) {
                staff.phone_numbers = staff.phone_numbers ? util.convertArrayToObject(staff.phone_numbers, 'type') : { work: { number: '5555555555' } }
            }
        })

        lastUpdated.value = moment.utc()

    }

    async function hydrateAllStaffs(data) {
        allStaffs.value = data
    }

    async function getAllStaffs() {
        return await new Promise((resolve, reject) => {
            axios.get(
                `/api/v1/staffs`
            ).then(response => {
                resolve(response)
            }).catch((err) => {
                authStore.handleError(err)
                reject({ "error": err })
            })
        });
    }

    function getStaffById(id) {
        const staff = allStaffs.value.find(staff => staff.id === id);

        if (!staff) {
            console.warn(`Staff with ID ${id} not found.`);
        }

        return staff;
    }

    return {
        defaultStaff,
        hydrateFromJobs, hydrateAllStaffs, getAllStaffs, getStaffById,
        staffs, allStaffs, lastUpdated
    }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStaffStore, import.meta.hot))
}
