<script setup>

    import { deepCopy, util } from '@/Helpers';
    import moment from 'moment';
    import { ref, onMounted, computed, watch } from 'vue'

    import { useClientShiftStore, useFormStatusStore } from '@/Stores';

    const clientShiftStore = useClientShiftStore()
    const formStatus = useFormStatusStore()

    const emit = defineEmits(['shifts:selected'])

    const container = ref(null)
    const containerNav = ref(null)
    const containerOffset = ref(null)

    const props = defineProps({
        client_id : {
            type: Number,
            required: true
        },
        start_date: {
            type: String,
            required: true
        },
        planned_shifts: {
            type: Array,
            required: false,
            default: []
        },
        selected_shift_ids: {
            type: Array,
            required: true
        }
    })

    const selectedIds = ref([...props.selected_shift_ids])

    let clientShifts
    let clientShiftMap
    let blocks
    let selectedCondition

    function toggleSelect(id) {
        if (selectedIds.value.includes(id)) {
            selectedIds.value = selectedIds.value.filter(item => item != id)
        } else {
            selectedIds.value.push(id)
        }

        emit('shifts:selected', selectedIds.value)
    }

    onMounted(() => {
      // Set the container scroll position based on the current time.
      const currentMinute = new Date().getHours() * 60
      container.value.scrollTop =
        ((container.value.scrollHeight - containerNav.value.offsetHeight - containerOffset.value.offsetHeight) *
          currentMinute) /
        1440
    })

    const next7Dates = computed(() => {
        return util.date.generateNext7MomentDays(props.start_date)
    })

    const isCurrentOrFutureWeek = computed(() => {
        const lastSunday = moment(util.date.lastSunday(), util.date.date_format)
        const startDate = moment(props.start_date, util.date.date_format)

        return startDate.isSameOrAfter(lastSunday)
    })

    const timeEntries = ['12AM']
    for (let i = 1; i < 12; i++) {
        timeEntries.push(`${i}AM`)
    }
    timeEntries.push('12PM')
    for (let i = 1; i < 12; i++) {
        timeEntries.push(`${i}PM`)
    }

    function stayTypeText(block) {
        return block.end_date ? 'Temp' : 'Ongoing'
    }

    function shiftToBlock(shift) {
        const block = {...shift, 'start_date': util.schedule.getWeekSpecificDate(props.start_date, shift.day_of_week)}
        delete block['day_of_week']

        if (util.schedule.is2DaysTime(shift.start_time, shift.duration)) {
            const dayRemainMinute = util.date.getDayRemainMinute(shift.start_time)
            const data = [{
                ...block,
                duration: dayRemainMinute
            }]

            if (!util.date.isDayOfWeek(shift.start_time, 'saturday')) {
                data.push({
                    ...block,
                    start_time: '00:00:00',
                    start_date: util.date.nextDate(block.start_date),
                    duration: shift.duration - dayRemainMinute,
                })
            }
            return data
        }
        return [block]
    }

    watch(() => props.selected_shift_ids, (newValue) => {
        selectedIds.value = [...newValue]
    })

    function canBeSelected(block) {
        if (!block.id) {
            return false
        }

        if (!selectedCondition.value) {
            return true
        }

        if (selectedCondition.value.stay_type != block.stay_type) {
            return false
        }

        if (selectedCondition.value.duration_type == 'ongoing' && block.end_date) {
            return false
        }

        if (selectedCondition.value.duration_type == 'temp' && !block.end_date) {
            return false
        }

        return true
    }

    watch(() => props.client_id, (newValue) => {
        clientShifts = clientShiftStore.computedClientShift(newValue)
        clientShiftMap = clientShiftStore.computedClientShiftMap(newValue)

        blocks = computed(() => {
            const clientShiftsClone = deepCopy(clientShifts.value)

            props.planned_shifts.forEach(shift => {
                if (shift.id) {
                    const index = clientShiftsClone.findIndex(item => item.id == shift.id)
                    clientShiftsClone[index] = shift
                }
            })

            let data = []
            clientShiftsClone.forEach(shift => {
                if (util.schedule.isDateOverlap(props.start_date, shift.day_of_week, shift.start_date, shift.end_date)) {
                    data = data.concat(shiftToBlock(shift))
                }
            })

            props.planned_shifts.forEach(shift => {
                if (!shift.id && util.schedule.isDateOverlap(props.start_date, shift.day_of_week, shift.start_date, shift.end_date)) {
                    data = data.concat(shiftToBlock(shift))
                }
            })


            return data
        })

        selectedCondition = computed(() => {
            if (selectedIds.value.length == 0) {
                return null
            }

            const firstSelectedShift = clientShifts.value[clientShiftMap.value[selectedIds.value[0]]]

            return {
                stay_type: firstSelectedShift.stay_type,
                duration_type: firstSelectedShift.end_date ? 'temp' : 'ongoing'
            }
        })
    }, {immediate: true})
</script>
<template>
    <div class="border-t flex h-full flex-col">
        <div ref="container" class="isolate flex flex-auto flex-col overflow-auto bg-white">
            <div style="width: 165%" class="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
                <div ref="containerNav" class="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
                    <div class="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
                    <div class="col-end-1 w-14" />
                    <div v-for="date in next7Dates" class="flex items-center justify-center py-3">
                        <span>{{ date.format('dddd') }} <span class="items-center justify-center font-semibold text-gray-900"> {{ date.format('MM/DD') }}</span></span>
                    </div>
                    <!--
                    <div class="flex items-center justify-center py-3">
                        <span class="flex items-baseline">Wed <span class="ml-1.5 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white">12</span></span>
                    </div>
                    -->
                    </div>
                </div>
                <div class="flex flex-auto">
                    <div class="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
                    <div class="grid flex-auto grid-cols-1 grid-rows-1">
                    <!-- Horizontal lines -->
                    <div class="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100" style="grid-template-rows: repeat(24, minmax(2rem, 1fr))">

                        <div ref="containerOffset" class="row-end-1 h-7" />
                        <template v-for="time in timeEntries">
                            <div>
                                <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">{{ time }}</div>
                            </div>
                        </template>
                    </div>

                    <!-- Vertical lines -->
                    <div class="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
                        <div class="col-start-1 row-span-full" />
                        <div class="col-start-2 row-span-full" />
                        <div class="col-start-3 row-span-full" />
                        <div class="col-start-4 row-span-full" />
                        <div class="col-start-5 row-span-full" />
                        <div class="col-start-6 row-span-full" />
                        <div class="col-start-7 row-span-full" />
                        <div class="col-start-8 row-span-full w-8" />
                    </div>

                    <!-- Events -->
                    <ol class="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8" style="grid-template-rows: 1.875rem repeat(288, minmax(0, 1fr)) auto">
                        <li v-for="block in blocks"
                            class="relative flex"
                            :class="`col-start-${util.date.dateDiff(props.start_date, block.start_date) + 1}`"
                            :style="`grid-row: ${util.schedule.start(block.start_time)} / span ${util.schedule.span(block.duration)}`"
                        >
                            <span class="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-blue-50 p-2 text-xs hover:bg-blue-100">
                                <input
                                    class="cursor-pointer"
                                    type="checkbox" v-if="isCurrentOrFutureWeek && canBeSelected(block)"
                                    @click="() => toggleSelect(block.id)"
                                    :checked="selectedIds.includes(block.id)"
                                />
                                <p v-if="!block.id">Unsaved</p>
                                <p class="text-blue-500 group-hover:text-blue-700">
                                    <span>{{ util.date.to12CycleTime(block.start_time) }} - {{ util.date.to12CycleTime(util.date.timeAfterMinute(block.start_time, block.duration)) }}</span>
                                </p>
                                <p class="font-semibold text-blue-700">{{ stayTypeText(block) }}</p>
                                <p class="font-semibold text-blue-500 group-hover:text-blue-700">{{ block.stay_type == 'live_in' ? 'Live In' : 'Live Out'}}</p>
                                <p>{{ util.currencyWithoutFraction(block.charge_rate_amount) }}/{{ block.charge_duration_rate_type == 'hourly' ? 'HR' : 'day' }}</p>
                                <p>{{ block.assigned_caregiver.id ? `${block.assigned_caregiver.first_name} ${block.assigned_caregiver.last_name}` : 'Unassigned'}}</p>
                            </span>
                        </li>
                    </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
