<script setup>

    import { useRouter } from 'vue-router'

    import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

    import { EllipsisVerticalIcon, EnvelopeIcon, LinkIcon, PhoneArrowUpRightIcon, UserCircleIcon } from '@heroicons/vue/24/outline'
    import { ArrowTopRightOnSquareIcon, BookOpenIcon, MapIcon,  } from '@heroicons/vue/20/solid';

    import { util } from '@/Helpers'

    const router = useRouter()

    const props = defineProps({
        subject: {
            type: Object
        },
        subjectType: {
            type: String
        },
        isMobile: {
            type: Boolean,
            default: false
        }
    })

    var type = null

    const coffeeProfileUrl = props.subjectType == 'client' ? import.meta.env.VITE_COFFEE_BASEURL + '/staff/sales-manager/information/pid/' + props.subject.coffee_user_profile_id : import.meta.env.VITE_COFFEE_BASEURL + '/user/recruiter-candidates/personalinfo/id/'+ props.subject.coffee_job_profile_id

    if (props.subject.phone_numbers.cell) {
        type = 'cell'
    } else if (props.subject.phone_numbers.home) {
        type = 'home'
    } else if (props.subject.phone_numbers.work) {
        type = 'work'
    }

</script>

<template>

    <div class="col-span-3 space-x-3 lg:space-x-5 pl-5 lg:pl-10 flex items-center">

        <VTooltip
            v-if="subject.phone_numbers[type] && subject.phone_numbers[type] !== null && subject.phone_numbers[type] !== ''"
        >
            <a
                :href="'tel:' + subject.phone_numbers[type].country_code + subject.phone_numbers[type].number "
                class="hover:text-lifeworx-blue-500"
                ref="reference"
            >
                <PhoneArrowUpRightIcon
                    class="inline-flex h-6 w-6"
                />
            </a>
            <template #popper>
                <span class="text-sm">
                    Call {{ '+' + subject.phone_numbers[type].country_code + '-' + util.addDashesToPhone(subject.phone_numbers[type].number) }} ({{ type }})
                </span>
            </template>
        </VTooltip>

        <PhoneArrowUpRightIcon
            v-else
            class="inline-flex h-6 w-6 text-zinc-400 cursor-not-allowed"
        />

        <VTooltip
            v-if="(subject.emails?.length > 0 && subject.emails[0] !== '') || (subject.email && subject.email !== null && subject.email !== '')"
        >
            <a
                :href="'mailto:' + (subject.email ? subject.email : subject.emails[0])"
                class="hover:text-lifeworx-blue-500"
            >
                <EnvelopeIcon
                    class="inline-flex h-6 w-6"
                />
            </a>
            <template #popper>
                <span class="text-sm">
                    Email {{ subject.email ? subject.email : subject.emails[0] }}
                </span>
            </template>
        </VTooltip>
        <EnvelopeIcon
            v-else
            class="inline-flex h-6 w-6 text-zinc-400 cursor-not-allowed"
        />


        <Menu as="div" class="relative inline-block text-left">

            <div>
                <MenuButton
                    class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-2 py-2  shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-lifeworx-blue-500 hover:ring-gray-400 hover:bg-opacity-5"
                >
                    <EllipsisVerticalIcon class="h-5 w-5 text-lifeworx-blue-700" aria-hidden="true" />
                </MenuButton>
            </div>

            <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
                <MenuItems class="absolute right-0 z-popover mt-2 w-56 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-clip">

                    <div class="py-1 bg-gray-50">
                        <MenuItem>
                            <h5 class="group flex font-semibold items-center px-4 py-2 text-sm text-lifeworx-blue-700 cursor-default">
                                <UserCircleIcon class="-ml-0.5 mr-3 h-6 w-6 text-lifeworx-blue-700 flex-shrink-0" aria-hidden="true" />
                                {{ subject.first_name + ' ' + subject.last_name }}
                            </h5>
                        </MenuItem>
                    </div>

                    <div class="py-1">
                        <MenuItem v-slot="{ active }">
                            <a
                                :href="'/feedback/' + props.subjectType + '/' + subject.id"
                                @click.prevent
                            >
                                <button
                                    @click="router.push({ path: '/feedback/' + props.subjectType + '/' + subject.id })"
                                    :class="[
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'group flex items-center px-4 py-2 text-sm w-full'
                                    ]"
                                >
                                        <BookOpenIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        Feedback History
                                </button>
                            </a>
                        </MenuItem>

                        <MenuItem v-slot="{ active }">
                            <a
                                :href="coffeeProfileUrl"
                                target="_blank"
                                :class="[
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'group flex items-center px-4 py-2 text-sm capitalize'
                                ]"
                            >
                                <ArrowTopRightOnSquareIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                Coffee {{ props.subjectType }} Profile
                            </a>
                        </MenuItem>
                    </div>

                </MenuItems>
            </transition>
        </Menu>

    </div>

</template>
