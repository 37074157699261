<script setup>

    import { computed, ref, toRaw, watch } from 'vue'

    import { storeToRefs } from 'pinia'

    import { util, getModifiedProperties } from '@/Helpers'

    import Dropdown from '@/Components/App/Dropdown.vue'
    import AddressAutocomplete from '@/Components/Forms/AddressAutocomplete.vue'
    import InputLabel from '@/Components/Forms/InputLabel.vue'
    import LwxAutoComplete from '@/Components/Forms/LwxAutoComplete.vue'
    import LwxSelect from '@/Components/Forms/LwxSelect.vue'
    import LwxSwitch from '@/Components/Forms/LwxSwitch.vue'
    import PhoneNumber from '@/Components/Forms/PhoneNumber.vue'
    import TextInput from '@/Components/Forms/TextInput.vue'

    import { EnvelopeIcon, PhoneArrowUpRightIcon } from '@heroicons/vue/24/solid'

    import { useClientSingleStore, useConfigStore, useFormStatusStore } from '@/Stores'

    const configStore = useConfigStore()
    const { config } = storeToRefs(configStore)
    const formStatus = useFormStatusStore()
    const clientSingleStore = useClientSingleStore()
    const { client } = storeToRefs(clientSingleStore)

    const status = formStatus.computedFormStatus('contactForm')
    const formLoading = formStatus.computedFormLoading('contactForm')

    const defaultPhones = [
        {
            type: "cell",
            number: "",
            country_code: "1"
        },
        {
            type: "work",
            number: "",
            country_code: "1"
        },
        {
            type: "home",
            number: "",
            country_code: "1"
        }
    ]

    const props = defineProps({
        contact: {
            type: Object,
            default: {
                id: null,
                primary_contact: false,
                billing_contact: false,
                marketable_contact: false,
                comm_phone: false,
                comm_sms: false,
                comm_email: false,
                type: null,
                first_name: "",
                last_name: "",
                address: "",
                city: "",
                state: null,
                zip: "",
                profession: null,
                gender: "",
                company: "",
                note: "",
                emails: [],
                phones: [
                    {
                        type: "cell",
                        number: "",
                        country_code: "1"
                    },
                    {
                        type: "work",
                        number: "",
                        country_code: "1"
                    },
                    {
                        type: "home",
                        number: "",
                        country_code: "1"
                    }
                ]
            }
        }
    })

    const emit = defineEmits(['update'])

    const clientPropsChange = ref(false)
    const types = ref([
        {
            id: null,
            name: ""
        }
    ])

    const professions = ref([
        {
            id: null,
            name: ""
        }
    ])

    const states = ref([
        {
            id: null,
            name: ""
        }
    ])

    const genders = [
        {
            id: "male",
            name: "Male"
        },
        {
            id: "female",
            name: "Female"
        }
    ]

    const contact = ref(null)

    function handleChange(data) {
        emit('update', data, 'contactForm');
    }

    function getOptionName(id, array) {
        const index = array.findIndex(item => item.id == id);
        return index == -1 ? undefined :  array[index].name;
    }

    function combinePhoneArrays(arr1, arr2) {
        const phoneMap = new Map()

        arr1.concat(arr2).forEach(phone => {
            if (!phoneMap.has(phone.type)) {
                phoneMap.set(phone.type, {...phone});
            }
        })

        const combinedArray = Array.from(phoneMap.values())
        combinedArray.sort((a, b) => {
            const types = { cell: 1, work: 2, home: 3 }
            return types[a.type] - types[b.type];
        })

        return combinedArray;
    }

    function addressFill(place) {

        for (const component of place.address_components) {

            const componentType = component.types[0]

            switch (componentType) {
                case "street_number": {
                    contact.value.address = component.long_name
                    break;
                }
                case "route": {
                    contact.value.address += ' ' + component.long_name
                    break;
                }
                case "postal_code": {
                    contact.value.zip = component.long_name
                    break;
                }
                case "sublocality_level_1": {
                    contact.value.city = component.short_name
                }
                case "locality": {
                    contact.value.city = component.long_name
                    break;
                }
                case "administrative_area_level_1": {
                    contact.value.state = { id: component.short_name, name: component.long_name }
                    break;
                }
            }
        }

    }

    watch(config, (value) => {
        types.value = value.contact.types.map(item => ({
            id: item,
            name: item
        }))
        professions.value = value.contact.professions.map(item => ({
            id: item,
            name: item
        }))
        states.value = value.states;

    }, { deep: true, immediate : true })

    watch(client, (newValue, oldValue) => {
        if (oldValue === undefined || oldValue.id != newValue.id) {
            clientPropsChange.value = true
        }
    }, { deep: true, immediate : true })

    watch([() => props.contact, types, states], (newValue, oldValue) => {
        contact.value = {
            ...props.contact,
            gender: {id: props.contact.gender, name: getOptionName(props.contact.gender, genders)},
            phones: combinePhoneArrays(props.contact.phones, defaultPhones),
            emails: [...props.contact.emails],
            type: getOptionName(props.contact.type, types.value) ? {id: props.contact.type, name: getOptionName(props.contact.type, types.value)} : props.contact.type,
            profession: getOptionName(props.contact.profession, professions.value) ? {id: props.contact.profession, name: getOptionName(props.contact.profession, professions.value)} : props.contact.profession,
            state: getOptionName(props.contact.state, states.value) ? {id: props.contact.state, name: getOptionName(props.contact.state, states.value)} : props.contact.state,
        }
    }, { immediate: true, deep: true })

    watch(contact, (newValue, oldValue) => {
        if (oldValue === undefined) {
            clientPropsChange.value = false
            return;
        }

        let fields = Object.keys(newValue).filter(key => key != 'phones')
        fields.push({
            key: 'phones',
            sort: (a, b) => a.type.localeCompare(b.type)
        })

        const extractedContact = clientSingleStore.extractContact(newValue)
        if (newValue.id === null) {
            if (Object.keys(getModifiedProperties(JSON.stringify(extractedContact), JSON.stringify(props.contact), fields)).length > 0) {
                handleChange(extractedContact)
                return;
            }
        } else if (oldValue.id === newValue.id && clientPropsChange.value == false) {
            if (Object.keys(getModifiedProperties(JSON.stringify(extractedContact), JSON.stringify(props.contact), fields)).length > 0) {
                handleChange(extractedContact)
                return;
            }
        }
        clientPropsChange.value = false
    }, { immediate: true, deep: true })

    watch(() => contact.value.profession, (value) => {
        if (!value || !value.id) {
            return;
        }
        if (professions.value.findIndex(item => item.id == value.id) == -1) {
            professions.value.push(value)
        }
    }, { immediate : true })

    const phoneLinks = computed(() => {
        let links = []
        contact.value.phones?.forEach(phone => {
            if (!phone.number) { return; }
            links.push({
                label: 'Call +' + phone.country_code + '-' + util.addDashesToPhone(phone.number) + ' (' + phone.type + ')',
                href: 'tel:+' + phone.country_code + phone.number
            })
        })
        return links;
    })

    const emailLinks = computed(() => {
        let links = []
        contact.value.emails?.forEach(email => {
            if (!email || email == '') { return; }
            links.push({
                label: 'Email ' + email,
                href: 'mailto:' + email
            })
        })
        return links;
    })

</script>

<template>

    <div class="w-full px-5 py-4">

        <div class="flex flex-wrap -mx-3 mb-3">

            <div class="w-full md:w-2/5 px-3 mb-4 md:mb-0">

                <InputLabel
                    for="grid-type"
                    value="Type"
                    class="text-xs text-stone-400"
                >
                    <LwxSelect
                        id="grid-type"
                        v-model="contact.type"
                        :options="types"
                    />
                </InputLabel>

            </div>

            <div class="w-full md:w-1/5 px-3 mb-4 md:mb-0">
                <InputLabel
                    for="grid-primary-contact"
                    value="Primary Contact"
                    class="text-xs text-stone-400 w-fit"
                >
                    <LwxSwitch
                        id="grid-primary-contact"
                        v-model="contact.primary_contact"
                        class="mt-2"
                    />
                </InputLabel>
            </div>

            <div class="w-full md:w-1/5 px-3 mb-4 md:mb-0">
                <InputLabel
                    for="grid-billing-contact"
                    value="Billing Contact"
                    class="text-xs text-stone-400 w-fit"
                >
                    <LwxSwitch
                        id="grid-billing-contact"
                        v-model="contact.billing_contact"
                        class="mt-2"
                    />
                </InputLabel>
            </div>

            <div class="w-full md:w-1/5 px-3 mb-4 md:mb-0 grid grid-cols-2">

                <div class="mt-2">

                    <Dropdown
                        v-if="contact?.phones && contact.phones[0].number || contact.phones[1].number || contact.phones[2].number"
                        :links="phoneLinks"
                    >
                        <template #button>
                            <PhoneArrowUpRightIcon
                                class="inline-flex h-6 w-6 text-lifeworx-blue-600 hover:text-lifeworx-blue-500 cursor-pointer"
                            />
                        </template>
                    </Dropdown>

                    <PhoneArrowUpRightIcon
                        v-else
                        class="inline-flex m-2 h-6 w-6 text-zinc-300"
                    />

                </div>

                <div class="mt-2">

                    <Dropdown
                        v-if="contact?.emails && contact.emails.length > 0"
                        :links="emailLinks"
                    >
                        <template #button>
                            <EnvelopeIcon
                                class="inline-flex h-6 w-6 text-lifeworx-blue-600 hover:text-lifeworx-blue-500 cursor-pointer"
                            />
                        </template>
                    </Dropdown>

                    <EnvelopeIcon
                        v-else
                        class="inline-flex m-2 h-6 w-6 text-zinc-300"
                    />

                    </div>

            </div>

        </div>

        <div class="flex flex-wrap -mx-3 mb-3">

            <div class="w-full md:w-2/5 px-3 mb-4 md:mb-0">
                <InputLabel
                    for="grid-first-name"
                    value="First Name"
                    class="text-xs text-stone-400"
                >
                    <TextInput
                        class="w-full px-2.5 py-1.5 text-sm text-stone-700"
                        id="grid-first-name"
                        type="text"
                        v-model="contact.first_name"
                    />
                </InputLabel>
            </div>

            <div class="w-full md:w-2/5 px-3 mb-4 md:mb-0">
                <InputLabel
                    for="grid-last-name"
                    value="Last Name"
                    class="text-xs text-stone-400"
                >
                    <TextInput
                        class="w-full px-2.5 py-1.5 text-sm text-stone-700"
                        id="grid-last-name"
                        type="text"
                        v-model="contact.last_name"
                    />
                </InputLabel>
            </div>

            <div class="w-full md:w-1/5 px-3 mb-4 md:mb-0">
                <InputLabel
                    for="grid-gender"
                    value="Gender"
                    class="text-xs text-stone-400"
                >
                    <LwxSelect
                        id="grid-gender"
                        v-model="contact.gender"
                        :options="genders"
                    />
                </InputLabel>
            </div>

        </div>


        <div class="flex flex-wrap -mx-3 mb-6">

            <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                <InputLabel
                    for="grid-company"
                    value="Company"
                    class="text-xs text-stone-400"
                >
                    <TextInput
                        class="w-full px-2.5 py-1.5 text-sm text-stone-700"
                        id="grid-company"
                        type="text"
                        v-model="contact.company"
                    />
                </InputLabel>
            </div>

            <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                <InputLabel
                    for="grid-profession"
                    value="Profession"
                    class="text-xs text-stone-400"
                >
                    <LwxAutoComplete
                        id="grid-profession"
                        v-model="contact.profession"
                        :options="professions"
                    />
                </InputLabel>
            </div>

        </div>


        <div class="flex flex-wrap -mx-3 mb-6">

            <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                <div class="w-full mb-3">
                    <InputLabel
                        for="grid-address"
                        value="Address"
                        class="text-xs text-stone-400"
                    >
                        <AddressAutocomplete
                            v-model="contact.address"
                            @addressFill="addressFill"
                            :validity="contact.address_validity"
                        />
                    </InputLabel>
                </div>
                <div class="w-full mb-3">
                    <InputLabel
                        for="grid-address2"
                        value="Address 2"
                        class="text-xs text-stone-400"
                    >
                        <TextInput
                            class="w-full px-2.5 py-1.5 text-sm text-stone-700"
                            id="grid-address2"
                            type="text"
                            v-model="contact.address_2"
                        />
                    </InputLabel>
                </div>
                <div class="flex flex-wrap -mx-3 mb-2">
                    <div class="w-full md:w-1/2 pl-3 mb-4 md:mb-0">
                        <InputLabel
                            for="grid-city"
                            value="Town/City"
                            class="text-xs text-stone-400"
                        >
                            <TextInput
                                class="w-full px-2.5 py-1.5 text-sm text-stone-700"
                                id="grid-city"
                                type="text"
                                v-model="contact.city"
                            />
                        </InputLabel>
                    </div>
                    <div class="w-full md:w-1/4 px-2 mb-4 md:mb-0">
                        <InputLabel
                            for="grid-state"
                            value="State"
                            class="text-xs text-stone-400"
                        >
                            <LwxSelect
                                id="grid-state"
                                v-model="contact.state"
                                :options="states"
                                :useId="true"
                            />
                        </InputLabel>
                    </div>
                    <div class="w-full md:w-1/4 pr-3 mb-4 md:mb-0">
                        <InputLabel
                            for="grid-zip"
                            value="Zip"
                            class="text-xs text-stone-400"
                        >
                            <TextInput
                                class="w-full px-2.5 py-1.5 text-sm text-stone-700"
                                id="grid-zip"
                                type="text"
                                :validate="['nullable', 'len:5', 'number']"
                                v-model="contact.zip"
                            />
                        </InputLabel>
                    </div>
                </div>
            </div>

            <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                <InputLabel
                    for="grid-note"
                    value="Note"
                    class="text-xs text-stone-400"
                >
                    <textarea
                        class="w-full rounded-md border border-stone-400 h-24 text-sm resize-none focus:ring-1 focus:border-lifeworx-blue-600 focus:ring-lifeworx-blue-600"
                        id="grid-note"
                        name="Note"
                        v-model="contact.note"
                    />
                </InputLabel>
            </div>

        </div>

        <div class="flex flex-wrap -mx-3 mb-8">

            <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                <div class="mb-7">
                    <InputLabel
                        for="grid-email-primary"
                        value="Primary Email"
                        class="text-xs text-stone-400"
                    >
                        <TextInput
                            class="w-full px-2.5 py-1.5 text-sm text-stone-700"
                            id="grid-email-primary"
                            type="email"
                            :validate="['nullable', 'email']"
                            v-model="contact.emails[0]"
                        />
                    </InputLabel>
                </div>
                <div>
                    <InputLabel
                        for="grid-email-secondary"
                        value="Secondary Email"
                        class="text-xs text-stone-400"
                    >
                        <TextInput
                            class="w-full px-2.5 py-1.5 text-sm text-stone-700"
                            id="grid-email-secondary"
                            type="email"
                            :validate="['nullable', 'email']"
                            v-model="contact.emails[1]"
                        />
                    </InputLabel>
                </div>
            </div>

            <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                <InputLabel
                    value="Phone"
                    class="text-xs text-stone-400"
                />
                <div class="mb-2">
                    <PhoneNumber
                        :phone="contact.phones[0]"
                        :context="contact.id"
                        label="cell"
                    />
                </div>
                <div class="mb-2">
                    <PhoneNumber
                        :phone="contact.phones[1]"
                        :context="contact.id"
                        label="work"
                    />
                </div>
                <div>
                    <PhoneNumber
                        :phone="contact.phones[2]"
                        :context="contact.id"
                        label="home"
                    />
                </div>
            </div>

        </div>

        <div class="flex flex-wrap -mx-3">
            <div class="w-full md:w-1/4 pl-3 mb-4 md:mb-0 md:border-r md:border-r-stone-200">
                <InputLabel
                    for="grid-marketable-contact"
                    value="Send&nbsp;Marketing"
                    class="text-xs text-stone-400 whitespace-nowrap"
                >
                    <LwxSwitch
                        id="grid-marketable-contact"
                        v-model="contact.marketable_contact"
                        class="mt-2"
                    />
                </InputLabel>
            </div>

            <div class="w-full md:w-1/4 pr-6 mb-4 md:mb-0">
                <InputLabel
                    value="Send Care Updates"
                    class="text-xs text-stone-400 font-semibold text-right"
                />
            </div>

            <div class="w-full md:w-1/6 mb-4 md:mb-0">
                <InputLabel
                    for="grid-comm-phone"
                    value="by Phone"
                    class="text-xs text-stone-400 whitespace-nowrap w-fit"
                >
                    <LwxSwitch
                        id="grid-comm-phone"
                        v-model="contact.comm_phone"
                        class="mt-2"
                    />
                </InputLabel>
            </div>

            <div class="w-full md:w-1/6 mb-4 md:mb-0">
                <InputLabel
                    for="grid-comm-sms"
                    value="by SMS"
                    class="text-xs text-stone-400 whitespace-nowrap w-fit"
                >
                    <LwxSwitch
                        id="grid-comm-sms"
                        v-model="contact.comm_sms"
                        class="mt-2"
                    />
                </InputLabel>
            </div>

            <div class="w-full md:w-1/6 mb-4 md:mb-0">
                <InputLabel
                    for="grid-comm-email"
                    value="by Email"
                    class="text-xs text-stone-400 whitespace-nowrap w-fit"
                >
                    <LwxSwitch
                        id="grid-comm-email"
                        v-model="contact.comm_email"
                        class="mt-2"
                    />
                </InputLabel>
            </div>

        </div>

    </div>

    <div class="flex border-t py-3 px-6 h-12">
        <div class="w-8 -ml-1 mt-1 pr-5 flex-none">
            <svg
                v-if="formLoading"
                class="h-5 w-5 animate-spin text-lifeworx-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            >
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
        <div
            class="flex-auto pt-0.5 font-semibold text-lifeworx-green-500"
            :class="{
                'text-lifeworx-red-500': status?.variant == 'error'
            }"
        >
            {{ status?.message }}
        </div>
        <div class="flex-none">
            <slot name="actions" />
        </div>
    </div>

</template>
