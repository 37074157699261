import { ref, computed } from 'vue'

import { defineStore, storeToRefs, acceptHMRUpdate } from 'pinia'

import * as Sentry from "@sentry/vue";

import { useNProgress } from '@vueuse/integrations/useNProgress'

import { useAuthStore } from '@/Stores'

import { util } from '@/Helpers'

import moment from 'moment'

export const useJobStore = defineStore('job', () => {

    const authStore = useAuthStore()
    const { user } = storeToRefs(authStore)

    const { isLoading } = useNProgress()
    const loading = ref(isLoading)

    const jobs = ref({})
    const lastUpdated = ref(null)


    const sortedJobsByClientAll = computed(() => {

        let clients = clientsFromJobs(Object.values(jobs.value))
        let jobsByClient = {}

        clients.forEach(client => {
            jobsByClient[client] = util.convertArrayToObject(jobsFromClient(client, jobs.value), 'id')
        })

        return jobsByClient;

    })

    const sortedJobsByClientAllOrdered = computed(() => {
        return orderClientsAndJobs(sortedJobsByClientAll);
    })


    //
    // Helpers
    //

    function bucketedJobsByWeek(start = null, end = null) {
        return Object.values(jobs.value).filter(function(job) {
            if (end !== 1) {
                return moment.utc(job.start_date).isBetween(timeWindowStartString(start), timeWindowEndString(end))
            }
            return moment.utc(job.start_date).isBetween(timeWindowStartString(start), timeWindowEndString(end)) || job.start_date === null
        })
    }

    function clientsFromJobs(jobs) {
        return [...new Set(jobs.map(job => job.client.id))];
    }

    function jobsFromClient(id, jobs) {
        return Object.values(jobs).filter(function(job) {
            return job.client.id == id;
        })
    }

    function orderClientsAndJobs(sortedJobsByClient) {
        let jobsByClient = {}

        Object.keys(sortedJobsByClient.value).forEach(client => {
            jobsByClient[client] = Object.values(sortedJobsByClient.value[client])
                .sort(compareJobsByRatedThenDateDesc)
                .map(job => { return {id: job.id, start_date: job.start_date, ratings: job.ratings} })
        })

        return Object.entries(jobsByClient).sort(compareClientsByRatedThenJobStartDateDesc);
    }

    function jobsOrderedDesc(sortedJobsByClientId, jobIdStartDates) {
        let jobIds = jobIdStartDates.map(jobIdStartDate => jobIdStartDate.id)

        return Object.values(sortedJobsByClientId)
            .filter(function(job) {
                return jobIds.includes(job.id)
            })
            .sort(compareJobsByRatedThenDateDesc);
    }

    function compareJobsByRatedThenDateDesc(a, b) {
        if (a.start_date == null) {
            return -1;
        } else if (b.start_date == null) {
            return 1;
        }

        if (a.ratings.length > 0 && b.ratings.length == 0) {
            return 1;
        } else if (a.ratings.length == 0 && b.ratings.length > 0) {
            return -1;
        }

        return b.start_date.localeCompare(a.start_date);
    }

    function compareClientsByRatedThenJobStartDateDesc(a, b) {
        if (a[1][0].start_date == null) {
            return -1;
        } else if (b[1][0].start_date == null) {
            return 1;
        }

        if (a[1][0].ratings.length > 0 && b[1][0].ratings.length == 0) {
            return 1;
        } else if (a[1][0].ratings.length == 0 && b[1][0].ratings.length > 0) {
            return -1;
        }

        return b[1][0].start_date.localeCompare(a[1][0].start_date);
    }



    function timeWindowStartString(subtractAmount = 14, timeUnit = 'weeks') {
        return moment().utc().startOf('week').subtract(subtractAmount, timeUnit).format();
    }

    function timeWindowEndString(subtractAmount = 1, timeUnit = 'weeks') {
        return moment().utc().endOf('week').subtract(subtractAmount, timeUnit).format();
    }

    function purge() {
        jobs.value = {}
    }



    //
    // Hydration
    //

    async function hydrate(jobsData, config) {

        const { start, end, includeMissingStartDate } = config;

        loading.value = true

        for (const id in jobs.value) {
            if (jobs.value[id].start_date === null && includeMissingStartDate) {
                delete(jobs.value[id])
            } else if (moment.utc(jobs.value[id].start_date).isBetween(timeWindowStartString(start), timeWindowEndString(end)) === true) {
                delete(jobs.value[id])
            }
        }

        jobs.value = Object.assign(jobs.value, util.convertArrayToObject(jobsData, 'id'))

        lastUpdated.value = moment.utc()

        loading.value = false

    }



    //
    // API
    //

    async function index(start, end, scope, includeMissingStartDate=false, client=null, caregiver=null) {

        loading.value = true
        isLoading.value = true

        return await new Promise((resolve, reject) => {
            axios.get(
                '/api/v1/jobs',
                {
                    params: {
                        date_range: start && end ? timeWindowStartString(start) + '/' + timeWindowEndString(end) : null,
                        staff_id: scope || (client || caregiver) ? null : user.value.user_id,
                        client_id: !client ? null : client,
                        caregiver_id: !caregiver ? null : caregiver,
                        missing_start_date: includeMissingStartDate ? 1 : 0,
                    }
                },
            ).then(response => {
                loading.value = false
                resolve(response)
            }).catch((err) => {
                loading.value = false
                authStore.handleError(err)
                reject({ "error": err })
            })
        });

    }


    return {
        index, hydrate, purge,
        loading, jobs, lastUpdated,
        bucketedJobsByWeek, jobsFromClient, clientsFromJobs,
        orderClientsAndJobs, jobsOrderedDesc, compareJobsByRatedThenDateDesc, compareClientsByRatedThenJobStartDateDesc,
        sortedJobsByClientAll, sortedJobsByClientAllOrdered
    }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useJobStore, import.meta.hot))
}
