<script setup>

    import { ref, watch } from 'vue'

    import { MapPinIcon, UsersIcon, ExclamationTriangleIcon, UserPlusIcon } from '@heroicons/vue/20/solid'

    const props = defineProps({
        contacts: {
            type: Array,
            default: []
        },
        selected: {
            type: Object
        },
        addNew: {
            type: Boolean,
            default: false
        }
    })

    const contactGroups = ref([])

    watch(() => props.contacts, (value) => {
        const groups = value.reduce((acc, contact) => {
            if (contact.type === 'Client') {
                acc[0]['contacts'].push(contact);
                return acc;
            }

            if (contact.primary_contact == true) {
                acc[1]['contacts'].push(contact);
                return acc;
            }

            if (contact.billing_contact == true) {
                acc[2]['contacts'].push(contact);
                return acc;
            }

            acc[3]['contacts'].push(contact);
            return acc;
        }, [
            {
                category: 'Client',
                contacts: []
            },
            {
                category: 'Primary',
                contacts: []
            },
            {
                category: 'Billing',
                contacts: []
            },
            {
                category: 'Other',
                contacts: []
            },
        ])

        contactGroups.value = []
        for (const i in groups) {
            if (groups[i]['contacts'].length != 0) {
                groups[i].contacts.sort((a, b) => {
                    const aName = a.first_name + " " + a.last_name
                    const bName = b.first_name + " " + b.last_name
                    return aName.localeCompare(bName)
                });
                contactGroups.value.push(groups[i])
            }
        }
    }, {deep: true})

    watch(contactGroups, (newValue, oldValue) => {
        if (props.selected.id) {
            return;
        }

        if (newValue.length == 0) {
            return;
        }

        if (oldValue.length == 0) {
            emit('update:selected', contactGroups.value[0]['contacts'][0]['id'])
        }
    })

    const emit = defineEmits(['update:selected'])

    function formatTypes(contact, group) {
        let types = []

        if (contact.type) { types.push(contact.type) }
        if (contact.primary_contact) { types.push('Primary') }
        if (contact.billing_contact) { types.push('Billing') }
        if (contact.comm_email || contact.comm_phone || contact.comm_sms) { types.push('Care Updates') }

        return types.join(', ');

    }

</script>

<template>

    <div class="h-full" aria-label="Directory">
        <div
            v-if="contactGroups.length > 0 && contactGroups[0].category !== 'Client'"
            class="bg-white sticky top-0 z-50"
        >
            <div
                class=" bg-lifeworx-red-500 bg-opacity-20 z-50 px-3 py-4 text-sm font-semibold leading-6 text-lifeworx-red-600 tracking-wide">
                    <ExclamationTriangleIcon class="inline-flex h-6 w-6 mr-2 text-lifeworx-red-500" aria-hidden="true" /> No Client Contact Selected
            </div>
        </div>

        <div v-for="group in contactGroups" :key="group.category" class="relative">
            <div
                class="sticky top-0 z-10 border-y border-b-stone-200 border-t-stone-100 bg-gray-50 px-3 py-2 text-sm font-semibold leading-6 text-lifeworx-blue-600 tracking-wide"
                :class="{
                    'top-14': contactGroups.length > 0 && contactGroups[0].category !== 'Client'
                }"
            >
              <h3>{{ group.category }}</h3>
            </div>
            <ul role="list" class="divide-y divide-gray-100">

                <li
                    v-for="contact in group.contacts" :key="contact.id" @click="emit('update:selected', contact.id)"
                    class="border-b py-2.5 pl-6 hover:bg-stone-500 hover:bg-opacity-20 select-none cursor-pointer"
                    :class="{
                        'bg-stone-500 bg-opacity-20 ': props.selected.id == contact.id,
                        'even:bg-stone-300 even:bg-opacity-20': props.selected.id == null
                    }"
                >
                    <div class="pb-2 text-lifeworx-blue-800 font-semibold tracking-wide text-sm">
                        <template v-if="contact.first_name || contact.last_name">
                            {{ contact.first_name }} {{ contact.last_name }}
                        </template>
                        <template v-else-if="contact.company">
                            {{ contact.company }}
                        </template>
                        <template v-else>
                            &mdash; &mdash;
                        </template>
                    </div>
                    <div
                        class="flex mb-2 text-stone-500 text-sm"
                        :title="group.category == 'Client' && contact.address_validity == 'N' ? 'Client Address May Be Incomplete or Invalid' : null"
                    >
                        <ExclamationTriangleIcon
                            v-if="group.category == 'Client' && contact.address_validity == 'N'"
                            class="flex flex-shrink-0 h-5 w-5 mr-2 text-lifeworx-orange-500"
                            aria-hidden="true"
                        />
                        <MapPinIcon
                            v-else
                            class="flex flex-shrink-0 h-5 w-5 mr-2 text-stone-400"
                            aria-hidden="true" />
                        {{ `${contact.city ? contact.city : ""}${contact.state ? (", " + contact.state) : "&mdash;"}` }}
                    </div>
                    <div class="flex mb-2 text-stone-500 text-sm">
                        <UsersIcon class="flex flex-shrink-0 h-5 w-5 mr-2 text-stone-400" aria-hidden="true" />
                        {{ formatTypes(contact, group) }}
                    </div>
                </li>
            </ul>
        </div>
        <div
            v-if="props.addNew == true"
            class="sticky top-0 z-10 border-y border-b-stone-200 border-t-stone-100 bg-lifeworx-blue-500 bg-opacity-20 px-6 py-3 text-sm font-semibold leading-6 text-lifeworx-blue-600 tracking-wide italic"
        >
                <UserPlusIcon class="inline-flex h-6 w-6 mr-2 text-lifeworx-blue-500" aria-hidden="true" />
                Unsaved Contact
        </div>

    </div>

</template>
